.si-date-picker {
    font-family: $regular_font-family;
    font-weight: 500;

    .si-input-wrap {
        margin-bottom: 15px;
    }

    .si-date-picker-container {
        background: rgba(200, 200, 200, 0.25);
        border-radius: 5px;
        .si-date-range-picker {
            display: none;
            margin-top: -17px;

            &.si-dekstopcalendareview {
                display: block;
                margin: auto;
                background: rgb(200 200 200 / 0%);
                border-radius: 5px;

                @media screen and (max-width: $media-tablet-max) {
                    display: none;
                }
            }

            &.si-mobilcalendareview {
                display: none;

                @media screen and (max-width: $media-tablet-max) {
                    display: block;
                    background: rgb(200 200 200 / 0%);
                    border-radius: 5px;
                    margin: auto;
                }
            }
        }

    }

    .si-input-wrap {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
    }

    .rdrMonthAndYearWrapper {
        .rdrMonthAndYearPickers {
            display: none;
        }

        .rdrNextPrevButton {
            background-color: transparent;

            &.rdrNextButton i {
                border-width: 10px 5px 10px 10px;
                border-color: transparent transparent transparent rgb(255 255 239);
            }

            &.rdrPprevButton i {
                border-width: 10px 10px 10px 5px;
                border-color: transparent rgb(255 255 239) transparent transparent;
            }
        }
    }

    .rdrMonthName {
        color: $primary-color-1;
    }

    .rdrWeekDay {
        color: $secondary-color-1;
        font-weight: 500;
    }

    .rdrDayNumber {
        transition: 0.2s;

        span {
            color: $secondary-color-1 !important;
            font-weight: 500;
        }
    }

    .rdrInRange {
        color: rgba($primary-color-5, 0.3) !important;
    }

    .rdrStartEdge,
    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .rdrDayStartOfMonth .rdrDayInPreview,
    .rdrDayStartOfMonth .rdrDayEndPreview,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrDayEndPreview {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .rdrEndEdge,
    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .rdrDayEndOfMonth .rdrDayInPreview,
    .rdrDayEndOfMonth .rdrDayStartPreview,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrDayStartPreview {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .rdrStartEdge,
    .rdrEndEdge {
        color: $primary-color-1 !important;
    }

    .rdrDayToday .rdrDayNumber span:after {
        display: none;
    }

    .rdrDay {
        color: transparent !important;

        &.rdrDayHovered {
            color: transparent !important;

            .rdrDayNumber {
                &:hover {
                    background: rgba(255, 255, 255, 0.09) !important;
                }
            }

            .rdrDayStartPreview,
            .rdrDayInPreview,
            .rdrDayEndPreview {
                color: transparent !important;
            }
        }
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        background: rgba($primary-color-5, 0.3) !important;
        color: rgba($primary-color-5, 0.3) !important;
    }

    .rdrDayStartPreview {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .rdrDayEndPreview {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .rdrDayPassive {
        .rdrDayNumber {
            display: none;
        }
    }

    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
        color: $black;
    }

    .select-date {
        color: $secondary-color-1;
        background-color: transparent;
        border: 1px solid $neutral-color-5;
        padding: 5px 10px;
        text-align: center;
        width: 120px;
        font-size: 12px;
        font-family: "poppins";
        @include border-radius(5px);
        font-weight: 500;
        &:focus,
        &:active,
        &:focus-within {
            border: 1px solid #282828;
            box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
        }
        &:hover {
            border: 1px solid #282828;
        }

        &[disabled] {
            background: rgba(200, 200, 200, 0.3);
            color: $neutral-color-1;
            border-color: $neutral-color-4;
        }

        @media screen and (max-width: $media-mobile-max-580) {
            width: 88px;
        }
    }

    .separator {
        padding: 0 10px;
        color: #999999;
        display: flex;
        align-items: center;
    }

    .si-date-picker-container {
        @include flexbox();
        background: rgba(200, 200, 200, 0.25);
        border-radius: 5px;
        margin: auto;
        justify-content: center;
        .si-form-radio {
            margin-left: 20px;
            background-color: #212121;
            height: fit-content;
            padding: 10px;
            font-size: 14px;
            color: #999999;
        }
    }

    .rdrDateDisplayWrapper {
        display: none;
    }

    .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton i {
        border-color: transparent transparent transparent $primary-color-1;
    }

    .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrPprevButton i {
        border-color: transparent $primary-color-1 transparent transparent;
    }
}

.si-start-date {
    @include flexbox();
    align-items: center;
    margin-left: auto;

    &[disabled] {
        .si-label {
            color: #282828;
            font-weight: 500;
            cursor: default;
        }
        input {
            color: $neutral-color-4;
            border: 1px solid $neutral-color-4 !important;
            cursor: not-allowed;
            -webkit-text-fill-color:$neutral-color-4!important;
            opacity: 1;
        }
        .icon-calender {
            cursor: not-allowed;
            &:before {
                color: $neutral-color-4;
            }
        }
    }
    input {
        color: #282828;
        border: 1px solid #282828;
        border-color: #282828;
        -webkit-text-fill-color:#282828;
        opacity: 1;
    }
    .icon-calender {
        &:before {
            color: #212121;
        }
    }
    .form-control-group {
        position: relative;
        width: 125px;
        background-color: $white;
        border-radius: 5px;
        height: 30px;
        display: flex;
        align-items: center;
        border: 1px solid $neutral-color-5;
        cursor: pointer;
        &:focus,
        &:active,
        &:focus-within {
            border: 1px solid #282828;
            box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
        }
        &:hover {
            border: 1px solid #282828;
        }
        .si-icon {
            left: 3px;
        }
        .date-text {
            margin-left: 35px;
            color: #282828;
            font-size: 12px;
        }

        @media screen and (max-width: $media-tablet-max) {
            margin-left: 15px;
        }
    }

    .si-label {
        margin-bottom: 0;
        margin-right: 8px;

        @media screen and (max-width: $media-tablet-max) {
            display: none;
        }
    }

    .si-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 34px;
    }

    .si-input {
        padding-left: 34px;
        cursor: pointer;
        color: $secondary-color-1;
        font-weight: 500;
        font-size: 14px;
        padding-right: 10px !important;
        -webkit-text-fill-color:$secondary-color-1;
        opacity: 1;

    }
}
.input-disable {
    pointer-events: none;
    background: rgba(200, 200, 200, 0.3) !important;
    border-color: #c8c8c8 !important;
    color: #c8c8c8 !important;
    -webkit-text-fill-color:#c8c8c8!important;
    opacity: 1;
}
.input-disable::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
}
.si-start-date {
    .form-control-group {
        input {
            border: 1px solid #282828;
        }
    }
}
.rdrtPrevButton-disabled {
    .rdrPprevButton {
        pointer-events: none;
        i {
            border-color: transparent #c8c8c8 transparent transparent !important;
            pointer-events: none;
        }
    }
}
.rdrtNextButton-disabled {
    .rdrNextButton {
        pointer-events: none;
        i {
            border-color: transparent transparent transparent #c8c8c8 !important;
            pointer-events: none;
        }
    }
}

// =======================================

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
    line-height: normal;
}
.react-datepicker__calendar-icon {
    width: 1.8em !important;
    height: 1.8em !important;
    padding: 0.5rem 0.5rem 0.5rem 0.6rem !important;
    font-size: 17px;

}
.css-1jqq78o-placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.react-datepicker__view-calendar-icon input {
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 31px;
    width: 100%;
    padding: 6px 10px 5px 35px !important;
    cursor: pointer;
    &:focus,
    &:focus-within {
        border-color: $secondary-color-1 !important;
        box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5) !important;
    }
    &:hover {
        @media screen and (min-width: 769px) {
            border-color: $secondary-color-1 !important;
        }
    }
    @media screen and (max-width: $media-tablet-min) {
        width: 100%;
    }
}
.react-datepicker__view-calendar-icon input {
    &:disabled {
        background: rgb(242, 242, 242) !important;
        color: #282825 !important;
        background-color: rgb(242, 242, 242);
        border-color: rgb(230, 230, 230);
        background-color: rgb(242, 242, 242);
        border-color: rgb(230, 230, 230);
        border-radius: 4px;
        -webkit-text-fill-color:#282828!important;
        opacity: 1;
    }
}

.react-datepicker__view-calendar-icon {
    svg {
        display: none;
    }
    &::before {
        content: "\e91c";
        font-family: "AMS" !important;
        font-size: 15px;
        color: $black;
        position: absolute;
        top: 8px;
        left: 10px;
    }
}
.react-datepicker__input-container {
    input {
        font-size: 12px;
        font-family: "poppins";
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
}

.si-date-range-picke {
    .si-date-picker {
        .si-input-wrap {
            position: relative;
            .select-date {
                text-align: right;
                padding: 5px 12px;
                width: 120px !important;
            }
        }
        .si-input-wrap::before,
        .si-input-wrap::after {
            content: "\e91c";
            font-family: "AMS";
            color: #282828;
            font-size: 15px;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
        }
        .si-input-wrap::before {
            left: 15px;
        }
        .si-input-wrap::after {
            left: 165px;
        }
    }
}
.si-start-date[disabled] {
    .form-control-group {
        border: 1px solid #c8c8c8 !important;
        background: unset;
    }
    .date-text {
        color: #c8c8c8;
        cursor: not-allowed;
        border: none !important;
    }
}
