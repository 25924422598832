input[type="checkbox"] {
    position: relative;
    left: 0px;
    min-width: 20px;
    height: 20px;
    color: $black;
    border: 2px solid $neutral-color-2;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

    &::before {
        position: absolute;
        content: "";
        display: block;
        left: 6px;
        width: 4px;
        top: 2px;
        height: 10px;
        border-style: solid;
        border-color:$secondary-color-1;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }

    &:checked {
        color: $secondary-color-1;
        border-color: $secondary-color-1;
        background: none;

        &::before {
            opacity: 1;
        }

        ~ label::before {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
    }
}

label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    user-select: none;
}

.si-custom-table__check {
    .si-custom-table__row {
        &.active,
        &:hover {
            input[type="checkbox"] {
                border: 2px solid $black;
                &::before {
                    border-color: $black;
                }
            }
        }
    }
}
.MuiTableCell-paddingCheckbox {
    padding: 8px 16px !important;
}
.si-popup-content {
    .MuiTableCell-paddingCheckbox {
        padding: 6px 16px !important;
    }
}

.css-q8lw68 {
    width: 20px !important;
}
.css-1u5ei5s {
    left: -2px !important;
}
