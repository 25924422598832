//Colors
$transparent: transparent;
$default: #404040;
$gray: #848484;
$white: #ffffff;
$black: #000000;
$red: #ff0000;
$yellow : #fde940;

$dark-yellow: #F9AD4A;
$solid-red: #D81212;
$patch-color:#F6E1A7;

$primary-color-1: #e10000;
$primary-color-2: #f50000;
$primary-color-3: #ff1414;
$primary-color-4: #ff2828;
$primary-color-5: #ff5046;
$primary-color-6: #FFCBC7;

$secondary-color-1: #282828;
$secondary-color-2: #323232;
$secondary-color-3: #3c3c3c;
$secondary-color-4: #464646;
$secondary-color-5: #505050;
$secondary-color-6: #888888;
$secondary-color-7: #282825;

$neutral-color-1: #969696;
$neutral-color-2: #6e6e6e;
$neutral-color-3: #b4b4b4;
$neutral-color-4: #c8c8c8;
$neutral-color-5: #eaeaea;
$neutral-color-6: #f1f1f1;
$neutral-color-7: #f9f9f9;
$neutral-color-8: #f8f8f8;

$active-color-1: #00B137;
$active-color-2: #007C27;
$active-color-3: #00D537;

//client colors
$left-panel-bg: #1C1C1C;
$left-panel-highlight-color: #0D0D0D;
$mobile-header: #1C1C1C;
$table-background: #181818;
$client-primary-color: #F9AD4A;
$count-bg: #212121;
$custom-table-head: #292929;
$client-secondary-color: #f95058;
$client-complementary-color: #fb574d;
$calender-header: #1d1d1d;
$client-deactive-color: #b5b9bb;
$calender-day-mobile-color: #4A4A4A;
$profile-background: #5D5D5D;
$exercise-btn: #2D2D2D;
$card-border: #2D2D2D;
$action-div-bg: #515151;
$action-hover: #383838;
$header-color-1: #2a0835;
$header-color-2: #0e0842;
$menu-active: #4e4382;
$header-bg: #faf3f3;
$publish-btn: #2E1111;
$font-color: $client-primary-color;
$notification-alert: #FF3D44;
$icon-font : 'mss';
//icons color
$substitute-up: #66cc33;
$substitute-down: #b4181e;
$dropdown-color: #222222;
$textarea-bg: #222222;
// social icons
$googlePlus: #dd4b39;
$facebook: #3b5998;
$twitter: #1da1f2;
$whatsApp: #7ad06d;
$instagram: #125688;

// box-shadow
$box-shadow-lightblack: 10px 0px 10px rgba(0, 0, 0, 0.1);

//Media Breakpoints
$media-mobile-ex-small-320: 320px;
$media-mobile-small-340: 340px;
$media-mobile-small: 360px;
$media-mobile-med: 375px;
$media-mobile-small-max-380: 380px;
$media-mobile-small-max-420: 420px;
$media-mobile-small-max-456: 456px;
$media-mobile-small-max: 480px;
$media-mobile-max-520: 520px;
$media-mobile-max-540: 540px;
$media-mobile-max-580: 580px;
$media-mobile-max-620: 620px;
$media-mobile-max-640: 640px;
$media-mobile-max-680: 680px;

$media-mobile-max: 767px; //Mobiles (Landscape)
$media-tablet-min: 768px;
$media-tablet-max-768: 768px;
$media-tablet-small-max : 812px; //for iphone10, vivov11 pro and one plus 6/6T @media screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)
$media-tablet-large-max: 991px; //Tablets, Ipads
$media-tablet-max: 992px; //Tablets, Ipads
$media-desktop-min: 1024px;
$media-desktop-max-1024: 1024px;
$media-desktop-small-max: 1199px;
$media-desktop-max: 1200px;
$media-laptop-min: 1280px; //Laptops, Desktops
$media-laptop-max-1280: 1280px;
$media-laptop-max: 1366px;
$media-ex-large-min: 1367px;
$media-ex-large-max: 1441px;
$media-ex-large-hd-max: 1601px;
$media-xxlarge: 1900px;
$media-xxlarge-min: 2000px;

// $si-icons :(Training : '\e900',
//     dropdown : '\e903',
//     Athletes : '\e901',
//     downArrow: '\e903',
//     admin-drp : '\e904',
//     plus : '\e902',
//     arrow : '\e905',
//     cancel : '\e90d',
//     delete: '\e906',
//     edit: '\e907',
//     notification: '\e908',
//     tick: '\e909',

// ) 
$si-icons: (
    icon-tags: '\e915',
    icon-search: '\e90b',
    icon-carret: '\e900',
    icon-chev-down: '\e901',
    icon-chev-up: '\e902',
    icon-chev-left: '\e903',
    icon-chev-right: '\e904',
    icon-info: '\e905',
    icon-close: '\e906',
    icon-bell: '\e907',
    icon-options: '\e908',
    icon-arrow-left: '\e909',
    icon-arrow-right: '\e90a',
    icon-group: '\e90c',
    icon-user: '\e90d',
    icon-add: '\e957',
    icon-page: '\e90f',
    icon-notes: '\e910',
    icon-delete: '\e911',
    icon-edit: '\e912',
    icon-1:'\e95b',
    icon-2:"\e95c",
    icon-4:"\e90e",
    icon-5:"\e95a",
    icon-6:"\e959",
    icon-7: "\e95d",
    icon-9:"\e958",
    icon-8:"\e95f",
    icon-10:"\e960",
    icon-11:"\e963",
    icon-12:"\e961",
    icon-delete-2:"\e95e",
    icon-send:"\e956", 
    icon-doctor-bag:"\e964",
    icon-academic-player:"\e966",
      
       
    )
 

    