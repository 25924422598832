.si-custom-tabs {
    .si-tab {
        &-list {
            @include flexbox();
            margin-bottom: 14px !important;
            margin-top: -11px !important;
            border-bottom: 0 !important;
        }

        &-item {
            color: $neutral-color-2 !important;
            border: 1px solid $neutral-color-2 !important;
            font-size: 12px !important;
            text-transform: capitalize !important;
            transition: 0.2s;
            border-radius: 50px !important;
            height: 26px !important;
            padding-right: 20px !important;
            font-weight: 500 !important;
            position: relative;
            padding-left: 0 !important;
            cursor: pointer;
            @include flexbox();
            align-items: center;

            & + .si-tab-item {
                margin-left: 20px;
                @media screen and (max-width: $media-tablet-max) {
                    margin-left: 27px;
                }
            }

            &.active {
                background-color: $primary-color-1 !important;
                color: $white !important;
                border-color: $primary-color-1 !important;
                .si-icon-tab {
                    background-color: $primary-color-1 !important;
                    border-color: $primary-color-1 !important;
                    box-shadow: 0px 0px 4px rgba($black, 0.5) !important;
                }
            }

            .si-icon-tab {
                @include border-radius(50px);
                margin-right: 8px;
                margin-left: -1px;
                height: calc(100% + 1px);
                @include flexbox();
                align-items: center;
                justify-content: center;
                width: 45px;
                border: 1px solid $neutral-color-2;
                height: 26px;
                &:before {
                    font-size: 18px;
                }
            }
        }
    }
}
.si-tab-pane__notesmedia {
    padding-top: 4px;
}

.si-tab-pane {
    @media screen and (max-width: $media-tablet-max) {
        .si-subHeader .custom-select__control {
            min-height: 32px !important;
            width: 207px;
        }
    }
    &::-webkit-scrollbar {
        width: 3px;
        z-index: 999;
        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
        @supports (-webkit-touch-callout: none) {
            width: 0px;
            display: none;
        }
    }

    /* Track */
    &::-webkit-scrollbar-track {
        z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(#242424, 0.2);
        z-index: 999;
    }
    &__editspecs {
        height: calc(100vh - 306px);
        background-color: #ffffff;
        overflow: hidden;
        overflow-y: auto;

        @media screen and (max-width: $media-tablet-max) {
            height: calc(100vh - 412px);
        }

        .si-form {
            display: flex;
        }

        .si-sportstags {
            .content-wrapper {
                background: rgba($neutral-color-4, 0.25);
                padding: 10px;
                padding-bottom: 0px;
                @include border-radius(5px);
            }

            .si-title {
                color: $secondary-color-1;
                font-size: 12px;
                font-family: $bold_font-family;
                font-weight: $bold_font-weight;
                margin-bottom: 5px;
                @media screen and (max-width: $media-tablet-max) {
                    font-size: 14px;
                }
            }

            .form-control {
                margin-top: 27px;
            }

            .custom-label__text {
                color: #6d6d6d;
                font-size: 12px;
                margin-left: 6px;
            }

            .custom-label {
                margin-bottom: 10px;
            }

            .si-tagControl {
                border-radius: 5px;
                padding: 15px;
                padding-left: 0;
                padding-bottom: 9px;
                padding-right: 0;
                .si-subtitile {
                    color: #5e5e5e;
                    font-size: 14px;
                    font-family: $regular_font-family;
                    font-weight: $regular_font-weight;
                    padding-left: 15px;
                }

                .ReactTags__tags {
                    display: flex;

                    flex-flow: wrap;
                    width: 100%;
                    background: $white;
                    border: 1px solid #c8c8c8;
                    border-radius: 5px;
                    align-items: center;
                    padding: 10px;
                    .ReactTags__selected {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .tag-wrapper {
                        display: flex;
                        width: 100%;
                        position: relative;

                        &.ReactTags__tag {
                            font-size: 14px;
                            text-transform: capitalize;
                            font-family: $regular_font-family;
                            font-weight: $regular_font-weight;
                            color: $black;

                            padding-left: 6px;
                            padding-right: 6px;
                            width: auto;
                            height: 30px;

                            background: #f4f4f4;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            line-height: 15px;
                            margin-right: 15px;
                        }
                    }

                    .ReactTags__remove {
                        background: transparent;
                        border: 0;
                        right: 6px;
                        line-height: 25px;
                        font-size: 22px;
                        margin: 5px;
                        color: #000000;
                    }
                }

                .ReactTags__tagInput {
                    width: 100px;

                    .ReactTags__tagInputField {
                        border: 1px solid $neutral-color-4;
                        border-radius: 5px;
                        background: $white;
                        height: 30px;
                        padding: 0 10px;
                        width: 100%;
                        font-size: 12px;
                        &:focus,
                        &:focus-within {
                            border-color: $secondary-color-1 !important;
                            box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5) !important;
                        }
                        &:hover {
                            @media screen and (min-width: 769px) {
                                border-color: $secondary-color-1 !important;
                            }
                        }

                        input {
                            &:focus-visible {
                                border: 1px solid #000000;
                                outline: 0;
                            }
                        }
                    }
                }

                .ReactTags__suggestions {
                    background: #222222;
                    box-shadow: 0px 0px 4px rgb(0 0 0 / 70%);
                    border-radius: 5px;
                    width: 183px;
                    height: auto;
                    padding: 10px;
                    padding: 10px 3px 10px 0px;
                    position: absolute;

                    ul {
                        list-style: none;

                        li {
                            font-size: 12px;
                            font-family: $regular_font-family;
                            font-weight: $regular_font-weight;
                            color: $white;
                            color: #fff;
                            height: 32px;

                            &:not(:last-child) {
                                border-bottom: 1px solid rgba($white, 0.2);
                            }
                        }
                    }
                }

                .ReactTags__activeSuggestion {
                    mark {
                        color: $white;
                        background: transparent;
                    }
                }
            }
        }

        .content-wrapper {
            padding: 10px 5px 10px 0px;
            @include border-radius(5px);
            margin-bottom: 15px;
            @media screen and (max-width: $media-tablet-max) {
                margin-bottom: 0px;
                padding: 5px 0px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
            &.si-viewmode {
                .form-control {
                    .si-value {
                        background: #eaeaea;
                        border: 1px solid #c8c8c8;
                        border-radius: 5px;
                        font-size: 12px;
                        color: #282828;
                        font-family: $regular_font-family;
                        font-weight: $regular_font-weight;
                        padding: 4px 15px;
                        @media screen and (max-width: $media-tablet-max) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .si-custom-col {
            color: $white;
        }

        input {
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $neutral-color-4;
                -webkit-text-fill-color:$neutral-color-4;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $neutral-color-4;
            }

            &:-ms-input-placeholder {
                /* Microsoft Edge */
                color: $neutral-color-4;
            }
        }

        .label {
            &-head {
                font-size: 12px;
                text-transform: capitalize;
                color: $secondary-color-1;
                font-family: $bold_font-family;
                font-weight: 700;
                @media screen and (max-width: $media-tablet-max) {
                    font-size: 14px;
                }
            }

            &-info {
                font-size: 10px;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                color: $secondary-color-6;
                padding-left: 10px;
            }
        }

        .si-wrp {
            @include flexbox();
            margin-bottom: 5px;

            @media screen and (max-width: $media-tablet-max) {
                flex-wrap: wrap;
            }
        }

        .si-label-wrap {
            @include flexbox();
            align-items: baseline;
        }

        .form-group {
            @include flexbox();
            flex-wrap: wrap;
            justify-content: flex-start;

            @media screen and (max-width: $media-tablet-max) {
                width: 100%;
            }

            .form-control {
                margin-right: 20px;
                width: 156px;

                &.si-error {
                    .si-input,
                    select {
                        border: 1px solid #e10000;
                    }

                    .si-errormsg {
                        @include flexbox();
                        color: #e10000;
                        font-family: $regular_font-family;
                        font-weight: $regular_font-weight;
                        font-size: 10px;
                        position: absolute;
                        left: 0;
                        white-space: nowrap;
                    }
                }

                .si-errormsg {
                    display: none;
                }

                &.si-reps,
                &.si-sets {
                    width: 60px;

                    @media screen and (max-width: $media-tablet-max) {
                        flex-basis: 13%;

                        .si-input {
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: $media-mobile-max-520) {
                        flex-basis: 21%;
                    }
                    @media screen and (max-width: $media-mobile-small-max) {
                        flex-basis: 100%;
                    }
                }
                &.si-reps {
                    .si-input {
                        &[disabled] {
                            border: 1px solid $neutral-color-4;
                            background-color: #ffffff;
                        }
                    }
                }
                select {
                    width: 109px;
                    text-align: center;
                    @media screen and (max-width: $media-mobile-small) {
                        width: 81px;
                    }
                }
            }
        }

        .form-control {
            &.si-Disable {
                .form-control-wrap {
                    cursor: not-allowed;
                    .si-input {
                        cursor: not-allowed;
                    }
                    .custom-select__control {
                        cursor: not-allowed;
                    }
                }
                .custom-select__single-value,
                .custom-select__indicator {
                    color: $neutral-color-4 !important;
                }
                .si-view {
                    &:after {
                        content: "\e920";
                        color: $neutral-color-4;
                        font-size: 15px;
                    }
                }

                label {
                    color: $neutral-color-4;
                }
            }

            &.si-tempo {
                width: 210px;
                margin-top: 25px;
                @media screen and (max-width: $media-tablet-max) {
                    margin-top: 0;
                }
                @media screen and (max-width: $media-mobile-max-520) {
                    width: 100%;
                }
            }

            .si-labelwrp {
                @include flexbox();
                position: relative;
                width: 100%;
            }

            label {
                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
                display: block;
                margin-bottom: 5px;
                margin-top: 5px;
                font-family: $medium_font-family;
                font-weight: $medium_font-weight;
                color: $secondary-color-1;
                cursor: initial;
            }

            .si-view {
                position: absolute;
                right: 0;
                cursor: pointer;

                &:after {
                    content: "\e921";
                    font-family: "AMS" !important;
                    font-size: 12px;
                    color: $black;
                    right: 0;
                }
            }

            &-wrap {
                position: relative;
                display: flex;
                width: 100%;

                .si-input {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    height: 30px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    font-family: $regular_font-family !important;
                    font-weight: $regular_font-weight !important;
                    font-size: 12px;
                    padding-left: 5px;
                    @media screen and (max-width: $media-mobile-small-max) {
                        flex: 0 0 50%;
                    }
                    &:focus {
                        border: 1px solid #000000;
                    }
                }

                .custom-select {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    border: none;
                    background-color: $white;
                    color: #32291d;
                    font-weight: 600;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    border: 1px solid $neutral-color-4;
                    &__control {
                        border-top-left-radius: 0px !important;
                        border-bottom-left-radius: 0px !important;
                        min-height: 30px;
                        height: 30px;
                        background-color: $white !important;
                        border: 1px solid $neutral-color-4;
                    }
                    &__indicator {
                        padding: 0px !important;
                        padding-right: 6px !important;
                    }
                }

                &[disabled] {
                    cursor: not-allowed;

                    .si-input {
                        pointer-events: none;
                        color: $neutral-color-4;
                        -webkit-text-fill-color:$neutral-color-4;
                        opacity: 1;
                    }

                    .custom-select {
                        pointer-events: none;
                        color: $neutral-color-4;
                        -webkit-text-fill-color:$neutral-color-4;
                        opacity: 1;
                    }
                }
            }
        }

        .si-custom {
            &-row {
                @include flexbox();
            }

            &-col {
                position: relative;
                width: 100%;

                & + .si-custom-col {
                    padding-left: 20px;
                    margin-left: 20px;
                    flex: 1;
                }
            }
        }
        .custom-single-select {
            width: 100%;
            @media screen and (max-width: $media-tablet-max) {
                flex: 0 0 50%;
            }
        }
        .custom-select__single-value {
            color: $secondary-color-1;
            font-size: 12px;
            font-family: $regular_font-family;
            font-weight: $regular_font-weight;
        }
        .si-Disable {
            cursor: not-allowed;
            input {
                pointer-events: none;
            }
        }
    }

    &__setrecurrence {
        .si-program-duration {
            font-size: 14px;
            text-transform: capitalize;
            color: $secondary-color-1;
        }
        .si-action-buttons {
            .si-action-btn {
                width: 98px;
            }
        }
        .si-program {
            &-head {
                .si-icon__button {
                    margin-left: auto;
                    display: none;
                }

                .title {
                    font-weight: 600;
                }

                .label {
                    @include border-radius(5px);
                    padding: 5px 10px;
                    font-size: 12px;
                    display: inline-block;
                    background-color: rgba($neutral-color-4, 0.2);
                    margin-left: 10px;
                }
            }

            &-weeks {
                max-height: calc(100dvh - 380px);
                // max-height: calc(100dvh - 290px);
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 3px;
                    z-index: 999;
                    @media screen and (max-width: $media-tablet-max) {
                        width: 0px;
                    }
                    @supports (-webkit-touch-callout: none) {
                        width: 0px;
                        display: none;
                    }
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(#242424, 0.2);
                    border-radius: 10px;
                    z-index: 999;
                }

                .si-program-list {
                    @include border-radius(5px);
                    padding: 8px 0;
                }

                .si-program-row {
                    padding: 10px;
                    @media screen and (max-width: $media-tablet-max) {
                        padding: 10px 0px;
                        margin-top: 6px;
                    }
                    & + .si-program-row {
                        border-top: 1px solid $neutral-color-5;
                        @media screen and (max-width: $media-tablet-max) {
                            border-top: none;
                        }
                    }
                }
            }

            &-row,
            &-list {
                @include flexbox();
                align-items: center;
            }

            &-item {
                @include flexbox();
                align-items: center;
                justify-content: center;
                flex: 1;
                .checkbox-disabled {
                    border-color: #969696 !important;
                    pointer-events: none;
                    &::before {
                        border-color: #969696 !important;
                    }
                }
            }

            &-row {
                &-head {
                    padding: 10px;
                    margin-top: 10px;
                    background: #f5eefa;
                    border: 1px solid $neutral-color-5;
                    color: #282828;
                    font-size: 14px;
                    font-family: $semibold_font-family;
                    font-weight: $semibold_font-weight;
                    border-radius: 5px 5px 0px 0px;
                    .col-1 {
                        font-weight: 600 !important;
                    }
                }
            }

            &-col {
                &.col-1 {
                    flex-basis: 31%;
                    font-family: $semibold_font-family;
                    font-weight: 700;
                    @media screen and (min-width: $media-tablet-max) {
                        flex-basis: 15%;
                    }
                }

                &.col-2 {
                    flex-basis: 74%;
                }

                &.col-3 {
                    flex-basis: 35%;

                    @media only screen and (min-width: $media-tablet-max) {
                        order: 3;
                    }
                }
            }
        }
    }

    &__notesmedia {
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100vh - 383px);
            background-color: #ffffff;
            overflow: hidden;
            overflow-y: auto;
            .si-label {
                font-size: 14px !important;
            }
        }
        &.si-viewmode {
            .form-control {
                .si-input,
                textarea {
                    &[disabled] {
                        border: 1px solid #c8c8c8 !important;
                        height: auto !important;
                        border-color: #c8c8c8 !important;
                        background: #eaeaea;
                        font-weight: 400;
                        color: #282828;
                        -webkit-text-fill-color:#282828;
                        opacity: 1;
                    }
                }
            }
        }
        .form-control {
            margin-bottom: 20px;
            @media screen and (max-width: $media-tablet-max) {
                margin-bottom: 10px;
                textarea {
                    height: 100px;
                }
            }
        }

        .si-form-inline {
            @include flexbox();
            margin: 0 -20px;

            .form-control {
                flex-basis: 50%;
                padding: 0 20px;
            }
        }

        .custom-label__text {
            color: #6d6d6d;
            font-size: 12px;
            margin-left: 6px;
        }

        .custom-label {
            margin-bottom: 3px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .si-tab-pane {
        &__setrecurrence {
            .si-action-buttons {
                .si-action-btn {
                    padding: 8px 11px;
                    margin-left: 7px;
                    width: 86px;
                }
            }
        }
    }
}

@media only screen and (max-width: $media-tablet-max) {
    .si-custom-tabs {
        .si-tab-item {
            flex-direction: column-reverse;
            background-color: transparent;
            flex: 1;
            font-size: 10px;
            padding-right: 0;
            height: 24px;

            &.active {
                background-color: transparent;
                color: $white;
            }

            .si-icon-tab {
                margin-right: 0;
                padding: 5px 10px;
                width: 100%;
                margin-top: 1px;
            }
        }
    }

    .si-tab-pane {
        &__setrecurrence {
            .si-program {
                &-head {
                    margin-bottom: 20px;

                    .si-program-col {
                        &.col-3 {
                            margin-left: auto;
                            flex-basis: unset;
                        }

                        &.col-1 {
                            @include flexbox();
                            flex: 1;
                            align-items: center;
                        }
                    }

                    .label {
                        margin-left: 8px;
                    }
                }

                &-weeks {
                    max-height: calc(100dvh - 400px);

                    .si-program {
                        &-row {
                            position: relative;
                        }

                        &-col {
                            &.col-1 {
                                flex-basis: initial;
                            }

                            &.col-3 {
                                flex-basis: initial;
                                flex: 1;
                                position: absolute;
                                right: 10px;
                                top: 0px;
                                @media only screen and (max-width: $media-tablet-max) {
                                    right: 0;
                                }
                            }

                            &.col-2 {
                                flex-basis: 100%;

                                background: $neutral-color-8;
                                padding: 0 17px;
                                height: 61px;
                                border-radius: 5px;
                                margin-top: 10px;

                                input[type="checkbox"] {
                                    width: 22px;
                                    height: 22px;
                                    &:before {
                                        top: 3px;
                                        left: 6px;
                                    }
                                }
                            }

                            .si-action-buttons {
                                @include flexbox();
                                justify-content: flex-end;
                                padding-top: 0px;
                            }
                        }
                    }
                }

                &-list {
                    justify-content: space-between;
                    margin-top: 10px;
                }

                &-row {
                    flex-wrap: wrap;
                    background: transparent !important;
                    & + .si-program-row {
                        margin-top: 25px;
                    }
                }

                &-item {
                    flex: unset;
                }
            }

            .si-action-buttons {
                .si-action-btn {
                    margin-left: 10px;
                }
            }
        }

        &__notesmedia {
            .si-form-inline {
                display: block;
            }

            .custom-label__text {
                font-size: 10px;
            }
        }
    }
}

.si-program-duration {
    .si-program-head {
        .program-duration {
            font-weight: 700;
        }
        .label {
            font-weight: 400;
        }
    }
    .si-program-weeks {
        border-radius: 5px;
        border: 1px solid $neutral-color-5;
        border-top: 0px;
        @media only screen and (max-width: $media-tablet-max) {
            border: none;
        }
        .si-program-row {
            .si-program-col {
                span {
                    &:first-child {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
.si-tab-pane__notesmedia {
    .custom-label {
        .si-label {
            font-weight: 700 !important;
        }
    }
    .si-form-inline {
        .form-control {
            .si-label {
                font-weight: 400 !important;
            }
        }
    }
}

.si-tab-pane__editspecs {
    .content-wrapper {
        .si-custom-row {
            .si-custom-col {
                .si-wrp {
                    .form-group {
                        gap: 15px;
                        @media only screen and (max-width: $media-tablet-max) {
                            gap: 0px;
                        }
                        .form-control {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
.si-tab-content {
    textarea,
    input {
        &::placeholder {
            color: #c8c8c8;
            -webkit-text-fill-color:#c8c8c8;
            opacity: 1;
        }
    }
    .si-viewmode {
        .media-url {
            background: #eaeaea !important;
            &:hover , &:focus{
                border-color: #c8c8c8;
                box-shadow: unset;
            }
        }
    }
}
