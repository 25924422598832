.nutrition-main-content {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-height: calc(100vh - 150px);
    padding: 15px;
    margin: -15px;
    overflow-y: scroll;
    padding-top: 5px;
    margin-top: 0px;
    .nutrition-card-wrp {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }
    @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 150px);
      }
    @media screen and (max-width: $media-tablet-max) {
        max-height: calc(100dvh - 170px);
        padding-top: 0;
    }

    &::-webkit-scrollbar {
        width: 2px;
        z-index: 999;
        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }

    .card {
        width: 24.3%;
        border-radius: 5px;
        background: #fff;
        padding: 8px 13px 15px 13px;
        display: flex;
        flex-direction: column;
        align-items: start;
        // max-height: 135px;
        @media screen and (max-width: 1365px) {
            width: 24.2%;
            // max-height: 135px;
        }
        @media screen and (max-width: 1199px) {
            width: 24%;
            max-height: 145px;
        }
        @media screen and (max-width: 992px) {
            width: 32.3%;
            max-height: 145px;
        }
        @media screen and (max-width: $media-tablet-max-768) {
            width: 100%;
        }
    }
    .card-header {
        display: flex;
        align-items: center;
        &--img {
            margin-right: 8px;
            width: 51px;
            @media screen and (max-width: $media-tablet-max) {
                width: 33px;
            }
            img {
                width: 100%;
            }
        }
        &--heading {
            font-size: 16px;
            font-weight: 600;
            @media screen and (max-width: $media-tablet-max) {
                font-size: 14px;
            }
        }
        margin-bottom: 5px;
    }
    .card-body {
        margin-bottom: 10px;
        &--content {
            font-size: 10px;
            font-weight: 400;
            color: $secondary-color-5;
        }
    }
    .card-footer {
        .si-viewBtn {
            border: 1px solid #f1f1f1;
            height: 30px;
            &:focus,
            &:active,
            &:focus-within {
                border: 1px solid #282828;
                box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
            }
            &:hover {
                border: 1px solid #282828;
            }

            @media screen and (max-width: $media-tablet-max) {
                font-size: 12px;
                .si-txt {
                    display: flex;
                }
            }
        }
    }
}

// ------------team-nutrition-log----------
.nutrition-subheader {
    padding: 15px 15px 0 15px;
    &--title {
        font-size: 16px;
        font-weight: 600;
    }
}
.nutrition-log {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    height: calc(100vh - 145px);
    padding-bottom: 10px;
    .si-label {
        cursor: default;
    }
    @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 145px);
      }
    @media screen and (max-width: $media-tablet-max) {
        height: calc(100dvh - 195px);
    }
    &--header {
        background-color: #e7e0ec;
        font-size: 14px;
        font-weight: 700;
        padding: 8px 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .log-header--title {
            margin-right: 20px;
            @media screen and (max-width: $media-tablet-max) {
                display: flex;
                align-items: center;
                gap: 5px;
                flex-wrap: wrap;
            }
            .additional-text {
                color: $neutral-color-2;
                font-size: 12px;
                font-weight: 400;
            }
        }

        .title-icon-wrp {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
        .icon-edit {
            cursor: pointer;
            color: $secondary-color-1;
            font-size: 18px;
            &:hover {
                color: #454343;
            }
        }
    }
    .nutrition-log-content-wrp {
        height: calc(100% - 110px);
        padding: 15px;
        padding-bottom: 18px;
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100% - 125px);
            padding: 15px 10px;
        }
        .nutrition-log-content {
            height: 95%;
            .si-label {
                font-size: 12px !important;
            }
            textarea {
                height: 100% !important;
                width: 100%;
                padding: 8px 10px;
                background-color: $white;
                border-radius: 5px;
                resize: none;
                border: 1px solid $neutral-color-5;
                font-size: 12px;
                font-weight: 400;
                font-family: "poppins";
                color: $secondary-color-1;
                &:focus {
                    border: 1px solid $secondary-color-1;
                    box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.50);
                    
                }
                &:hover{
                    border: 1px solid $secondary-color-1;
                }
                &::placeholder {
                    font-size: 16px;
                    opacity: 1;
                    color: #c8c8c8;
                }
            }
        }
        .view-mode {
            textarea {
                border: none;
                padding: 10px;
                cursor: default;
                &:focus , &:hover{
                    border: none;
                    box-shadow: unset;
                }


            }
        }
    }
    .nutrition-log--subheader {
        padding: 8px 20px 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: $media-tablet-max) {
            padding: 8px 10px 0 10px;
        }

        .si-label {
            font-size: 12px !important;
            font-weight: 500;
            font-family: "poppins";
            margin-bottom: 0;
            margin-top: 2px;
            white-space: nowrap;
            @media screen and (max-width: $media-tablet-max) {
                font-size: 12px !important;
            }
        }
    }
    .date-form-control {
        position: relative;
        width: 118px;
    }

    .react-datepicker__view-calendar-icon::before {
        left: 12px;
    }
    .react-datepicker__view-calendar-icon input {
        color: #6e6e6e !important;
        webkit-text-fill-color: #6e6e6e !important;
        opacity: 1;
        &:focus {
            border-color: #282828;
        }
    }
    .react-datepicker__view-calendar-icon input:disabled {
        color: #6e6e6e !important;
        webkit-text-fill-color: #6e6e6e !important;
        border: none !important;
        background: none !important;
        opacity: 1;
        cursor: default;
    }
    .react-datepicker__view-calendar-icon::before {
        left: 12px;
        color: #6e6e6e !important;
        webkit-text-fill-color: #6e6e6e !important;
        // cursor: pointer;
    }
}
.nutrition-footer {
    display: flex;
    justify-content: end;
    margin-top: 15px;
}

.edit-mode-active {
    textarea {
        &::placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .view-mode {
        display: none;
    }
}
.view-mode-active {
    textarea {
        padding: 10px 15px;
        color: #6e6e6e !important;
        webkit-text-fill-color: #6e6e6e !important;
        opacity: 1;
    }
    .edit-mode {
        display: none;
    }
}
.Recommended-wrp {
    text-align: end;
    .icon-label-wrp {
        display: flex;
        align-items: center;
        @media screen and (max-width: $media-tablet-max) {
            margin-bottom: 3px;
        }
    }
    .si-label {
        font-size: 12px !important;
        font-weight: 500;
        font-family: "poppins";
        margin-bottom: 0;
        margin-top: 2px;
        white-space: nowrap;
    }
    .icon-doctor-bag {
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-image: linear-gradient(#05befc, #00b8fc, #009ae3, #0070c1);
        font-size: 20px;
        margin-right: 10px;
    }
    .doctor-name {
        font-size: 12px;
        font-weight: 400;
        color: #282828;
        margin-left: 35px;
        display: inline-block;
        margin-top: 10px;
    }
    .doctor-name-view {
        color: #6e6e6e !important;
    }
}
.Recommended-wrp {
    text-align: end;
}

.view-date {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    margin-top: 10px;
    .date {
        font-size: 12px;
        color: $neutral-color-2;
        font-weight: 400;
    }
}

.individual-nutrition-plan {
    height: calc(100vh - 185px) !important;
    @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 185px)!important;
      }
    @media screen and (max-width: $media-tablet-max) {
        height: calc(100dvh - 235px) !important;
    }
    .nutrition-log-content-wrp .nutrition-log-content {
        height: 95% !important;
        @media screen and (max-width: $media-tablet-max) {
            height: 95% !important;
        }
    }
}
.note-listing-section {
    .si-subHeader {
        margin-bottom: 15px;
    }
}

// error-state
.nutrition-log {
    .error-active {
        position: relative;
        textarea {
            border: 1px solid #e10000 !important;
        }
        &::after {
            content: "Recommendation is required";
            position: absolute;
            font-size: 10px;
            color: #e10000;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            bottom: -15px;
        }
    }
}
.internal-note-section + .main-content {
    .MuiBox-root {
        height: calc(100dvh - 200px);
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100dvh - 300px);
        }
    }
}
