@import "../variables";
@import "../mixins";
@import "../variables-en";

.si-login {
  &__page {
    height: 100dvh;

    background-image: url("../images/login.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $white;

    &::before {
      display: none;
    }

    .si-main__container {
      margin-bottom: 0px;
      @include flexbox;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: transparent !important;

      @media screen and (max-width: $media-mobile-small-max-420) {
        padding-top: 11%;
      }
    }
  }

  &__form {
    width: calc(100% - 20%);
    height: 516px;
    margin-left: auto;
    background-color: #f9f9f9;
    @include border-radius(26px 10px 10px 26px);
    @include box-shadow(0px, 0px, 30px, 0px, rgba($black, 0.5));
    @include flexbox;
    align-items: center;
    position: relative;

    @media screen and (max-width: $media-desktop-min) {
      height: 509px;
    }

    @media screen and (max-width: $media-tablet-max) {
      height: 408px;
    }

    @media screen and (max-width: $media-tablet-small-max) {
      height: 339px;
      width: calc(100% - 25%);
      margin-right: 15px;
    }

    @media screen and (max-width: $media-mobile-max) {
      width: calc(100% - 23%);
      margin-right: 18px;
      height: 363px;
    }

    @media screen and (max-width: $media-mobile-max-680) {
      height: 315px;
    }

    @media screen and (max-width: $media-mobile-max-580) {
      height: 278px;
    }

    @media screen and (max-width: $media-tablet-max) {
      @include flexbox;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin: auto;
      height: 409px;
    }
  }

  &__left {
    overflow: hidden;
    position: relative;
    width: 50%;
    height: 100%;
    border-radius: 5%;

    @media screen and (max-width: $media-tablet-max) {
      width: 100%;
      height: 50%;
    }

    &::before {
      content: "";
      background: #e10000;
      width: 530px;
      height: 600px;
      @include border-radius(0% 50% 50% 0%);
      position: absolute;
      left: -40%;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (max-width: $media-tablet-max) {
        width: 420px;
        height: 450px;
        left: -32%;
      }

      @media screen and (max-width: $media-tablet-small-max) {
        left: -66%;
      }

      @media screen and (max-width: $media-mobile-max) {
        left: -51%;
      }

      @media screen and (max-width: $media-mobile-max-680) {
        left: -77%;
      }

      @media screen and (max-width: $media-mobile-max-580) {
        left: -96%;
      }

      @media screen and (max-width: $media-tablet-max) {
        @include border-radius(0% 0% 50% 50%);
        width: 100%;
        left: 0;
        background: $transparent;
      }
    }
  }

  &__Logo {
    @include flexbox;
    flex-flow: column;
    align-items: center;
    @include border-radius(50%);
    justify-content: center;
    width: 519px;
    height: 519px;
    background: #fff;
    background-repeat: no-repeat;
    @include background-size(cover);
    z-index: 1;
    position: absolute;
    left: -24%;
    top: 50%;
    @include transform(translateY(-50%));
    @include box-shadow(-1px, 0px, 7px, 0px, rgba(0, 131, 191, 0.26));

    @media screen and (max-width: $media-tablet-max) {
      width: 453px;
      height: 457px;
    }

    & img {
      width: 358px;
      height: auto;

      @media screen and (max-width: $media-tablet-max) {
        width: 222px;
        height: auto;
        z-index: 1;
      }
    }

    @media screen and (max-width: $media-mobile-max-580) {
      width: 298px;
      height: 298px;
    }

    @media screen and (max-width: $media-tablet-max) {
      width: 220px;
      height: 55px;
    }

    @media screen and (max-width: $media-tablet-max) {
      left: 50%;
      top: 0;
      @include transform(translate(-50%, -50%));
      width: 250px;
      height: 250px;
    }

    & img {
      @media screen and (max-width: $media-mobile-max) {
        z-index: 1;
      }
    }

    &::after {
      @media screen and (max-width: $media-tablet-max) {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% + 5%);
        background-color: #e10000;
        @include border-radius(50%);
        top: 0;
      }
    }

    &::before {
      @media screen and (max-width: $media-tablet-max) {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        @include border-radius(50%);
        background-color: #fff;
        z-index: 1;
      }
    }

    & span {
      font-size: 24px;
      font-weight: $medium_font-weight;
      font-family: $medium_font-family;
      color: #fff;
      margin-top: 21px;
      z-index: 1;

      @media screen and (max-width: $media-tablet-max) {
        font-size: 20px;
        font-size: 20px;
        color: #049df3f7;
        margin-top: 3px;
      }
    }

    .si-logotext {
      color: #0a59a7;
      font-size: 28px;
      padding-top: 11px;
      font-family: $bold_font-family;

      @media screen and (max-width: $media-tablet-max) {
        font-size: 22px;
      }

      @media screen and (max-width: $media-mobile-max-680) {
        font-size: 19px;
      }

      @media screen and (max-width: $media-mobile-max-580) {
        font-size: 15px;
      }

      @media screen and (max-width: $media-tablet-max) {
        font-size: 16px;
      }
    }
  }

  &__right {
    width: 50%;
    flex-basis: 50%;
    height: calc(100% - 40%);

    @media screen and (max-width: $media-tablet-small-max) {
      height: calc(100% - 18%);
    }

    @media screen and (max-width: $media-tablet-max) {
      width: 100%;
      flex-basis: 100%;
      height: calc(100% - 40%);
      padding: 20px;
    }
  }

  &__head {
    @include flexbox;
    flex-flow: column;
    color: #282828;
    margin-bottom: 30px;

    @media screen and (max-width: $media-mobile-max) {
      margin-left: 27px;
    }

    @media screen and (max-width: $media-tablet-max) {
      margin-bottom: 15px;
      margin-left: 0px;
    }

    .si-title {
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      font-size: 36px;
      display: block;
      margin-bottom: 10px;
    }

    .si-subtitle {
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  &__input {
    width: calc(100% - 40%);

    @media screen and (max-width: $media-mobile-max) {
      margin-left: 27px;
    }

    @media screen and (max-width: $media-tablet-max) {
      width: 100%;
      margin-left: 0px;
    }

    & form {
      @include flexbox;
      flex-flow: column;

      & input {
        border: none;
        box-sizing: border-box;
        background: transparent;
        color: #282828;
        height: 30px;
        padding: 0px 25px 2px 5px;
        border-bottom: 1px solid #282828;
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;

        @include placeholder {
          color: #282828;
        }

        font-size: 16px;
        width: 100%;

        @media screen and (max-width: $media-tablet-max) {
          height: 40px;
        }
      }

      .si-username {
        position: relative;
        margin-bottom: 15px;

        &::before {
          content: "\e91e";
          font-family: "AMS" !important;
          font-size: 25px;
          color: #282828;
          right: 0;
          top: 0;
          position: absolute;
        }
      }

      .si-password {
        position: relative;

        &::before {
          content: "\e91f";
          font-family: "AMS" !important;
          font-size: 25px;
          color: #282828;
          right: 0;
          top: 0;
          position: absolute;
        }
      }
    }
  }

  &__button {
    margin-top: 20%;
    @include flexbox;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $media-tablet-max) {
      margin-top: 0%;
      position: absolute;
      background: #282828;
      width: 100%;
      left: 50%;
      bottom: -30px;
      padding: 10px 18px;
      width: calc(100% - 15%);
      @include transform(translateX(-50%));
      color: $white;
      border-radius: 50px;
    }

    & span {
      font-size: 19px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      color: #282828;

      @media screen and (max-width: $media-tablet-max) {
        color: $white;
      }
    }

    button {
      border: none;
      background: #282828;
      @include border-radius(16px);
      box-sizing: border-box;
      color: $white;
      position: relative;
      width: 70px;
      height: 25px;
      cursor: pointer;

      @media screen and (max-width: $media-tablet-max) {
        color: #0a59a7;
        background-color: $white;
      }

      &::after {
        content: "\e909";
        font-family: "AMS" !important;
        font-size: 18px;
        width: 100%;
        height: 100%;
        color: $white;

        top: -4px;
        left: 0px;
        transform: rotate(179deg);
        position: absolute;

        @media screen and (max-width: $media-tablet-max) {
          color: #0a59a7;
        }
      }
    }
  }
}
