.si-icon {
    &__button {
        width: 24px;
        height: 21px;
        background: transparent;
        border: none;
        color: #0d0d0d;
    }

    &__media {
        background-image: url("../../images/icons/icon-media.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }
}

.si-viewBtn {
    height: 18px;
    background: transparent;
    color: #787878;
    cursor: pointer;
    padding: 0 15px;
}
.icon-download {
    color: #323232 !important;
    cursor: pointer;
}
