.injury-details-main {
    position: relative;
    @media screen and (max-width: $media-tablet-min) {
        height: calc(100dvh - 174px);
        overflow-y: scroll;
    }
    .injury-athelet-profile {
        display: flex;
        align-items: center;
        .injury-athelet-profile-img {
            height: 40px;
            width: 40px;
            background-color: $white;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
                width: 20px;
            }
        }
        &-name {
            font-size: 18px;
            font-weight: 600;
            color: #282828;
            text-transform: capitalize;
        }
    }
    .injury-details-form-content {
        margin-top: 15px;
        max-height: calc(100vh - 200px);
        overflow: auto;
        margin-top: 15px;
        @media screen and (max-width: $media-tablet-max) {
            margin-left: -10px;
            margin-right: -10px;
        }
        @media screen and (max-width: $media-tablet-max) {
            margin-left: -10px;
            margin-right: -10px;
            max-height: unset;
        }

        &::-webkit-scrollbar {
            width: 3px;
            z-index: 999;

            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }

        &::-webkit-scrollbar-track {
            z-index: 999;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(#242424, 0.2);
            border-radius: 10px;
            z-index: 999;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(#242424, 0.2);
            z-index: 999;
        }

        .si-title {
            font-weight: 700;
            font-size: 14px;
            color: $secondary-color-1;
            background: #e7e0ec;
            border-radius: 5px;
            padding: 3px 15px;
        }
        .injury-description {
            margin-top: 5px;
            @media screen and (max-width: $media-tablet-max) {
                margin-top: 0px;
                .form-control {
                    margin-bottom: 10px;
                }
            }
            .si-error {
                textarea {
                    border: 1px solid #e10000;
                }
                .si-error-message {
                    margin-top: -5px !important;
                }
            }
            textarea {
                text-align: start;
                color: $secondary-color-1;
                padding: 7px;
                opacity: 1;
            }
        }
        .athlete-name {
            background: $neutral-color-5;
            width: 350px;
            display: block;
            color: $neutral-color-1;
            @media screen and (max-width: $media-tablet-min) {
                width: 338px;
            }
        }
        .for-athelets-content {
            display: flex;
            gap: 15px;
            background: $white;
            border-radius: 5px;
            padding: 6px 15px 10px 15px;
            @media screen and (max-width:1199px) {
                flex-direction: column;
                gap: 0px;
            }
        }
        .for-athelets-section {
            input[type="date"] {
                position: relative;
                width: 130px;
            }
            &__left {
                .left-outer-wrp {
                    display: flex;
                    gap: 15px;
                    @media screen and (max-width: $media-tablet-min) {
                        flex-direction: column;
                        gap: 0px;
                    }
                    .left-inner-wrp {
                        display: flex;
                        gap: 15px;
                    }
                }
                .injury-input {
                    width: 200px;
                    display: block;
                    @media screen and (max-width: $media-tablet-min) {
                        width: 157px;
                    }
                }
                .select-type {
                    width: 197px;
                    display: block;
                    @media screen and (max-width: $media-tablet-min) {
                        width: 325px;
                    }
                }
            }
            &__right {
                width: fill-available;
                width: -webkit-fill-available;

                @media screen and (max-width: $media-tablet-min) {
                    width: unset;
                }
                .drag-drop-container {
                    display: flex;
                    border: 1px dashed #0075e1;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 15px 15px 12px 40px;
                    @media screen and (max-width: $media-tablet-min) {
                        padding: 10px;
                    }

                    .drag-drop__icon {
                        i {
                            color: #0075e1;
                            font-size: 32px;
                            @media screen and (max-width: $media-tablet-min) {
                                font-size: 24px;
                            }
                        }
                    }
                    .drag-drop__text-wrp {
                        margin-left: 15px;
                        @media screen and (max-width: $media-tablet-max) {
                            margin-left: 10px;
                        }
                        .title-text {
                            font-size: 12px;
                            font-weight: 400;
                            color: $secondary-color-1;
                            @media screen and (max-width: $media-tablet-max) {
                                font-size: 14px;
                            }
                            input {
                                width: 65px;
                                position: relative;
                                @media screen and (max-width: $media-tablet-max) {
                                    left: 1px;
                                    margin: 0px 3px;
                                    width: 76px;
                                    font-size: 14px;
                                }
                            }
                            input::file-selector-button {
                                color: dodgerblue;
                                border: none;
                                background: none;
                                text-transform: lowercase;
                                cursor: pointer;
                                padding: 0px !important;
                                margin-right: 0px !important;
                            }
                            input[type="file"] {
                                color: transparent;
                                font-size: 12px;
                                @media screen and (max-width: $media-tablet-max) {
                                    font-size: 14px;
                                }
                            }
                        }
                        .info-text {
                            font-size: 10px;
                            color: #888888;
                            font-weight: 400;
                        }
                        .error-text {
                            font-size: 10px;
                            color: $primary-color-1;
                            font-weight: 400;
                            display: none;
                        }
                    }
                }
                .uploaded-file-wrp {
                    margin-top: 13px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                    cursor: auto;
                    .uploaded-file {
                        display: flex;
                        align-items: center;
                        position: relative;
                        @media screen and (max-width: $media-tablet-min) {
                            width: 100%;
                        }
                        .file-name-tooltip {
                            font-size: 12px;

                            background-color: #85736e;
                            border-radius: 7px 7px 0px 7px;
                            padding: 5px 10px;
                            color: $white;
                            position: absolute;
                            right: 0%;
                            bottom: 110%;
                            display: none;
                            max-width: 200px;
                            word-wrap: break-word;
                            z-index: 2;
                            @media screen and (max-width:1024px){
                                display: none;
                            }
                        }
                        &:hover {
                            .file-name-tooltip {
                                display: inline-block;
                                @media screen and (max-width:1024px){
                                    display: none;
                                }
                            }
                        }

                        .icon-pdf {
                            height: 28px;
                            width: 28px;
                            display: inline-block;
                            margin-right: 3px;
                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }
                        .file-name {
                            font-size: 12px;
                            color: $secondary-color-2;
                            font-weight: 400;
                            margin-right: 15px;
                            @media screen and (max-width:1024px) {
                                width: 90%;
                            }
                            p {
                                width: 55px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                height: 18px;
                                @media screen and (max-width:1024px) {
                                    width: 90%;
                                    height: unset;
                                
                                }
                            }
                        }
                        .icon-delete-2 {
                            cursor: pointer;
                            font-size: 13px;
                            @media screen and (max-width: $media-tablet-min) {
                                position: absolute;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
        .for-physiotherapist-only {
            margin-top: 15px;

            .physiotherapist-content-wrp {
                background-color: $white;
                border-radius: 5px;
                padding: 6px 15px 10px 15px;
                .drpdwn-wrp {
                    display: flex;
                    gap: 15px;
                    @media screen and (max-width: $media-tablet-min) {
                        flex-direction: column;
                        gap: 0;
                    }
                }
                .custom-select__control {
                    min-height: 30px !important;
                }
            }
        }
        .for-physiotherapist-disabled {
            .si-input {
                background: $neutral-color-7;
            }
            .injury-description {
                textarea {
                    background: $neutral-color-7;
                    border: 1px solid #c8c8c8;
                    opacity: 1;
                    color: $neutral-color-2 !important;
                    -webkit-text-fill-color:$neutral-color-2;
                    &::placeholder {
                        color: #6e6e6e !important;
                        -webkit-text-fill-color: #6e6e6e!important;
                    }
                }
            }
            .custom-select__control {
                min-height: 30px !important;
                background: #f9f9f9 !important;
                border-color: #c8c8c8;
            }
        }
        .si-error-message {
            display: none;
        }
        .si-error {
            .si-error-message {
                display: block;
                position: unset;
                margin-top: 1px;
                position: absolute;
            }
            input,
            select {
                border: 1px solid #e10000;
            }
        }
        .custom-select__control {
            @media screen and (min-width: 1200px) {
                width: 197px;
            }
            @media screen and (min-width: 769px) and (max-width: 1199px) {
                width: 140px;
            }
        }
        .date-form-control {
            position: relative;
            cursor: pointer;
            width: 120px;
            @media screen and (max-width: $media-tablet-max) {
                width: 130px;
            }
            input {
                color: #282828;
                font-size: 12px;
                font-family: "poppins";
                -webkit-text-fill-color:#282828;
                opacity: 1;
                @media screen and (max-width: $media-tablet-max) {
                    font-size: 14px;
                }
            }
        }
        .react-datepicker__view-calendar-icon {
            &::before {
                top: 7px;
            }
        }
        .react-datepicker__calendar-icon {
            width: 1.8em;
            height: 1.8em;
        }
        .css-1jqq78o-placeholder {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .react-datepicker__view-calendar-icon input {
            border-color: rgb(204, 204, 204);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            height: 30px;
            width: 100%;
            font-family: "poppins";
            cursor: pointer;
            @media screen and (max-width: $media-tablet-min) {
                width: 100%;
            }
        }
        .react-datepicker__view-calendar-icon input {
            &:disabled {
                background: rgb(242, 242, 242) !important;
                color: #282825 !important;
                background-color: rgb(242, 242, 242);
                border-color: rgb(230, 230, 230);
                background-color: rgb(242, 242, 242);
                border-color: rgb(230, 230, 230);
                border-radius: 4px;
                -webkit-text-fill-color:#282825!important;
                opacity: 1;
            }
        }
        .css-hlgwow {
            padding: 2px 5px !important;
        }

        .css-16xfy0z-control {
            min-height: 32px;
        }
        .form-control {
            margin-bottom: 6px;
            @media screen and (max-width: $media-tablet-max) {
                margin-bottom: 15px;
            }
        }
    }
    .injury-details-footer {
        margin-top: 15px;
        display: flex;
        justify-content: end;
        @media screen and (max-width:$media-tablet-max){
            position: fixed;
            bottom: 10px;
            width: 95%;
            right: 10px;
        }
        @media screen and (min-width:768px) and (max-width:$media-tablet-max) {
            width: 97.5%;
            
        }
    }
}
.si-error-message {
    display: none;
}
.si-error {
    .si-error-message {
        display: block;
        position: absolute;
    }
}
.no-comment-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #282828;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.5;
}

