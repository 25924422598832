.si-select {
  text-transform: capitalize;
}

// .custom-select__menu-list
.custom-select__menu-list::-webkit-scrollbar {
  width: 2.5px !important;
  height: 2px;
}
.custom-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-select__menu-list::-webkit-scrollbar-thumb {
  background: rgba(#242424, 0.2) !important;
  border-radius: 5px;
}
.select-panel {
  background: white !important;
}
