.si-athleteprofile-tab {
    .si-sections {
        @media screen and (max-width: $media-tablet-max) {
            padding-left: 0px !important;
            padding-right: 0px !important;
            gap: 15px;
        }
    }
}
.si-assesment,
.si-tab-pane__assesments {
    .si-sections {
        @include flexbox();
        flex-wrap: wrap;
        gap: 14px;
        @media screen and (max-width: $media-tablet-max) {
            gap: 15px;
        }
    }

    .si-testwrp {
        width: 24%;
        min-height: 220px;
        background: $white;
        @include border-radius(5px);
        padding: 14px;
        position: relative;

        @media screen and (max-width: 1299px) {
            width: 32%;
            min-height: 205px;
        }

        @media screen and (max-width: 992px) {
            width: 49%;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0;
            .si-button {
                width: unset;
            }
        }
    }

    .si-testimg {
        width: 40px;
        height: 45px;
        & img {
            width: 100%;
            height: 100%;
        }
    }

    .si-top {
        @include flexbox();
        padding-bottom: 15px;
        justify-content: flex-start;
        align-items: center;
        height: 80px;
        @media screen and (max-width: $media-tablet-max) {
            height: unset;
        }
    }

    .si-testName {
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;
        font-size: 16px;
        color: $secondary-color-1;
        padding-left: 13px;
        line-height: 23px;
        width: 166px;
        @media screen and (max-width: $media-tablet-max) {
            width: unset;
            font-size: 14px;
        }
    }

    .si-middle {
        color: $secondary-color-5;
        font-size: 10px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        margin-bottom: 15px;
        min-height: 100px;
        padding-right: 5px;
        overflow-y: scroll;
        @media screen and (max-width: 1100px) {
            min-height: 120px;
            font-size: 12px;
        }
        @media screen and (max-width: $media-tablet-max) {
            min-height: 60px;
        }
        &::-webkit-scrollbar {
            width: 3.68px;
            z-index: 999;
            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }

        /* Track */
        &::-webkit-scrollbar-track {
            z-index: 999;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(#242424, 0.2);
            border-radius: 10px;
            z-index: 999;
        }
    }

    .si-bottom {
        button {
            height: 30px;
            padding: 8px 20px;
            font-size: 12px;
            font-family: $semibold_font-family;
            font-weight: $semibold_font-weight;
            white-space: nowrap;
            @media screen and (max-width: $media-tablet-max) {
                padding: 8px 17px;
            }
        }
    }
}

.si-assesment {
    height: calc(100vh - 83px);
    overflow-y: auto;
    @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 83px);
      }
    @media screen and (max-width: $media-tablet-max) {
        height: calc(100dvh - 145px);
    }

    &::-webkit-scrollbar {
        width: 3.68px;
        z-index: 999;
        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
    }

    /* Track */
    &::-webkit-scrollbar-track {
        z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }
}

.si-Performanceheader {
    &.si-reports {
        .si-Left {
            width: 100%;
            flex: 1;
        }
    }
}

.si-reportdrp {
    @include flexbox();
    align-items: center;

    .si-testimg {
        width: 40px;
        height: 45px;
        & img {
            width: 100%;
            height: 100%;
        }
    }

    .si-dropdown {
        margin-left: 10px;
        min-width: 210px;

        select {
            border: none;
            background: transparent;
            font-family: $semibold_font-family;
            font-weight: $semibold_font-weight;
            font-size: 16px;
            color: $secondary-color-1;
        }
        .custom-select__single-value {
            font-size: 16px;
            color: #282828;
            font-weight: 600;
        }
        .custom-select__control {
            background: rgba(251, 248, 248, 0.97);
            border: none !important;
        }
        .custom-select__control:hover {
            background: #eaeaea !important;
        }
        .custom-select__control:focus {
            background: #eaeaea !important;
        }
        .custom-select__control--is-focused {
            background-color: #eaeaea;
        }
        .custom-select__menu {
            background: #ffffff !important;
            border: 1px solid #eaeaea !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 5px !important;
        }
        .custom-select__option {
            border-top: 0px !important;
            border-bottom: 0.5px solid #f1f1f1;
            &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }
    }
}
@media screen and (max-width: $media-tablet-max) {
    .add-new-report {
        display: none;
    }
}

.si-nexttest {
    width: 100%;
    height: 40px;
    background-color: $patch-color;
    margin-top: 10px;
    @include flexbox();
    @include border-radius(5px);
    padding: 0 10px;
    align-items: center;
    font-size: 16px;
    @media screen and (max-width: $media-tablet-max) {
        font-size: 13px;
        margin-top: 5px;
    }

    .icon-test {
        font-size: 20px;
        @include flexbox();
        justify-content: flex-start;
        width: auto;
        margin-right: 10px;
    }

    .si-cover {
        @include flexbox();
    }

    .si-wrp {
        @include flexbox();
        align-items: center;
    }

    .si-nexttxt {
        color: $secondary-color-5;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        white-space: nowrap;

        @media screen and (max-width: $media-tablet-max) {
            margin-right: 4px;
        }
    }
    .si-date {
        color: $secondary-color-1;
        font-family: $semibold_font-family;
        font-weight: 700;
    }

    .si-conducttxt {
        color: $secondary-color-5;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        padding: 0 6px;
        white-space: nowrap;

        @media screen and (max-width: $media-tablet-max) {
            padding-left: 0;
        }
    }
}

.si-reportwrp {
    @include flexbox();
    flex-flow: column;
    width: 100%;
    .custom-select__menu-list {
        max-height: 230px;
    }
}

.si-testcover {
    @include flexbox();
    color: $secondary-color-1;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .si-namewrp {
        @include flexbox();
        align-items: center;
    }

    .si-conductdate {
        @include flexbox();
        align-items: center;
        padding-left: 8px;
        .test-date-input {
            width: 130px;
        }
        .si-datepicker {
            width: 120px;
        }
        .react-datepicker__view-calendar-icon input {
            border: 1px solid $neutral-color-5;
            padding: 6px 10px 5px 35px !important;
            &:focus {
                border: 1px solid #282828;
                box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
            }
            &:hover {
                border: 1px solid #282828;
            }
        }
    }

    .si-Image {
        width: 40px;
        height: 40px;
        background-color: $white;
        @include border-radius(50%);

        & img {
            width: 28px;
            height: auto;
            margin: auto;
            text-align: center;
            @include flexbox();
        }
    }

    .si-name {
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;

        padding-left: 10px;
        font-size: 18px;
        line-height: 27px;
    }

    .si-download {
        margin-left: 10px;
        color: $secondary-color-1;
        font-size: 12px;
        background: $white;
        border: 1px solid $neutral-color-5;
        height: 30px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        padding: 5.5px 13px;
        &:focus {
            border: 1px solid #282828;
            box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
        }
        &:hover {
            border: 1px solid #282828;
        }
        .icon-download,
        .icon-upload {
            font-size: 17px;
            @media screen and (max-width: $media-tablet-max) {
                margin-right: 0px;
            }
        }
        span {
            &:last-child {
                @media screen and (max-width: $media-tablet-max) {
                    display: none;
                }
            }
        }
    }
    .si-download-count {
        font-size: 12px;
        font-weight: 500;
        height: 18px;
        width: 18px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: #f6e1a7;
        border-radius: 50px;
        color: #000000;
        position: absolute;
        right: 8px;
        top: 75px;
        @media screen and (max-width: $media-tablet-max) {
            top: -7px;
            left: 270px;
        }
    }
}
.si-reportdetails-wrapper {
    overflow: scroll;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    &::-webkit-scrollbar {
        width: 3.68px;
        z-index: 999;
        height: 0px;
        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
    }
    &::-webkit-scrollbar-track {
        z-index: 999;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }
}
.si-reportdetails {
    min-width: 1090px;
    // overflow-x: scroll;
    .si-tbls {
        color: $secondary-color-1;
        font-size: 12px;

        &__head,
        &__row {
            @include flexbox();
            width: 100%;
        }

        &__row {
            align-items: center;
        }

        &__head {
            background-color: #f5eefa;
            border: 1px solid $neutral-color-5;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding-left: 15px;
            font-family: $semibold_font-family;
            font-weight: $semibold_font-weight;
            height: 36px;
            display: flex;
            align-items: center;
        }

        &__body {
            font-family: $regular_font-family;
            font-weight: $regular_font-weight;
            overflow-y: auto;
            height: calc(100vh - 222px);
            @media screen and (min-width: 993px) and (max-width:1199px) {
                height: calc(100dvh - 222px);
              }
            @media screen and (max-width: $media-tablet-max) {
                height: calc(100vh - 295px);
            }

            &::-webkit-scrollbar {
                width: 3.68px;
                z-index: 999;
                height: 0px;
                @media screen and (max-width: $media-tablet-max) {
                    width: 0px;
                }
            }

            /* Track */
            &::-webkit-scrollbar-track {
                z-index: 999;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(#242424, 0.2);
                border-radius: 10px;
                z-index: 999;
            }
            .si-tbls__row {
                min-height: 35px;
                padding-left: 15px;
                margin-top: 4px;
                &:last-child {
                    margin-bottom: 8px;
                }
                &:first-child {
                    margin-top: 8px;
                }
            }
        }

        .si-score {
            width: 80px;
            height: 30px;
            text-align: center;
            color: #282828;
            border: 0;
            border: 1px solid $neutral-color-4;
            @include border-radius(5px);
            input {
                text-align: center;
                color: #282828;
                border: 0;
                border-color: $neutral-color-4;
                -webkit-text-fill-color:#282828;
                opacity: 1;
            }
            &:focus {
                border: 1px solid #282828;
                box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
            }
            &:hover {
                border: 1px solid #282828;
            }
        }

        .si-tableleft {
            .si-textarea {
                border-radius: 5px;
                resize: none;
                border-color: $neutral-color-4;
                padding: 5px;
                padding-right: 5px;
                padding-left: 10px;
                margin-top: 6px;
                max-height: 30px;
                color: #282828;
                font-family: "Poppins", sans-serif;
                font-weight: 400;
                font-size: 12px;
                &:focus {
                    border: 1px solid #282828;
                    box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
                }
                &:hover {
                    border: 1px solid #282828;
                }
            }
        }

        .si-tableright {
            padding-right: 27px;
            padding-left: 5px;
            align-self: baseline;
            .si-textarea {
                width: 100%;
                min-height: 116px;
                border-color: $neutral-color-4;
                @include border-radius(5px);
                resize: none;
                padding: 0 10px;
                color: $secondary-color-1;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                font-size: 12px;
                margin: 10px 0px;
                padding-top: 5px;
                &:focus {
                    border: 1px solid $secondary-color-1;
                    box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
                }
                &:hover {
                    border: 1px solid $secondary-color-1;
                }
            }
        }
        .si-tbls__head {
            .si-tableright {
                align-self: unset !important;
            }
        }
        .si-drpdown {
            select {
                width: 220px;
                height: 30px;
                background-color: transparent;
                @include border-radius(5px);
                padding: 0 10px;
                color: $secondary-color-1;
                font-size: 12px;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                border-color: $neutral-color-4;
            }
        }
        .si-resultwrp {
            height: auto;
            @include flexbox();
            flex-flow: column;
            background: $white;
            border: 1px solid $neutral-color-5;
            @include border-radius(5px);
            margin-top: 6px;
            .si-tblwrp {
                @include flexbox();
            }

            .si-head {
                height: 30px;
                background: $patch-color;
                width: 100%;
                color: $secondary-color-1;
                font-family: $semibold_font-family;
                font-weight: $semibold_font-weight;
                font-size: 12px;
                @include flexbox();
                align-items: center;
                padding: 0 15px;
            }
        }

        .si-vscore {
            display: none;
        }
        .si-interpretation {
            display: none;
        }
        .si-viewtext {
            display: none;
        }
    }
    .si-viewtbls {
        @extend .si-tbls;
        .si-vscore {
            width: 80px;
            height: 50px;
            text-align: center;
            color: #282828;
            border: 0;
            background-color: $neutral-color-6;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        .si-viewtext {
            display: inline-block;
            width: 100%;
            height: auto;
            padding-top: 15px;
        }
        .si-interpretation {
            display: inline-block;
            width: 142px;
        }
        .si-additional-information{
            width: 100%!important;
        }
        .si-score {
            display: none;
        }
        .si-textfield {
            display: none;
        }
        .si-textarea {
            display: none;
        }
        .si-tbls__row {
            min-height: 35px !important;
            margin-top: 0px !important;
            .si-vscore {
                height: 40px !important;
            }
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
}
.si-tablefooter {
    @include flexbox();
    padding: 0 14px;
    height: 42px;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    position: relative;
    .si-left {
        color: $secondary-color-1;
        font-size: 10px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
    }
    .si-footer-right {
        position: absolute;
        right: 18px;
    }
    .si-right {
        .si-btnwrp {
            @include flexbox();
        }
    }
}
// ---------------new----------
.si-tbls__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.si-tableleft {
    //   width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: auto;
}
.si-tableright {
    // width: 25%;
    flex: 1;
}
.si-tblwrp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.si-tbls__row {
    align-items: center;
    justify-content: space-between;
}
.si-tbls__cell {
    flex: auto;
}
.si-suggstion,
.si-inter {
    text-align: center;
}
.si-text {
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    .si-start-date {
        margin-left: 10px;
    }
}

.si-mob-footer {
    display: none;
}
.si-search {
    display: none;
}
@media screen and (max-width: $media-tablet-max) {
    .si-left,
    .si-footer-right {
        display: none;
    }
    .si-mob-footer {
        display: block;
        width: 100%;
    }
    .si-Title--pre {
        display: none;
    }
    .si-namewrp {
        display: none !important;
    }
    .si-search {
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 5px;
        padding: 10px;
        margin-right: 20px;
    }
}

// ---new---

.si-main-testAss {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    .si-sections {
        .si-disabled {
            .si-testimg {
                img {
                    filter: grayscale(1);
                }
            }
            .si-testName,
            .si-middle {
                color: $neutral-color-3;
            }
        }
    }
}

// ---try-for-table -issue-27
.si-reportdetails {
    .si-tblwrp {
        .si-tableleft {
            .si-tbls__row {
                .si-tbls__cell {
                    width: 135px !important;
                    word-wrap: break-word;
                    text-align: center;
                    &:first-child {
                        text-align: left;
                        width: 150px;
                    }
                }
            }
        }
    }
    .si-tbls__head {
        .si-tbls__cell {
            width: 135px !important;
            word-wrap: break-word;
            text-align: center;
            span {
                width: 135px;
                text-align: center;
            }
            &:first-child {
                text-align: left;
                width: 150px;
            }
        }
    }
}

.test-report__name-text {
    font-size: 16px;
    font-weight: 600;
    color: #282828;
    margin-left: 10px;
    display: inline-block;
    text-transform: capitalize;
}

.subheader-test-name {
    @media screen and (max-width: $media-tablet-max) {
        display: none;
    }
}

.si-athleteprofile-tab {
    .si-main-testAss {
        padding-left: unset;
        padding-right: unset;
        margin-top: 0px !important;
    }
}
@media screen and (max-width: $media-tablet-max) {
    .si-subheader-testAsst {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
}

.si-tblwrp {
    .si-tableleft {
        .si-tbls__row {
            .si-tbls__cell {
                .other-comments {
                    max-height: 78px;
                    width: 98%;
                }
            }
        }
    }
}
