.si-input {
    @include border-radius(5px);
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    border: 1px solid $neutral-color-4;
    background-color: $white;
    // color: $secondary-color-7;
    color: $secondary-color-1;
    -webkit-text-fill-color:$secondary-color-1;
    width: 100%;
    padding: 5px 10px;
    resize: none;
    height: 30px;
    transition: 0.2s;
    font-size: 12px;
    opacity: 1;
    @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
    }

    &:focus {
        border-color: $secondary-color-1;
        box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
    }
    &:hover {
        @media screen and (min-width: 769px) {
            border-color: $secondary-color-1;
        }
    }

    &-sets {
        width: 60px;
    }

    &.border--red {
        border-color: $red;
    }

    &[disabled] {
        background-color: transparent;
        border-color: transparent;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #c8c8c8;
        -webkit-text-fill-color:#c8c8c8;
        opacity: 1;
        font-weight: 400;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #c8c8c8;

        font-weight: 400;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #c8c8c8;

        font-weight: 400;
    }
}

.si-duration {
    @include flexbox();
    align-items: center;

    .si-input {
        width: 32%;
        @media screen and (max-width: $media-tablet-max) {
            width: 42%;
        }
    }
}

.si-error-message {
    font-size: 10px;
    color: $primary-color-1;
    display: none;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    margin-top: 1px;
}

.si-form {
    .form-control {
        & + .form-control {
            margin-top: 30px;
        }
    }
}

.si-label {
    color: $secondary-color-1;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 7px;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    cursor: default;
    @media screen and (max-width: $media-tablet-max) {
        font-size: 14px !important;
        margin-bottom: 5px;
    }

    &-append {
        color: $secondary-color-1;
        font-weight: 400;
        font-size: 14px;
        margin-left: 10px;
        text-transform: capitalize;
    }
}

.si-select {
    text-transform: capitalize;
}
.si-popup__description {
    textarea {
        font-size: 12px !important;
        font-weight: 400 !important;
        &.si-input {
            background: rgba(255, 255, 255, 0.3);
            &::placeholder {
                color: #c8c8c8 !important;
                -webkit-text-fill-color:#c8c8c8;
                font-weight: 400;
                font-size: 12px;
                opacity: 1;
            }
            &[disabled] {
                background: rgba(200, 200, 200, 0.3);
                opacity: 1;
            }
        }
    }
}

.si-textfield {
    .si-input {
        width: 88%;
    }
}

@media screen and (max-width: $media-laptop-max-1280) {
}

// ---calender-icon---
.test-date-input {
    position: relative;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 1;
    display: block;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    border-width: thin;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 5%;
}
input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
    left: 10%;
}
input::-webkit-datetime-edit {
    position: relative;
    left: 10%;
}

input:focus {
    &::placeholder {
        opacity: 0;
    }
}
.search {
    input:focus {
        &::placeholder {
            opacity: unset;
        }
    }
}
textarea:focus {
    &::placeholder {
        opacity: 0;
    }
}

.si-error {
    .custom-select__control {
        border-color: #e10000;
    }
    .si-datepicker {
        border: 1px solid #e10000 !important;
    }
}
