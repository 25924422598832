
* {
    margin: 0;
    padding: 0;
    @include box-sizing(border-box);
}


html, body {
   height: 100dvh!important;
}
body {
    font-family: $regular_font-family;
    // overflow: hidden;
    background-color: #f9f9f9;
    &.si-ar {
        direction: rtl;
    }
}

.si-main {
    color: $black;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-user-select: none;
    -webkit-user-select: none;
   

    &__container {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }

    &__containerFluid {
        width: 100%;
    }

    

    ul,
    ol {
        margin: 0;
        padding: 0;

        list-style: none;
    }

    em {
        font-style: normal;
        line-height: 1;
    }

    a {
        text-decoration: none;
    }

    input:focus,
    textarea:focus {
        @include placeholder {
            color: $transparent;
            outline: none;
            box-shadow: none;
        }
        caret-color: black;
    }

    input,
    textarea {
        @include placeholder {
            color: rgba($black, .5);
            outline: none;
            box-shadow: none;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    :focus {
        outline: none;
    }

    *,
    *:before,
    *:after {
        @include box-sizing(border-box);
        -webkit-tap-highlight-color: rgba($black, 0);
        outline: 0;
        -webkit-tap-highlight-color: transparent;
    }
}

.si-page-bg {
    position: relative;
    width: 100%;

    &::before {
        content: ' ';
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        will-change: transform;
        z-index: -1;
        background-color: $black;
        background-repeat: no-repeat;
        background-position: center center;
        @include background-size(cover);
    }

    &.si-interfaceBg {
        &::before {
            background-image: url(../images/interface-bg.jpg);
        }
    }
}

.bodyNoScroll {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    @media screen and (max-width: $media-mobile-max) {
        position: fixed;
    }
}

.si-frm-errMsg {
    color: $red;
    font-size: 12px;
}

.si-flexBox {
    @extend %flexbox;
}

.si-flexBox-reverse {
    flex-direction: row-reverse;
}

//Flexbox Grid
.si-fBox {
    @include flexbox;

    &__fItem {
        padding: 0 10px;

        &--1by3 {
            flex-basis: 33.33%;
            width: 33.33%;
        }

        &--10 {
            flex-basis: 10%;
            width: 10%;
        }

        &--20 {
            flex-basis: 20%;
            width: 20%;
        }

        &--30 {
            flex-basis: 30%;
            width: 30%;
        }

        &--40 {
            flex-basis: 40%;
            width: 40%;
        }

        &--50 {
            flex-basis: 50%;
            width: 50%;
        }

        &--60 {
            flex-basis: 60%;
            width: 60%;
        }

        &--70 {
            flex-basis: 70%;
            width: 70%;
        }

        &--80 {
            flex-basis: 80%;
            width: 80%;
        }

        &--90 {
            flex-basis: 90%;
            width: 90%;
        }

        &--100 {
            flex-basis: 100%;
            width: 100%;
        }

        &--1by3,
        &--10,
        &--20,
        &--30,
        &--40,
        &--50,
        &--60,
        &--70,
        &--80,
        &--90,
        &--100 {
            @media screen and (max-width: $media-tablet-max) {
                flex-basis: 100%;
                width: 100%;
            }
        }
    }
}

//table style start here
.si-tbl {
    display: table;
    width: 100%;

    &__head,
    &__body,
    &__foot {
        display: table-header-group;
    }

    &__row {
        display: table-row;
    }

    &__cell {
        display: table-cell;
        vertical-align: middle;
    }
}


//shortname/fullname
.si-name {
    &__short {
        display: none;

        @media screen and (max-width: $media-mobile-max) {
            display: block;
        }
    }

    &__full {
        @media screen and (max-width: $media-mobile-max) {
            display: none;
        }
    }
}

//
.si-noDataFound {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    padding: 50px 5px;
    // font-family: $bold_font-family_primary;
    // font-weight: $bold_font-weight;
    background: #f6f3f3;

    @media screen and (max-width: $media-mobile-max) {
        font-size: 12px;
    }
}

//Buttons
.si-btn {
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    min-width: 200px;
    text-align: center;
    position: relative;
    transition: all .3s ease-in-out;
    text-transform: capitalize;
    background: $white;
    color: rgba($black, .7);
    @include border-radius(4px);
    // @include box-shadow(4px, 3px, 4px, -2px, rgba($black, 0.5));

    span {
        position: relative;
        padding: 0 10px;
        width: 100%;
        height: 100%;
        @include flexbox;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background: transparent;
    }

    &.active {
        background-color: $white;
        color: $client-secondary-color;
        border: 1px solid $client-primary-color;

        & span {
            color: $client-secondary-color;
        }
    }

    &__primary {
        background-color: $client-primary-color;
        height: 30px;
        color: $white;

        span {
            font-size: 14px;
            // font-family: $semi-bold_font-family_primary;
            // font-weight: $semi-bold_font-weight;
            line-height: 1;
        }

        &:hover {}

        &:active {}

        &--outline {
            height: 30px;
            background-color: transparent;
            border: 1px solid $client-secondary-color;

            & span {
                font-size: 14px;
                // font-family: $semi-bold_font-family_primary;
                // font-weight: $semi-bold_font-weight;
                line-height: 1;
            }
        }
    }

    &__secondary {
        &:hover {}

        &:active {}

        &--outline {
            &:hover {}

            &:active {}
        }
    }

    &__deactive {
        &:hover {}

        &:active {}

        &--outline {
            &:hover {}

            &:active {}
        }
    }
}

//Form lable
.si-lbl {
    margin-bottom: 5px;
    font-size: 14px;
    color: $black;
}

//Form Dropdown
.si-drpDwn {
    position: relative;
    display: inline-block;
    width: 100%;

    &.active {
        .si-drpDwn__value {
            z-index: 1111;

            &:after {
                content: map-get($si-icons, downArrow);
                @include transform(translateY(-50%) rotate(180deg));
            }
        }

        .si-drpDwn__list {
            display: block;

            @media screen and (max-width: $media-mobile-max) {
                display: none;
            }
        }
    }

    &__value {
        cursor: pointer;
        position: relative;
        padding: 10px;
        padding-right: 40px;
        border: 1px solid $client-primary-color;
        // background: rgba($white, .65);
        font-size: 14px;
        text-transform: uppercase;
        color: #fff;
        height: 34px;
        @include text-truncate;
        outline: none;
        border: 0;

        &:after {
            content: "\e905";
            font-family: 'AMS' !important;
            color: #fff;
            position: absolute;
            left: auto;
            right: 10px;
            top: 23%;
            @include transform(translateY(-50%));
            @include transform(rotate(90deg));
            display: none;
        }

    }

    &__list {
        display: none;
        position: absolute;
        background-color: $white;
        width: 100%;
        z-index: 111;
        left: 0;
        top: 0px;
        padding-top: 30px;
        padding-bottom: 10px;
        @include box-shadow(0px, 2px, 5px, 0px, rgba($black, 0.2));
        @include border-radius(16px);

        &Up {
            bottom: 40px;
            top: auto;
        }

        .swiper-container {
            height: 100%;
        }

        ul {
            height: 200px;
            overflow: auto;

            li {
                font-size: 14px;
                padding: 10px 25px;
                cursor: pointer;
                color: $font-color;
                border-bottom: 1px solid rgba($black, .2);

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background: rgba($black, .1);
                }
            }
        }
    }
}

//Form Input
.si-input {
    input {
        box-shadow: none;
        outline: none;
        background: transparent;
        border: 1px solid $black;
        font-size: 14px;
        color: $client-secondary-color;
        padding: 10px;
        padding-right: 35px;
        height: 40px;
        width: 100%;
        display: block;
    }

    &--success {
        input {
            border-color: green;
        }
    }

    &--danger {
        input {
            border-color: red;
        }
    }
}

//radio btn
.si-radioBox {
    [type="radio"] {

        &:checked,
        &:not(:checked) {
            position: absolute;
            left: -9999px;

            &+label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: $client-secondary-color;
                -moz-user-select: none;
                -webkit-user-select: none;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #ddd;
                    @include border-radius(100%);
                    background: $white;
                }

                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $client-primary-color;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    @include border-radius(100%);
                    @include transition(all, .2s, ease);
                }
            }
        }

        &:not(:checked)+label:after {

            opacity: 0;
            @include transform(scale(0));

        }

        &:checked+label:after {
            opacity: 1;
            @include transform(scale(1));
        }
    }
}

.si-form {
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__control {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}


.si-box1 {
    @include maintain-ratio(4 3);

    img {
        display: block;
        width: 100%;
    }
}

//custom icon common style
.si-icon {
    position: relative;
    @include flexbox;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::after {
        content: '';
        font-family: $icon-font;
        position: absolute;
        top: 50%;
        font-size: 18px;
        color: $black;
        @include transform(translateY(-50%));
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &.si-arrowDown {
        &::after {
            content: map-get($si-icons, downArrow);
            color: $client-primary-color;
        }
    }
}

.si-closeBtn {
    width: 20px;
    height: 20px;

    &::after {
        content: "\e902";
        font-family: $icon-font !important;
        color: $black;
        font-style: initial;
        border: 2px solid $black;
        border-radius: 50%;
        font-size: 15px;
        width: 23px;
        height: 23px;
        @include flexbox;
        align-items: center;
        justify-content: center;
    }
}

// common custom dropdown for both platform(web/mobile)
.si-commonselectbox {
    min-width: 150px;
    position: relative;
    background: rgba($black, 0.4);
    border: 1px solid rgba($white, .1);
    @include border-radius(5px);

    &.active {
        i {
            transform: rotate(180deg);
            transform-origin: 45% 45%;
        }
    }

    .si-selected {
        @include flexbox;
        justify-content: space-between;
        position: relative;
        text-transform: capitalize;
        cursor: pointer;
        height: 30px;
        align-items: center;
        color: $white;
        padding: 0px 10px;

        span {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
        }

        i {
            position: absolute;
            right: 5px;
            width: 15px;
            height: 15px;
            font-size: 15px;
            margin: auto;
            top: 0;
            bottom: 0;
            color: $white;

            &::after {
                content: "\e905";
                font-family: $icon-font !important;
                font-style: initial;
                font-size: 13px;
                right: 0px;
            }

            @media screen and (max-width: $media-tablet-max) {
                font-size: 13px;
            }
        }
    }

    .si-options {
        display: none;
        position: absolute;
        top: 28px;
        width: 100%;
        z-index: 20;
        padding-top: 2px;
        -webkit-scrolling-overflow: auto;
        -webkit-box-shadow: 0 4px 11px rgba(black, 0.4);
        -moz-box-shadow: 0 4px 11px rgba(black, 0.4);
        box-shadow: 0 4px 11px rgba(black, 0.4);

        ul {
            color: $black;
            text-transform: capitalize;
            height: 200px;
            overflow: auto;
            background: $white;
            @include border-radius(0px 0px 5px 5px);

            li {
                cursor: pointer;
                padding: 10px;
                position: relative;
                color: #4a4747;
                background-color: $white;

                &:hover {
                    background-color: $client-primary-color;
                    color: $white;

                }

                &:not(:last-child) {
                    border-bottom: 1px solid rgba($black, 0.2);

                    &:hover {
                        border-bottom-color: rgba($white, .2);
                    }
                }

                span {
                    display: block;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 10px;
                        right: 10px;
                        bottom: 0;
                        height: 1px;
                    }
                }



                &:last-child {
                    span:after {
                        display: none;
                    }
                }
            }
        }
    }

    &.active {
        .si-options {
            display: block;
        }
    }
}


//Tooltip box for both platform (web/mobile)
.si-tooltip {

    &::after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 50%;
        @include transform(translateX(50%) rotate(45deg));
        visibility: hidden;
        opacity: 0;

    }

    &:hover {
        &::after {
            visibility: visible;
            opacity: 1;
        }

        .si-tooltipBox {
            visibility: visible;
            opacity: 1;
        }
    }

    .si-tooltipBox {
        position: absolute;
        justify-content: center;
        align-items: center;
        padding: 20px;
        color: $white;
        text-transform: initial;
        width: 420px;
        top: -117px;
        height: 100px;
        right: 0;
        z-index: 11;
        letter-spacing: 0;
        font-size: 16px;
        line-height: 20px;
        visibility: hidden;
        opacity: 0;

        .si-header {
            text-align: left;
            font-weight: $bold_font-weight;
            font-size: 16px;
        }

        .si-body {
            text-align: left;
            font-size: 16px;
            margin-top: 2px;
        }
    }
}

//Disabled CSS
.si-disable {
    cursor: default !important;
    pointer-events: none;
    filter: grayscale(90%);
    -webkit-filter: grayscale(90%);
    -moz-filter: grayscale(90%);
    -ms-filter: grayscale(90%);
    -o-filter: grayscale(90%);
    background: #afb6bc !important;
    border-color: #afb6bc;
    box-shadow: none;
    color: $white;
}


// Loader css
.si-loader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 9999;
    @include flexbox;
    display: none;

    .si-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 70px;
        height: 70px;
        z-index: 1;
        animation: rotatePreloader 2s infinite ease-in;
        @include transform(translate(-50%, -50%));

        div {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 0%;
                width: 10%;
                height: 10%;
                background-color: $client-primary-color;
                @include border-radius(50%);
                @include transform(translateX(-50%));
            }
        }
    }
}

@include keyframes(loading) {
    0% {
        @include transform(rotate(0deg));
    }

    100% {
        @include transform(rotate(360deg));
    }
}

@keyframes rotatePreloader {
    0% {
        transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
    }

    100% {
        transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
    }
}

.si-loader div:nth-child(1) {
    transform: rotateZ(0deg);
    animation: rotateCircle1 2s infinite linear;
    z-index: 9;
}

@keyframes rotateCircle1 {
    0% {
        opacity: 0;
    }

    0% {
        opacity: 1;
        transform: rotateZ(36deg);
    }

    7% {
        transform: rotateZ(0deg);
    }

    57% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(2) {
    transform: rotateZ(36deg);
    animation: rotateCircle2 2s infinite linear;
    z-index: 8;
}

@keyframes rotateCircle2 {
    5% {
        opacity: 0;
    }

    5.0001% {
        opacity: 1;
        transform: rotateZ(0deg);
    }

    12% {
        transform: rotateZ(-36deg);
    }

    62% {
        transform: rotateZ(-36deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(3) {
    transform: rotateZ(72deg);
    animation: rotateCircle3 2s infinite linear;
    z-index: 7;
}

@keyframes rotateCircle3 {
    10% {
        opacity: 0;
    }

    10.0002% {
        opacity: 1;
        transform: rotateZ(-36deg);
    }

    17% {
        transform: rotateZ(-72deg);
    }

    67% {
        transform: rotateZ(-72deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(4) {
    transform: rotateZ(108deg);
    animation: rotateCircle4 2s infinite linear;
    z-index: 6;
}

@keyframes rotateCircle4 {
    15% {
        opacity: 0;
    }

    15.0003% {
        opacity: 1;
        transform: rotateZ(-72deg);
    }

    22% {
        transform: rotateZ(-108deg);
    }

    72% {
        transform: rotateZ(-108deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(5) {
    transform: rotateZ(144deg);
    animation: rotateCircle5 2s infinite linear;
    z-index: 5;
}

@keyframes rotateCircle5 {
    20% {
        opacity: 0;
    }

    20.0004% {
        opacity: 1;
        transform: rotateZ(-108deg);
    }

    27% {
        transform: rotateZ(-144deg);
    }

    77% {
        transform: rotateZ(-144deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(6) {
    transform: rotateZ(180deg);
    animation: rotateCircle6 2s infinite linear;
    z-index: 4;
}

@keyframes rotateCircle6 {
    25% {
        opacity: 0;
    }

    25.0005% {
        opacity: 1;
        transform: rotateZ(-144deg);
    }

    32% {
        transform: rotateZ(-180deg);
    }

    82% {
        transform: rotateZ(-180deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(7) {
    transform: rotateZ(216deg);
    animation: rotateCircle7 2s infinite linear;
    z-index: 3;
}

@keyframes rotateCircle7 {
    30% {
        opacity: 0;
    }

    30.0006% {
        opacity: 1;
        transform: rotateZ(-180deg);
    }

    37% {
        transform: rotateZ(-216deg);
    }

    87% {
        transform: rotateZ(-216deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(8) {
    transform: rotateZ(252deg);
    animation: rotateCircle8 2s infinite linear;
    z-index: 2;
}

@keyframes rotateCircle8 {
    35% {
        opacity: 0;
    }

    35.0007% {
        opacity: 1;
        transform: rotateZ(-216deg);
    }

    42% {
        transform: rotateZ(-252deg);
    }

    92% {
        transform: rotateZ(-252deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(9) {
    transform: rotateZ(288deg);
    animation: rotateCircle9 2s infinite linear;
    z-index: 1;
}

@keyframes rotateCircle9 {
    40% {
        opacity: 0;
    }

    40.0008% {
        opacity: 1;
        transform: rotateZ(-252deg);
    }

    47% {
        transform: rotateZ(-288deg);
    }

    97% {
        transform: rotateZ(-288deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}

.si-loader div:nth-child(10) {
    transform: rotateZ(324deg);
    animation: rotateCircle10 2s infinite linear;
    z-index: 0;
}

@keyframes rotateCircle10 {
    45% {
        opacity: 0;
    }

    45.0009% {
        opacity: 1;
        transform: rotateZ(-288deg);
    }

    52% {
        transform: rotateZ(-324deg);
    }

    102% {
        transform: rotateZ(-324deg);
    }

    100% {
        transform: rotateZ(-324deg);
        opacity: 1;
    }
}


[type="search"] {

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}


//Animate Fade
.animateFade {

    display: none;

    &.active {
        display: block;
        -webkit-animation: fadeInUp 1s;
        -moz-animation: fadeInUp 1s;
        -ms-animation: fadeInUp 1s;
        -o-animation: fadeInUp 1s;
        animation: fadeInUp 1s;

    }
}



//ie support
_:-ms-fullscreen,
:root {}

_:-ms-fullscreen,
:root .si-popup .si-chalng.si-popup-modal .si-popup-modal-bg {
    align-items: stretch;
}

.d-none {
    display: none !important;
}

.si-no-data {
    color: $white;
    font-weight: 400;
    font-size: 16px;
    min-height: 200px;
    @include flexbox();
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-align: center;
}

[class^="icon-"],
[class*=" icon-"] {
    font-size: 14px;
}

.Toastify__toast-container{
     z-index:10001!important;
    }
 .form-control{
    position: relative;
 }   
 
  