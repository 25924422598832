.details-reportlog-main {
    display: flex;
    gap: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: calc(100dvh - 80px);
    @media screen and (max-width: $media-tablet-max) {
        gap:15px;
        height: calc(100dvh - 130px);
    }
    .icon-download{
        font-size: 18px;
    }
    @media screen and (max-width: $media-tablet-max) {
        flex-direction: column;
        padding-top: 20px;
    }
    .injury-athelet-profile {
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        .injury-athelet-profile-img {
            height: 35px;
            width: 35px;
            background-color: $neutral-color-5;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
                width: 18px;
            }
        }
        &-name {
            font-size: 18px;
            font-weight: 600;
            color: #282828;
            text-transform: capitalize;
        }
    }
    .si-subheader {
        font-weight: 700;
        font-size: 14px;
        color: $secondary-color-2;
   
        background: #e7e0ec;
        border-radius: 5px;
        padding: 7px 15px;
        position: sticky;
        z-index: 1;
        top: 0;
        @media screen and (max-width: $media-tablet-max) {
            color: $neutral-color-2;
        }
        .icon-chev-right {
            display: none;
        }
        @media screen and (max-width: $media-tablet-max) {
            padding: 12px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                display: inline-block;
                 word-wrap: break-word;
            }
            .icon-chev-right {
                display: block;
                transform: rotate(0deg);
                transition: transform 0.3s ease-in-out;
            }
        }
    }
    &__left {
        width: 38%;
        position: relative;
        background: $white;
            box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.05);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        @media screen and (max-width: $media-tablet-max) {
            width: unset;
            margin: 0px -10px;
        }
        .atheletes-details {
            background: $white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            flex-direction: column;
            &-content {
                &__footer {
                    display: flex;
                    justify-content: end;
                    position: absolute;
                    bottom: 10px;
                    right: 15px;
                    width: 100%;
                    @media screen and (max-width: $media-tablet-max) {
                        bottom: -11px;
                        right: 0px;
                        width: 100%;
                        padding: 15px;
                    }

                    .si-button {
                        padding: 9px 9px;
                        text-transform: uppercase;
                        font-family: "poppins";
                        font-size: 14px;
                        @media screen and (max-width: $media-tablet-max) {
                            max-width: unset;
                            display: flex;
                        }
                   
                    }

                }
            }
            &__form-content {
                margin: 13px 15px 0px 15px;
                border-bottom: 1px solid $neutral-color-4;
                padding-bottom: 20px;
                margin-bottom: 15px;
                .si-input {
                    background-color: $neutral-color-5;
                }
            }
        }
        .shoulder-dislocation {
            @media screen and (min-width:1024px){
                overflow: scroll;
                height: calc(100dvh - 195px);
                &::-webkit-scrollbar {
                    width: 3px;
                    z-index: 999;
                    
                    @media screen and (max-width:$media-tablet-max) {
                        width: 0px;
                    }
                }
                
                &::-webkit-scrollbar-track {
                    z-index: 999;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgba(#242424, 0.2);
                    border-radius: 10px;
                    z-index: 999;
                }
            }
            .si-subheader {
                background: #f1f1f1;
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                padding: 8px 10px 7px 10px;
                margin-left: 15px;
                margin-right: 15px;
                align-items: baseline;
                color: $neutral-color-2;
                text-transform: capitalize;
                .icon-edit {
                    color: $neutral-color-2;
                    cursor: pointer;
                }
            }
            &__content {
                padding: 13px 15px 20px 15px;
                margin-bottom: 20px;
                height: calc(100vh - 300px);
                overflow-y: scroll;
                @media screen and (min-width: 993px) and (max-width:1199px) {
                    height: calc(100dvh - 310px);
                  }
                @media screen and (max-width:$media-tablet-max){
                   height: calc(100dvh - 415px); 
                }
                .content-row {
                    margin-bottom: 8px;
                    line-height: 18px!important;
                    word-wrap: break-word;
                    .si-title {
                        font-size: 12px;
                        font-weight: 500px;
                        color: $secondary-color-1;
                        text-transform: capitalize;
                        line-height: unset;
                        margin-right: 3px;
                        @media screen and (max-width:$media-tablet-max){
                            font-size: 14px;
                        }
                    }
                    .text {
                        font-size: 12px;
                        font-weight: 400;
                        color: $neutral-color-2;
                        @media screen and (max-width:$media-tablet-max){
                            font-size: 14px;
                        }
                    }
                }
                .attachment-row {
                    display: flex;
                    align-items: center;
                    line-height: 18px;
                    width: 100%;
                    overflow-wrap: anywhere;
                    .pdf-img-wrp {
                        height: 18px;
                        width: 18px;
                        display: inline-block;
                        margin-right: 7px;
                        img {
                            height: 100%;
                        }
                    }
                    .uploaded-file {
                        margin-top: 7px;
                        display: flex;
                        .file-name {
                            font-size: 12px;
                            font-weight: 400;
                            color: $neutral-color-2;
                            margin-right: 10px;
                            @media screen and (max-width:$media-tablet-max){
                                font-size: 14px;
                            }
                        }
                    }
                }
                &::-webkit-scrollbar {
                    width: 2px;
                    height: 2px;
                    z-index: 999;
                    @media screen and (max-width:$media-tablet-max){
                        width: 0px;
                          }
                }
                &::-webkit-scrollbar-thumb {
                    background: rgba(#242424, 0.2);
                    border-radius: 10px;
                    z-index: 999;
                  }
            }
        }
        .si-button[disabled]{
            background-color: #969696;
            border-color: #969696;
            color: #c8c8c8;
            padding: 5px 10px 5px 8px;
        }
    }
    &__right {
        width: calc(100% - 38%);
        position: relative;
        background: $white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);

        .report-details {
            background: #ffffff;
            display: flex;
            flex-direction: column;
            height: 85.4%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            @media screen and (max-width:$media-tablet-max){
                height: unset;
            }
            .reportlog-content-wrp{
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 2px;
                    z-index: 999;
                    @media screen and (max-width:$media-tablet-max){
                        width: 0px;
                    }
                }
                &::-webkit-scrollbar-thumb {
                    background: rgba(#242424, 0.2);
                    border-radius: 10px;
                    z-index: 999;
                }
            }
            .reportlog-content {
                padding: 13px 15px 0px 15px;
                height: 100%;
                .comment-container-wrp{
                    padding-bottom: 50px;
                    @media screen and (max-width: $media-tablet-max) {
                        padding-bottom: unset;
                    }

                }
            }
        }
    
        @media screen and (max-width: $media-tablet-max) {
            width: unset;
            margin: 0px -10px;
        }
    }
}

.atheletes-details-content{
    @media screen and (max-width: $media-tablet-max) {
        height: 0;
        overflow: hidden;
    }

}
.reportlog-content-wrp{
    @media screen and (max-width:$media-tablet-max){
            height: 0;
            overflow:hidden;
        }
    
}


.atheletes-details-content,
.reportlog-content-wrp {
    .shoulder-dislocation{
        .si-subheader{
            span{
                &:first-child{
                    font-weight: 600!important;
                    width: 93%;
                    word-wrap: break-word;

                }
            }
        }
    }

}
.details-active,
.report-active {
    .si-subheader__text{
        color: #323232;
    }
    .icon-chev-right {
        transform: rotate(90deg) !important;
        transition: transform 0.3s ease-in-out;
    }
}

.details-active + .atheletes-details-content{
    @media screen and (max-width: $media-tablet-max) {
        height: calc(100dvh - 245px);
        overflow: scroll;
        .atheletes-details-content__footer{
            bottom: 2px;
        }
    }
    &::-webkit-scrollbar {
        width: 3px;
        z-index: 999;
        
        @media screen and (max-width:$media-tablet-max) {
            width: 0px;
        }
    }
    
    &::-webkit-scrollbar-track {
        z-index: 999;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }
}
.report-active + .reportlog-content-wrp {
    height: unset!important;
    .send-wrp{
        @media screen and (max-width: $media-tablet-max) {
            left: unset;
        }
    }
    @media screen and (max-width:$media-tablet-max){
        .reportlog-content{
            height: calc(100dvh - 322px);
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 2px;
                z-index: 999;
                @media screen and (max-width:$media-tablet-max){
                    width: 0px;
                }
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(#242424, 0.2);
                border-radius: 10px;
                z-index: 999;
            }
        }
    }
}



// =====comment-section-report-log========
.reportlog-content-wrp {
    .comment-container {
        margin-bottom: 20px;
        position: relative;
    }
    .comment-date {
        font-size: 10px;
        font-weight: 500;
        color: $neutral-color-2;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: -10px;
        border: 1px solid $neutral-color-5;
        background: $white;
        border-radius: 50px;
        padding: 0 15px;
        white-space: nowrap;
        @media screen and (max-width:$media-tablet-max){
            font-size: 12px;
        }
    }
    .marked-recover{
        background: #FFDAD6!important;
        white-space: nowrap;
    }
    .comment-wrap {
        display: flex;
        margin-top: 17px;
        .name-time-wrp {
            display: flex;
            align-items: baseline;
            .atheletes-name{
                font-size: 12px;
                font-weight: 500;
                color: $secondary-color-1;
                text-transform: capitalize;
                @media screen and (max-width:$media-tablet-max) {
                    font-size: 14px;
                }
            }
            .comment-time{
                font-size: 10px;
                font-weight: 500;
                color: $neutral-color-1;
            }
        }
        .athlete-image {
            margin-right: 15px;
            height: 35px;
            min-width: 35px;
            background-color: #eaeaea;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
               width: 18px;
            }
        }
        .atheletes-name {
            margin-right: 13px;
            margin-bottom: 5px;
            font-size: 12px;
           
        }
        .comment-action-wrp {
            display: flex;
            align-items: baseline;
            .comment{
               font-size: 12px;
               color: $secondary-color-1;
               background-color: $neutral-color-6;
               padding: 6px 10px;
               border-radius: 6px;
               border-top-left-radius: 0px;
               word-break: break-word;
            }
            .si-custom-table__cell{
               padding: unset;
               width: unset;
               .icon-options{
                margin-left: 0;
                color: #6E6E6E;
               }
               .si-action-icons{
                right: 22px!important;
                width: unset;
               }
            }
        }
        .icon-options {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
.icon-msg-send {
    cursor: pointer;
    position: relative;
    right: 42px;
    bottom: 1px;
    font-size: 21px!important;
    &::before{
        font-size: 21px;
        
    }
    @media screen and (max-width:$media-tablet-max){
        right: 37px;
    }
    
}

.send-wrp {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 15px;
    width: 97.5%;
    border-radius: 6px;
    left: 20px;
     
    @media screen and (max-width: $media-tablet-max) {
     position: unset;
     margin-top: 15px;
     margin-left: 16px;
     margin-bottom: 20px;
     width: 98%;
     
    }
    input{
        width: 100%;
        border: none;
        background: #F4F2FD;
        padding: 15px;
        border-radius: 6px;
        padding-right: 40px;
        font-size: 12px;
         font-family: 'Poppins';
         font-weight: 500;
         color: #6E6E6E;
         @media screen and (max-width: $media-tablet-max) {
            padding-left: 10px;
            opacity: 1;
         }
         &::placeholder{
            color: #6E6E6E;
            opacity: 1;
         }
        &:focus{
            border: 1px solid #AEA9C5;
            color: #6E6E6E;
        }
    }
    input:focus::placeholder {
        color: transparent;
      }
    .icon-msg-send:before {
        content: "\e960";
        color: #FF5046;;
    }
}
.gray-icon{
    .icon-msg-send:before {
        content: "\e960";
        color: #D9D9D9!important;
    }
}

.comment-date-wrp{
    border-bottom: .3px solid #FFCBC7;
}
.load-btn-wrp{
    display: flex;
    justify-content: center;
    position: sticky;
    top: 15px;
    z-index: 999;
    margin-bottom: 18px;
   
    .load-btn{
        border-radius: 50px;
        padding: 5px 10px;
        border: .3px solid rgb(228, 225, 225);
        display: flex;
        align-items: center;
        cursor: pointer;
        background: white;
        font-size: 10px;
        color: #282828;
        .icon-chev-down{
            font-size: 8px;
            margin-left: 4px;
        }
    }
}