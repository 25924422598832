.homepage{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background: $white;
    height: 98%;
    @media screen and (max-width:$media-tablet-max){
        height: 100%;
    }
    // height: calc(100dvh - 100px);
    // @media screen and (max-width:$media-tablet-max){
    //     height: calc(100dvh - 150px);
    // }
    
    .homepage-content{
        width: 70%;
        text-align: center;
        @media screen and (max-width:768px){
            width: 100%;
        }
        .title{
            color: #326EBE;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 15px;
            @media screen and (max-width:768px){
                font-size: 18px;
            }
        }
        .para{
            color:$black;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 15px;
            line-height: 26px;
            @media screen and (max-width:768px){
                font-size: 12px;
                line-height: 18px;
            }
        }
        @media screen and (max-width:1100px){
            .img-wrp{
                width: 100%;
                img{
                    width: 100%;
                }
            }
        }
    }
}