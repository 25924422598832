.MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none !important;
}
.si-custom-table {
    input[type="checkbox"] {
        margin-left: -18px;
    }

    .MuiTableCell-head {
        background-color: #f5eefa;
    }

    .MuiTableHead-root {
        z-index: 2;
        position: relative;
        .MuiTableCell-root {
            border-bottom: none !important;
        }
        @media screen and (max-width: $media-tablet-max) {
            background-color: transparent;
        }
        .MuiTableCell-root {
            color: $secondary-color-1;
            z-index: 0;
            font-family: $semibold_font-family;
            font-weight: $semibold_font-weight;
            @supports (-webkit-touch-callout: none) {
                z-index: 2;
            }
            @media screen and (max-width: $media-tablet-max) {
                &.stickyCell-left {
                    position: sticky;
                    left: 0;
                    z-index: 99;
                    @supports (-webkit-touch-callout: none) {
                        z-index: 8;
                    }
                }

                &.stickyCell-right {
                    position: sticky;
                    left: initial;
                    right: 0;
                    z-index: 99;
                    @supports (-webkit-touch-callout: none) {
                        z-index: 8;
                    }
                }
            }
            @supports (-webkit-touch-callout: none) {
                &.stickyCell-right {
                    z-index: 8;
                }
            }

            .MuiButtonBase-root {
                &:hover,
                &.Mui-active {
                    color: $primary-color-1;
                }
            }
        }
    }

    .MuiTableBody-root {
        background-color: $white;

        .MuiTableCell-root {
            color: $secondary-color-1;
            border-bottom: 1px solid #f1f1f1;
            padding: 0px 10px 0px 10px;
            height: 40px;
            position: relative;
            font-weight: 400;
            font-family: "Poppins";
            .si-icon__media {
                margin: auto;
            }

            .si-count {
                padding: 2px 6px;
                white-space: nowrap;
            }
        }

        .MuiTableRow-root {
            &:hover {
                @media screen and (min-width: 993px) {
                    background-color: $neutral-color-7;
                    .si-icon__button {
                        background-color: unset;
                    }
                }
            }
            .MuiTableCell-root {
                @media screen and (max-width: $media-tablet-max) {
                    &.stickyCell-left {
                        position: sticky;
                        left: 0;
                        background: $white;
                        z-index: 1;
                    }

                    &.stickyCell-right {
                        position: sticky;
                        left: initial;
                        background: $white;
                        z-index: 1;
                        right: 0;
                    }
                }
            }

            &.Mui-selected {
                background-color: rgba($primary-color-5, 0.3) !important;

                .MuiCheckbox-root {
                    .MuiSvgIcon-root {
                        fill: $secondary-color-1;
                    }
                }

                .MuiRadio-root {
                    color: $black;
                }

                .MuiTableCell-root {
                    color: $black;
                    border-bottom: 1px solid #f5eefa;
                }
            }

            &.MuiTableRow-hover {
                @media screen and (min-width:992px){
                    &:hover {
                        background: #f9f9f9;
                        .MuiCheckbox-root {
                            .MuiSvgIcon-root {
                                fill: $black;
                            }
                        }
                        
                        .MuiTableCell-root {
                            color: $black;
                        }
                    }
                    
                    &:hover {
                        .MuiRadio-root {
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    .MuiCheckbox-root {
        .MuiSvgIcon-root {
            fill: $neutral-color-2;
        }
    }

    .MuiRadio-root {
        &.Mui-checked {
            color: $secondary-color-1;
        }
    }
}

.si-tbl-pagination {
    @include flexbox();

    &.MuiTablePagination-root {
        color: $secondary-color-1;
    }

    .MuiTablePagination-actions {
        color: $white;
        .MuiButtonBase-root {
            &.MuiIconButton-root {
                background-color: $primary-color-1;
                border: 1px solid $primary-color-1;
                padding: 4px;

                &.Mui-disabled {
                    color: $neutral-color-1;
                    border-color: $neutral-color-1;
                    background-color: transparent;
                    width: 30px;
                    height: 30px;
                }
            }
        }

        button {
            & + button {
                margin-left: 10px;
            }
        }
    }

    .MuiInputBase-root,
    .MuiTablePagination-selectLabel {
        display: none;
    }
}

@media screen and (max-width: $media-tablet-max) {
    .si-custom-table .MuiTableHead-root {
        background-color: transparent;
        position: relative;
        z-index: 2;
    }
}
@media screen and (max-width: $media-tablet-max) {
    .si-custom-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root.stickyCell-right {
        position: sticky;
        left: initial;
        z-index: 1;
        right: 0;
    }
    .si-custom-table-container .si-icon__button {
        height: auto;
        background: #ffffff;
        position: relative;
        z-index: 2;
        @media screen and (max-width: $media-tablet-max) {
            z-index: 1;
            padding: 0 15px 0 13px;
        }
    }
    .si-wrap .si-action-icons {
        top: initial;
        // right: 50px;
        z-index: 3;
        top: -4px;
        // right: 85px;
    }
}
@media only screen and (max-width: 1200px) {
    .si-custom-table-container .si-custom-table__cell:last-child {
        width: 100%;
        padding: 0;
    }
}
.css-1nmdiq5-menu {
    margin-top: 5px !important;
}
.nodata-tableRow{
    &:hover{
        background-color: $white!important;
    }
}
// @media screen and (max-width:580px){
//     .si-wrap .si-action-icons {
//         right: 55px;
//     }
// }
