@import "../mixins";
@import "../variables";
@import "../variables-en";

.si-card {
  &__list {
    flex: 0 0 14.28%;
    width: 14.28%;
    padding-top: 10px;
    position: relative;
    @media screen and (max-width: $media-tablet-max) {
      padding-top: 0;
    }
    &:last-of-type {
      .si-card__container {
        .si-Right {
          .si-action {
            position: absolute;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    &.activeday {
      &::before {
        content: "";
        position: absolute;
        box-shadow: 0px 0px 10px rgba($black, 0.5);
        top: 0;
        left: 0;
        height: 100vw;
        width: 100%;
        display: none;
      }

      .si-column {
        & + .si-column {
          &::before {
            content: "";

            display: none;
          }
        }
      }
    }
    @media screen and (max-width: $media-tablet-max) {
      display: none;
    }

    &.active {
      @media screen and (max-width: $media-tablet-max) {
        display: block;
        flex: 0 0 100%;
        width: 100%;
        box-shadow: none;
      }
    }
  }

  &__box {
    min-height: 59px;
    border-left: 1px solid rgba(#fff, 0.05);
    padding: 5px 7px;
    z-index: 1;
    position: relative;
    @media screen and (max-width: $media-desktop-max-1024) {
      padding: 5px 6px;
    }

    &.si-card-dragging {
      .si-card__container {
        border-color: $neutral-color-4;
      }
    }

    &.si-assigned {
      .si-card__container {
        .si-tooltipBox {
          &::before {
            background-color: $client-primary-color;
          }

          .si-matchName {
            span {
              color: $client-primary-color;
            }
          }
        }
      }
    }

    &.si-unassigned {
      .si-card__container {
        background-color: rgba($client-secondary-color, 0.3);

        .si-tooltipBox {
          &::before {
            background-color: $client-secondary-color;
          }

          .si-matchName {
            span {
              color: $client-secondary-color;
            }
          }
        }
      }
    }
  }

  &__container {
    cursor: pointer;
    padding: 5px;
    @include border-radius(5px);
    @extend %flexbox;
    flex-flow: row;
    min-height: 62px;
    position: relative;
    @include flexbox();
    background-color: $white;
    border: 1px solid $neutral-color-5;
    box-shadow: 0px 2px 3px rgba($black, 0.1);
    @media screen and (max-width: $media-tablet-max) {
      background-color: $white;
      min-height: 100%;
      padding: 15px;

      padding-top: 14px;
      padding-right: 5px;
    }

    &:hover {
      .si-toolTip {
        opacity: 1;
        visibility: visible;
      }
    }

    .si-toolTip {
      font-family: $bold_font-family;
      font-weight: $bold_font-weight;
      @include border-radius(5px);
      opacity: 0;
      visibility: hidden;
      @include transition(all, 0.2s, ease-in-out);

      .si-tooltipBox {
        background: #8f9094;
        color: $white;
        z-index: 9999;
        top: 23px;
        line-height: 16px;
        position: absolute;
        padding: 5px 10px;
        display: block;
        display: block;
        white-space: normal;
        width: 150px;
        word-wrap: break-word;
        font-size: 10px;
        @include border-radius(5px);

        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          @include transform(rotate(45deg));
          background: #8f9094;
          left: 4px;
          top: -4px;
          z-index: -1;
        }

        & span {
          color: $white !important;
        }
      }
    }

    span {
      font-size: 12px;
      font-family: $medium_font-family;
      font-weight: $semibold_font-weight;
      line-height: 1.4;

      @media screen and (max-width: $media-desktop-max-1024) {
        font-size: 10px;
      }

      @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
        font-weight: 500;
        // margin-right: 12px;
      }
    }

    .si-Left {
      width: 90%;
      flex: 0 0 90%;
      line-height: 18px;
      @media screen and (max-width: $media-desktop-max-1024) {
        line-height: 14px !important;
      }
      @media screen and (max-width: $media-tablet-max) {
        width: 100%;
        flex: 1;
      }
    }

    .si-Right {
      @media screen and (max-width: $media-tablet-max) {
        width: 30px;
        height: 30px;
        background: rgba(200, 200, 200, 0.2);
        border-radius: 32px;
        margin: auto;
        right: 11px !important;
        top: 50%;
        transform: translateY(-50%);
        .icon-options {
          width: 100% !important;
          height: 100%;
          position: relative;
          &:before {
            right: 0 !important;
            font-size: 14px;
            left: 0;
          }
        }
      }

      .icon-options {
        width: 12px;

        &:before {
          position: absolute;
          right: 9px;

          @media screen and (max-width: $media-desktop-max-1024) {
            right: 6px;
          }
          @media screen and (max-width: $media-tablet-max) {
            font-size: 11px;
          }
          @media screen and (max-width: $media-tablet-max) {
            font-size: 14px;
          }
        }
      }

      &.active {
        .si-action {
          visibility: visible;
          opacity: 1;
          @include transform(scale(0.9));
          @include transition(all, 0.2s, ease-in-out);
        }
      }

      .si-cardMenu {
        position: relative;

        &:after {
          content: "";
          background-image: url("../images/card-menu.svg");
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          right: 3px;
          width: 2px;
          height: 10px;
          top: 8px;

          @media screen and (max-width: $media-desktop-max-1024) {
            right: -1px;
          }
        }
      }

      .si-action {
        width: 99px;
        background: $action-div-bg;
        border-radius: 5px;
        z-index: 999999;
        position: absolute;
        visibility: hidden;
        top: 0;
        right: 15px;
        @include transform(scale(0.9));
        @include transition(all, 0.2s, ease-in-out);
        @media screen and (min-width: 993px) and (max-width: 1100px) {
          right: 5px;
        }
        @media screen and (min-width: 1100px) and (max-width: 1200px) {
          right: 10px;
        }

        @media screen and (max-width: $media-tablet-max) {
          width: 119px;
          top: -7px;
          right: 30px;
        }

        .si-icon-button {
          @include flexbox();
          align-items: center;
          background-color: transparent;
          border: none;
          padding: 6px;
          width: 100%;
          color: $white;
          text-transform: capitalize;
          font-size: 12px;

          @media screen and (max-width: $media-tablet-max) {
            padding: 8px;
            font-size: 14px;
            margin-right: 13px;
          }

          &:hover {
            background-color: $action-hover;
            cursor: pointer;
            border-radius: 6px;
          }

          span {
            margin-right: 6px;
          }
        }
      }
    }

    .si-exeriseName {
      margin-bottom: 7px;
      width: 100%;
      color: $secondary-color-1;
      padding-right: 13px;
      word-wrap: break-word;
    }

    .si-exerise,
    .si-Controlled {
      color: $neutral-color-2;
      @include flexbox();
      flex-flow: column;
      .text-wrap {
        @include flexbox();
        align-items: center;
        justify-content: flex-start;
        flex-flow: wrap;
      }

      & span {
        font-family: $medium_font-family;
        font-weight: 400;
        font-size: 12px;
        text-transform: capitalize;
        margin-bottom: 4px;

        &:nth-of-type(1) {
          padding-left: 0px;
          padding-right: 5px;
        }
        & + span {
          padding-left: 4px;
          padding-right: 4px;
          position: relative;
          &:before {
            content: "";

            border-left: 1px solid $dark-yellow;
            border-left: 1px solid #6e6e6e;
            position: absolute;
            left: 0px;
            right: 0;
            top: 1px;
            height: 13px;
          }
        }

        @media screen and (max-width: $media-desktop-max-1024) {
          font-size: 10px;
        }

        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }
      }
    }
    .si-exerise {
      width: 100%;
    }
  }
}

select {
  cursor: pointer;
}

@media screen and (max-width: $media-tablet-max) {
  .si-card__box {
    padding: 5px 0px;
  }
}

.feedback-container {
  display: block !important;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  .si-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      display: inline-block;
      font-family: "Poppins", sans-serif;
    }
    &-icon {
      height: 10px;
      display: inline-block;
      margin-bottom: 5px;
      @media screen and (max-width: $media-tablet-max) {
        margin-bottom: 10px;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.si-feedback-remark {
  background: linear-gradient(268.08deg, #fcbd4e 2.02%, #ffdea3 93.88%);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 0px 5px 5px;
  text-align: center;
  line-height: 16px;
  padding: 3px 8px;
  position: absolute;
  left: 0;
  width: 88%;
  &__num {
    margin-right: 2px;
    display: inline-block;
  }
  &__text {
    display: inline-block;
    margin-left: 2px;
  }
}
