.rmsc {
    &.custom-multi-select {
        --rmsc-main: #4285f4;
        --rmsc-hover: rgba($primary-color-5, 0.3);
        --rmsc-selected: #e2e6ea;
        --rmsc-border: #ccc;
        --rmsc-gray: #aaa;
        --rmsc-bg: $white !important;
        --rmsc-p: 10px;
        --rmsc-radius: 4px;
        --rmsc-h: 38px;
    }

    .dropdown-container {
        background-color: var(--rmsc-bg);

        .panel-content {
            background: var(--rmsc-bg);
        }
    }
}

//  For Single Select - Styling has been handled by classPrefixName
.custom-select {
    &__control {
        box-shadow: none !important;
        &:focus,
        &:focus-within {
            border-color: $secondary-color-1 !important;
            box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5) !important;
        }
        &:hover {
            @media screen and (min-width: 769px) {
                border-color: $secondary-color-1 !important;
            }
        }
    }

    &__indicator-separator {
        background-color: transparent !important;
    }

    &__indicator {
        color: $secondary-color-1 !important;

        svg {
            width: 20px;
        }

        &:hover {
            color: $secondary-color-1;
        }
    }

    &__placeholder {
        color: #c8c8c8;
    }

    &__menu {
        background-color: $white !important;
        margin-top: 8 !important;
        z-index: 9990 !important;

        &-list {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            &::-webkit-scrollbar {
                width: 4px;
                z-index: 999;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $secondary-color-5;
                border-radius: 5px;
                z-index: 999;
            }
        }
    }

    &__option {
        color: $secondary-color-1 !important;

        & + .custom-select__option {
            border-top: 1px solid #f1f1f1;
        }

        &--is-focused {
            background-color: #f9f9f9 !important;
            color: #0d0d0d !important;
            @media screen and (max-width:1024px){
            background-color: unset!important;
            }
        }

        &--is-selected {
            background-color: rgba($primary-color-5, 0.3) !important;
            color: #0d0d0d !important;
        }
    }

    &__single-value {
        color: $secondary-color-1 !important;
    }

    &--is-disabled {
        .custom-select__indicators {
            svg {
                color: #c8c8c8 !important;
            }
        }
    }
}
.css-10wo9uf-option:active{
    background-color: unset!important;
}

.custom-single-select {
    font-size: 12px;
    @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
    }
}
.custom-select__control--is-disabled {
    .custom-select__single-value--is-disabled {
        color: $neutral-color-2 !important;
    }
}

.custom-multi-select,
.custom-single-select {
    &.rmsc {
        .dropdown-container {
            background-color: $white;
            color: $secondary-color-2;
        }

        .dropdown-heading {
            height: 28px !important;
            color: $white !important;
            border-radius: 5px !important;
            @media screen and (max-width: $media-tablet-max) {
                height: 30px !important;
            }
            &-value {
                color: $secondary-color-1 !important;
                font-size: 12px;
                @media screen and (max-width: $media-tablet-max) {
                    font-size: 14px;
                }
            }
        }

        .dropdown-content {
            z-index: 9;
        }

        .select-item {
            color: $secondary-color-1;
            border-bottom: 1px solid #f1f1f1;
            font-size: 12px;
            padding: 6px 10px !important;
            font-weight: 400;

            &.selected,
            &:hover {
                color: $secondary-color-1;

                input[type="checkbox"] {
                    border: 2px solid $black;

                    &::before {
                        border-color: $black;
                    }
                }
            }
            &.selected {
                background-color: rgba($primary-color-5, 0.3) !important;
            }
            &:hover {
                background-color: #f9f9f9;
            }
        }
        @media screen and (max-width:1100px){
            .select-item:hover{
                background:none;
            }
        }

        .search input {
            color: $secondary-color-1;
            background-color: $white;
            height: 30px;
            font-size: 13px;

            &:focus {
                background-color: $white !important;
                caret-color: black;
            }
        }

        .dropdown-container:focus-within {
            box-shadow: none !important;
            border-color: black !important;
        }

        .item-renderer {
            align-items: center;
        }

        .gray {
            color: $secondary-color-1;
        }

        .options {
            background-color: $white;

            &::-webkit-scrollbar {
                width: 2.5px;
                z-index: 999;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(#242424, 0.2) !important;
                border-radius: 5px;
                z-index: 999;
            }
        }
    }
}

.css-1xc3v61-indicatorContainer {
    padding: 0 8px 0 0 !important;
}
.custom-select__control--is-focused {
    height: 30px !important;
    min-height: 30px !important;
    display: flex;
    align-items: center;
    .custom-select__indicator {
        padding: 0 8px 0 0 !important;
    }
}

.completed-program {
    tbody {
        tr {
            td {
                padding: 11px !important;
            }
        }
    }
}
.dropdown-container[aria-expanded="true"] {
    border: 1px solid black !important;
}

.css-t3ipsp-control:hover {
    border-color: black !important;
}
.css-t3ipsp-control {
    border-color: black !important;
}
.css-13cymwt-control {
    outline: none;
}

.custom-select__option {
    padding: 6px 12px !important;
    border-left: 1px solid #f1f1f1;
    @media screen and (max-width:1100px){
        border-right: 1px solid #f1f1f1;
    }
}
.rmsc .dropdown-container {
    &:hover {
        border: 1px solid $secondary-color-1;
    }
    &:focus {
        box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5) !important;
    }
}
.selectSport-form-control{
    .custom-single-select{
        .custom-select__menu{
            .custom-select__option--is-focused {
                @media screen and (max-width:1024px) {
                   background-color: unset!important;
                }
               }
        }
    
    }

}

