.psychology-case-log,
.psychology-session {
    &.si-view {
        .si-headsession {
            .si-button {
                display: flex;

                @media screen and (max-width: $media-tablet-max) {
                    display: none;
                }
            }
        }

        .si-savebtn {
            display: none;
        }

        .icon-edit {
            display: inline-block;
            margin-left: 12px;
            color: $neutral-color-2;
            cursor: pointer;
        }

        .si-default {
            cursor: default !important;
            pointer-events: none;
            filter: grayscale(90%);
            -webkit-filter: grayscale(90%);
            -moz-filter: grayscale(90%);
            -ms-filter: grayscale(90%);
            -o-filter: grayscale(90%);
            box-shadow: none;
        }
        .si-input {
            &.si-disable {
                background: #f9f9f9 !important;
                border-color: #c8c8c8;
                border-radius: 4px;
            }
        }
        .custom-select__placeholder {
            color: #6e6e6e !important;
        }
        .custom-select__single-value {
            color: #6e6e6e !important;
        }
    }

    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__time-name {
        line-height: normal;
    }
    .react-datepicker__calendar-icon {
        width: 1.8em;
        height: 1.8em;
    }
    .react-datepicker__view-calendar-icon {
        &::before {
            top: 7px;
            left: 11px;
        }
    }
    .css-1jqq78o-placeholder {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .custom-select__menu {
        z-index: 9;
    }
    .react-datepicker__view-calendar-icon input {
        border-color: #c8c8c8;
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        height: 31px;
        width: 115px;
        cursor: pointer;
        padding-left: 32px;
        padding: 6px 10px 5px 35px !important;

        @media screen and (max-width: $media-tablet-max) {
            width: 130px;
        }
    }
    .react-datepicker__view-calendar-icon input {
        &:disabled {
            background: #f9f9f9 !important;
            color: #282825 !important;
            border-color: #c8c8c8;
            border-radius: 4px;
            -webkit-text-fill-color:#282825;
            opacity: 1;
            &:hover{
                border-color: #c8c8c8!important;
            }
        }
    }
    .css-hlgwow {
        padding: 2px 7px !important;
    }
    .css-13cymwt-control {
        border-radius: 5px;
    }
    .custom-select--is-disabled .custom-select__indicators svg {
        color: #6e6e6e !important;
    }
    .css-16xfy0z-control {
        min-height: 30px;
        background-color: #f9f9f9;
        border-color: #c8c8c8;
    }

    .form-control {
        width: 50%;
    }
    .si-error {
        .si-input {
            border-color: #e10000;
        }
    }
    .icon-edit {
        display: none;
    }

    .si-additionalInfo {
        width: 100%;
        height: 36px;
        border-radius: 5px;
        color: $neutral-color-2;
        background-color: #f1f1f1;
        font-size: 14px;
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 15px;
        @media screen and (max-width: $media-tablet-max) {
            margin-bottom: 10px;
        }
    }

    .si-headsession {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        padding-top: 0px;

        .si-button {
            height: 30px;

            display: none;
        }
    }

    .case-log-header {
        font-size: 16px;
        font-weight: 600;
        color: $secondary-color-1;
        padding: 15px 0;
    }

    .si-clientInfo {
        display: flex;
        align-items: center;

        .si-image {
            width: 30px;
            height: 30px;
            border-radius: 65px;
            background-color: $white;
            padding: 5px;
            overflow: hidden;

            & img {
                width: 100%;
                height: auto;
            }
        }

        .si-clientname {
            font-size: 18px;
            color: $secondary-color-2;
            border-radius: 5px;
            padding: 0 15px;
            font-family: $semibold_font-family;
            font-weight: $semibold_font-weight;
        }
    }

    .si-title {
        background-color: #e7e0ec;
        font-size: 14px;
        color: $secondary-color-2;
        font-weight: 700;
        border-radius: 5px;
        padding: 2.5px 15px;
    }

    .si-error-message {
        position: absolute;
    }
    .react-datepicker-wrapper {
        @media screen and (max-width: $media-tablet-max) {
            width: 100%;
        }
        input:disabled {
            font-size: 12px;
            font-family: "poppins";
            color: #6e6e6e !important;
           -webkit-text-fill-color: #6e6e6e!important;
            opacity: 1;
            cursor: default;
            @media screen and (max-width: $media-tablet-max) {
                font-size: 14px;
                opacity: 1;
            }
           
        }
        input {
            font-size: 12px;
            font-family: "poppins";
            @media screen and (max-width: $media-tablet-max) {
                font-size: 14px;
            }
        }
    }
    .content-row-wrp {
        background-color: $white;
        border-radius: 5px;
        padding: 15px;
        height: calc(100vh - 216px);
        overflow: auto;
        @media screen and (min-width: 993px) and (max-width:1199px) {
            height: calc(100dvh - 216px);
          }
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100dvh - 275px);
            padding-top: 10px;
        }

        &::-webkit-scrollbar {
            width: 3px;
            z-index: 999;

            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }

        &::-webkit-scrollbar-track {
            z-index: 999;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(#242424, 0.2);
            border-radius: 10px;
            z-index: 999;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(#242424, 0.2);
            z-index: 999;
        }
    }
    .content-row {
        display: flex;
        gap: 20px;
        margin-bottom: 17px;
        width: 100%;
        @media screen and (max-width: $media-tablet-max) {
            gap: 10px;
        }
        &:first-child {
            gap: 0;
        }

        @media screen and (max-width: $media-tablet-max) {
            &:nth-of-type(1) {
                flex-direction: row;
            }

            flex-direction: column;
        }

        &:nth-of-type(1) {
            @media screen and (max-width: $media-tablet-max) {
                flex-direction: row;
                flex: 1;
                width: 100%;
            }

            .inner-content-wrp {
                flex: unset;
                width: unset;
                .form-control {
                    &:first-child {
                        margin-right: 20px;
                    }
                }
                @media screen and (max-width: $media-tablet-max) {
                    flex: unset;
                    width: unset;
                }
            }
        }

        &:nth-of-type(4) {
            .inner-content-wrp {
                .selectSport-form-control {
                    .si-disable {
                        border: 1px solid #c8c8c8 !important;
                        background: #eaeaea !important;
                        color: $neutral-color-2 !important;
                    }
                }
                &:nth-of-type(1) {
                    flex: 0 0 24%;
                    width: 24%;
                    @media screen and (max-width: $media-tablet-max) {
                        flex: 1;
                        width: 100%;
                    }
                }

                &:nth-of-type(2) {
                    flex: 0 0 34%;
                    width: 34%;
                    @media screen and (max-width: $media-tablet-max) {
                        flex: 1;
                        width: 100%;
                    }
                    .form-control {
                        &:nth-of-type(2) {
                            flex: 0 0 38%;
                            width: 38%;
                            @media screen and (max-width: $media-tablet-max) {
                                flex: 1;
                                width: 100%;
                            }
                        }
                    }
                }
                &:nth-of-type(3) {
                    flex: 0 0 37%;
                    width: 37%;
                    @media screen and (max-width: $media-tablet-max) {
                        flex: 1;
                        width: 100%;
                    }
                }
            }
        }

        .inner-content-wrp {
            display: flex;
            gap: 20px;
            flex: 0 0 32%;
            width: 32%;

            .form-control {
                flex: 1;

                @media screen and (max-width: $media-tablet-max) {
                    width: 100%;
                }
            }
        }

        .reason-desc {
            width: 100%;
            .si-error-message {
                margin-top: -5px;
            }
        }

        .date-form-control {
            position: relative;
            cursor: pointer;
        }

        input[type="radio"] {
            accent-color: $secondary-color-1;
        }

        .gender-wrp {
            font-size: 12px;
            display: flex;
            padding-top: 7px;

            .gender-text {
                display: inline-block;
                margin-left: 10px;
                margin-right: 25px;

                &:last-child {
                    margin-right: unset;
                }
            }
        }
    }

    .width-machine {
        /*   Sort of a magic number to add extra space for number spinner */
        padding: 0 1rem;
    }

    .textarea {
        display: block;
        width: 100%;
        overflow: hidden;
        resize: both;
        min-height: 70px;
        line-height: 20px;
    }

    .textarea {
        border-radius: 5px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        border: 1px solid #c8c8c8;
        background-color: #ffffff;
        color: #282825;
        width: 100%;
        padding: 5px 10px;
        font-size: 15px;

        &.si-disable {
            background: #f9f9f9 !important;
            color: #282825 !important;
        }
    }
    textarea[readonly] {
        background: #f9f9f9 !important;
        border: 1px solid #c8c8c8;
        color: #6e6e6e;
        -webkit-text-fill-color: #6e6e6e;
        cursor: default;
        &:focus ,&:hover{
            border: 1px solid #c8c8c8;
            box-shadow: unset;
        }
    }
    .si-disable,
    .si-default {
        border: 1px solid #c8c8c8 !important;
        background: #eaeaea !important;
        color: $neutral-color-2;
        pointer-events: none;
        -webkit-text-fill-color: #6e6e6e;
    }
    .custom-select__placeholder {
        color: #282828;
    }
}

.psychology-session {
    &.si-view {
        textarea,
        select,
        input {
            background: #f9f9f9;
        }
    }

    .content-row {
        @media screen and (max-width: $media-tablet-max) {
            flex-direction: row;
        }
    }
    textarea[readonly] {
        background: #f9f9f9 !important;
        cursor: default;
        &::-webkit-scrollbar {
            width: 3px;
            z-index: 999;

            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }
    }
    @media screen and (max-width: $media-tablet-max) {
        margin-left: -10px;
        padding-right: 0;
    }
}

footer {
    position: fixed;
    padding: 0 15px;
    bottom: 10px;
    right: 0;

    @media screen and (max-width: $media-tablet-max) {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .si-button {
        @media screen and (max-width: $media-tablet-max) {
            width: auto;
            flex: 1;
        }
    }
}
.si-view .react-datepicker__view-calendar-icon::before,
.psychology-session .react-datepicker__view-calendar-icon::before {
    color: #6e6e6e !important;
    cursor: default;
}
.reffered-by {
    width: 190px !important;
    @media screen and (max-width: $media-tablet-max) {
        width: 170px !important;
    }
}
.psychology-case-log {
    .case-log-content {
        @media screen and (max-width: $media-tablet-max) {
            margin: 0 -10px;
        }
    }
}
