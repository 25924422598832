@import "../mixins";
@import "../variables";
@import "../variables-en";

.si-main-content {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  .si-subHeader {
    margin-top: 0;
  }
  .si-popup-content {
    .si-subHeader {
      margin-top: 15px;
    }
  }
  &::-webkit-scrollbar {
    width: 3.68px;
    z-index: 999;
    @media screen and (max-width: $media-tablet-max) {
      width: 0px;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    z-index: 999;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(#242424, 0.2);
    border-radius: 10px;
    z-index: 999;
  }
  &.si-dashboard {
    overflow-y: auto;
    @media screen and (max-width: $media-tablet-max) {
      height: calc(100% - 70px);
    }
  }

  @media screen and (max-width: $media-tablet-max) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__scrollable {
    height: calc(100vh - 190px);
    height: -webkit-calc(100vh - 190px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      z-index: 999;
    }
  }

  .si-tab-content {
    .si-firstStep {
      @media screen and (max-width: $media-tablet-max) {
        top: 50%;
      }
    }
  }
}
.si-treckBtn {
  width: 189px;
}
.si-treckBtn,
.si-viewBtn {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  height: 30px;
  background: transparent;
  color: #282828;
  font-family: $medium_font-family;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  white-space: nowrap;
  &:focus {
    border: 1px solid $secondary-color-1;
    box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
  }
  &:hover {
    @media screen and (min-width: 993px) {
      border: 1px solid $secondary-color-1;
    }
  }

  @media screen and (max-width: $media-tablet-max) {
    border: 0;
    width: auto;
    height: auto;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .si-txt {
    padding-left: 10px;
    @media screen and (max-width: $media-tablet-max) {
      display: none;
    }
  }

  .si-treckicon {
    position: relative;
    margin-right: 10px;

    &:after {
      content: "";
      background-image: url("../images/treck.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 16px;
      height: 16px;
      position: absolute;
      left: -7px;
      top: -8px;

      @media screen and (max-width: $media-tablet-max) {
        top: -8px;
      }
    }
  }
}

.si-logBtn {
  width: 120px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background: transparent;
  font-size: 12px;
  color: $secondary-color-1;
  font-family: $medium_font-family;
  font-weight: $medium_font-weight;
  cursor: pointer;
  position: relative;
  margin-top: 4px;
  margin-bottom: 4px;
  &:focus {
    border: 1px solid $secondary-color-1;
    box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
  }
  &:hover {
    @media screen and (min-width: 993px) {
      border: 1px solid $secondary-color-1;
    }
  }
  @media screen and (max-width: $media-tablet-max) {
    min-width: 67px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &:after {
    content: "\e904";
    font-size: 12px;
    position: absolute;
    font-family: "AMS" !important;
    color: #f9ad4a;
    right: 9px;
    display: none;
    top: 9px;
  }
}
.si-download {
  cursor: pointer;
  .icon-download {
    font-size: 21px;
  }
}

.si-label-wrap {
  margin-bottom: 11px;
}

.si-headwrp {
  .custom-select--is-disabled {
    background: transparent !important;

    .custom-select__control {
      background-color: transparent !important;
      border: none !important;
    }
  }

  &.si-margin {
    @media screen and (max-width: $media-tablet-max) {
      padding-left: 0;
      padding-top: 10px;
    }

    .icon-back {
      justify-content: flex-start;

      @media screen and (max-width: $media-tablet-max) {
        justify-content: center;
      }
    }

    .si-label-append {
      margin-left: 7px;
    }

    .form-control {
      &:nth-of-type(3) {
        select {
          margin-left: 0;
          padding-left: 4px;
        }
      }

      @media screen and (max-width: $media-tablet-max) {
        &:nth-of-type(2) {
          width: 59px;
        }

        &:nth-of-type(3) {
          select {
            padding-left: 6px;
          }
        }
      }

      @media screen and (max-width: $media-mobile-small-max-456) {
        &:nth-of-type(3) {
          select {
            padding-left: 8px;
          }
        }
      }
    }
  }
}
.si-reports {
  height: unset !important;
}
.si-Performanceheader {
  border-radius: 10px;

  width: 98%;
  height: 75px;
  display: flex;
  align-items: center;
  padding-right: 0px;
  position: relative;
  top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (min-width: $media-tablet-max) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: $media-tablet-max) {
    background: transparent;
    flex-flow: column;
    padding-top: 12px;
    top: 0;
    height: 70px;
    margin: auto;
    height: 115px;
    margin-right: 0;
    //  margin-left:0;
  }

  &:after {
    content: "";
    position: absolute;
    background-image: url("../images/patch.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    height: 67px;

    border-radius: 10px;
    width: 97%;

    right: 0;

    @media screen and (max-width: $media-tablet-max) {
      width: 97%;
      right: 5px;
    }

    @media screen and (max-width: $media-mobile-max-640) {
      height: 66px;
      border-radius: 10px;
      width: 95%;
      right: 4px;
      top: 13px;
    }

    @media screen and (max-width: $media-mobile-max-580) {
      width: 90%;
      right: 13px;
    }
  }

  .rangeSlider.rangeSlider__horizontal,
  .rangeslider.rangeslider-horizontal {
    width: 95% !important;
  }

  .si-progressbar {
    left: 0;
    right: 0;
    padding-bottom: 11px;
    padding-top: 7px;
    width: 100%;

    @media screen and (max-width: $media-tablet-max) {
      padding-top: 26px;
    }

    .si-progressInfo {
      display: flex;
      padding-bottom: 4px;
      width: 100%;
      justify-content: space-between;
      color: $white;
      z-index: 1;
      font-size: 10px;

      @media screen and (max-width: $media-desktop-max-1024) {
        font-size: 8px;
      }

      @media screen and (max-width: $media-tablet-max) {
        padding-bottom: 10px;
      }

      .si-Label {
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }
      }

      .si-percent {
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        position: absolute;
        right: 6px;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }
      }
    }

    &.si-red {
      .rangeSlider__fill,
      .rangeslider__fill {
        background: #d51c00;

        &:before {
          border: 2px solid #d51c00;
        }
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        background: #d51c00;
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        border: 3px solid #ff7965;
      }
    }

    &.si-green {
      .rangeSlider__fill,
      .rangeslider__fill {
        background: #63c185;

        &:before {
          border: 2px solid #63c185;
        }
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        background: #63c185;
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        border: 3px solid #a3ddb8;
      }
    }
  }

  .progress {
    border-radius: 50%;

    position: relative;

    &:before {
      --s: 1;
      content: "";
      width: 28px;
      height: 28px;
      left: 50%;
      top: 50%;
      z-index: 1;
      position: absolute;

      border-radius: inherit;
      transform: translate(-50%, -50%) scale(var(--s));
      transition: transform 0.32s ease;
    }
  }

  .rangeSlider,
  .rangeslider {
    background: #c8c8c8 !important;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    left: 48%;
    @media screen and (max-width: $media-tablet-max) {
      left: 50%;
    }
    transform: translateX(-50%);

    &.rangeSlider__horizontal,
    &.rangeslider-horizontal {
      width: 109px;
      height: 6px;

      @media screen and (max-width: $media-desktop-small-max) {
        width: 97px;
      }

      @media screen and (max-width: $media-desktop-max-1024) {
        width: 268px !important;
      }

      @media screen and (max-width: $media-tablet-max) {
        width: 100% !important;
        height: 6px;
      }

      @media screen and (max-width: $media-mobile-small-max-420) {
        height: 6px;
      }
    }

    .rangeSlider__fill,
    .rangeslider__fill {
      border-radius: 7px;
      position: absolute;

      &:before {
        content: "";
        left: -2px;
        top: -2px;
        bottom: -2px;
        right: -2px;
        border-radius: 6px;
        position: absolute;
      }
    }

    .rangeSlider__fill__horizontal,
    .rangeslider__fill {
      height: 39%;
      top: 2px;
      left: 0;

      @media screen and (max-width: $media-desktop-max-1024) {
        height: 38%;
      }

      @media screen and (max-width: $media-tablet-max) {
        height: 23%;
      }
    }

    .rangeSlider__handle,
    .rangeslider__handle {
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      display: inline-block;
      width: 15px;
      height: 15px;
      position: absolute;
      border-radius: 50%;
      display: none;
    }

    .rangeSlider__handle__horizontal,
    .rangeslider__handle {
      top: -5px;
    }
  }

  .progress {
    &.complete {
      & + input {
        & + .rangeSlider,
        & + .rangeslider {
          opacity: 0;
          transition: opacity 0.4s ease;
        }
      }
    }
  }

  .si-infoWrp {
    display: flex;
  }

  .si-infoDetails {
    margin-left: 15px;

    line-height: 20px;
    padding-top: 10px;

    @media screen and (max-width: $media-tablet-max) {
      margin-left: 13px;
    }
  }

  .si-name {
    color: $white;
    font-size: 20px;
    text-transform: capitalize;
    font-family: $bold_font-family;
    font-weight: $bold_font-weight;

    @media screen and (max-width: $media-desktop-max-1024) {
      font-size: 22px;
    }

    @media screen and (max-width: $media-tablet-max) {
      font-size: 18px;
    }
  }

  .si-otherInfo {
    margin-top: 5px;
    align-items: center;
    @media screen and (max-width: $media-tablet-max) {
      width: 260px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0;
        z-index: 999;
      }
    }
  }

  .si-otherInfo,
  .si-groupWrp {
    display: flex;
  }

  .si-groupWrp {
    margin-left: 10px;
    &:first-child {
      margin-left: 0px;
    }
  }

  .si-group {
    min-width: 50px;
    height: 22px;
    background: rgba(200, 200, 200, 0.2);
    border-radius: 25px;
    color: $white;
    font-size: 10px;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    white-space: nowrap;

    @media screen and (max-width: $media-mobile-small-max-420) {
      min-width: 47px;
      height: 25px;
      font-size: 10px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .si-profession {
    color: $white;
    font-size: 16px;
    text-transform: capitalize;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;

    @media screen and (max-width: $media-tablet-max) {
      font-size: 11px;
    }
  }

  .si-Left {
    width: 65%;
    flex: 0 0 65%;
    z-index: 1;

    @media screen and (max-width: 1199px) {
      width: 60%;
      flex: 0 0 60%;
    }

    @media screen and (max-width: $media-tablet-max) {
      width: 100%;
      flex: 1;
    }
  }

  .si-Image {
    min-width: 67px;
    height: 67px;
    border-radius: 65px;
    background: $white;
    overflow: hidden;
    @media screen and (max-width: $media-tablet-max) {
      margin-left: -10px;
    }
    @media screen and (max-width: 420px) {
      margin-left: 0px;
    }

    & img {
      width: 55px;
      height: auto;
      margin: auto;
      display: flex;
    }
  }

  .si-Right {
    width: 35%;
    flex: 0 0 35%;
    font-family: $semibold_font-family;
    font-weight: $semibold_font-weight;
    font-size: 24px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1199px) {
      width: 40%;
      flex: 0 0 40%;
      font-size: 19px;
      padding-left: 37px;
      margin-top: -6px;
    }
    @media screen and (min-width:993px) and (max-width: 1199px) {
      padding-left: 13px;
    }
    
    @media screen and (max-width: $media-tablet-max) {
      width: 99%;
      flex: 0 0 99%;
      display: flex;
      align-items: center;
      padding-left: 0;
      margin-top: -24px;
      margin-left: -11px;
    }

    @media screen and (max-width: $media-mobile-small-max-456) {
      width: 95%;
      flex: 0 0 98%;
      margin-top: -33px;
    }

    .si-top {
      @media screen and (max-width: $media-tablet-max) {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .si-head {
      color: $white;

      @media screen and (max-width: $media-tablet-max) {
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        font-size: 12px;
        color: $secondary-color-1;
        padding-bottom: 8px;
      }
    }

    .si-percent {
      color: $primary-color-5;

      @media screen and (max-width: $media-tablet-max) {
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        font-size: 12px;
        padding-left: 8px;
        color: $secondary-color-1;
        padding-bottom: 8px;
      }
    }
  }
}

.si-subHeader {
  @include flexbox();
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 15px;

  @media screen and (max-width: $media-tablet-max) {
    margin-top: 15px;
  }

  @media screen and (max-width: $media-tablet-max) {
    position: relative;
    z-index: 9;
    padding-left: 10px;
    padding-right: 10px;
  }

  .si-button {
    margin-left: auto;
  }

  .form-control-wrap {
    @include flexbox();
    gap: 15px;
    flex: 1;
    font-size: 14px;

    @media screen and (max-width: $media-tablet-max) {
      margin-left: 45px;
      z-index: 1;
      font-size: 12px;

      .form-control {
        width: 49%;

        &:nth-of-type(2) {
          margin-left: 5px;
        }

        & select {
          width: 100%;
        }
      }
    }

    .custom-multi-select,
    .custom-single-select {
      width: 220px;
      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5) !important;
        border-radius: 5px;
      }

      @media screen and (max-width: 1100px) {
        width: 140px !important;
      }
    }
    .custom-single-select {
      .css-1jqq78o-placeholder {
        color: #282828;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
      }
      .custom-select__option {
        font-weight: 600;
      }
      .custom-select__menu {
        margin-top: 8px !important;
      }
    }

    .si-tab {
      width: 260px;
      .switch label {
        font-size: 12px;
        text-transform: uppercase;
      }

      @media screen and (max-width: $media-tablet-max) {
        width: 220px;
        .switch label {
          font-size: 14px;
        }
      }

      @media screen and (max-width: $media-mobile-max-540) {
        width: 140px;
        .switch label {
          font-size: 12px;
          width: 50%;
          flex: 0 0 50%;
          margin: 0;
        }
      }
    }
  }

  .si-calender-week {
    font-size: 14px;

    &.form-control {
      width: 200px;

      .si-input {
        padding-right: 10px;
      }
    }
    .custom-select__single-value {
      @media screen and (max-width: $media-tablet-max) {
        font-weight: 600;
      }
    }
  }

  .form-control {
    position: relative;
    height: 30px;
    margin-bottom: 0;
    @media screen and (max-width: $media-tablet-max) {
      height: 30px;
    }

    &.si-searchbox {
      width: 340px;

      input {
        border-radius: 5px;

        @media screen and (max-width: $media-tablet-max) {
          border-radius: 5px;
        }
      }

      @media screen and (max-width: $media-tablet-max) {
        width: auto;
        position: absolute;
        height: 32px;
        line-height: 0;
        input {
          @include transition(width, 0.5s, ease-in-out);
          position: relative;
          font-size: 0;
          z-index: 2;
          height: 100%;

          &.animateIn {
            width: calc(100vw - 20px);
            padding-left: 40px;
            font-size: 14px;
            z-index: 2;
            bottom: unset;
            padding: 15px 35px;
            caret-color: black;
          }
        }
      }
      .icon-close {
        font-size: 12px !important;
      }
    }

    .si-input {
      height: 30px;
      padding-right: 34px;
      font-size: 12px;
      font-weight: 400;
      &::placeholder {
        color: #282828;
      }
      @media screen and (max-width: $media-tablet-max) {
        padding-right: 20px;
      }
    }

    .si-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 100%;

      @media screen and (max-width: $media-tablet-max) {
        &.icon-search,
        &.icon-close {
          left: 0px;
          top: 1px !important;
          right: initial;
          z-index: 2;
        }
      }
    }
  }

  .si-button {
    padding: 5.5px 20px;
    white-space: nowrap;
    @media screen and (min-width: $media-tablet-max) {
      height: 30px;
      min-width: unset;
    }

    @media screen and (max-width: $media-mobile-small-max) {
      width: 95%;
    }
  }
  .custom-select__control {
    min-height: 30px !important;
    @media screen and (max-width: $media-tablet-max) {
      min-height: 32px !important;
    }
  }
}

.si-heading {
  @include flexbox();
  align-items: center;

  .form-control {
    &:nth-of-type(3) {
      select {
        margin-left: 13px;
      }
    }
  }

  .form-control {
    margin-bottom: 0;
    position: relative;
    &:nth-of-type(2) {
      @media screen and (max-width: $media-tablet-max) {
        width: 95px;
      }

      @media screen and (max-width: $media-mobile-small-max-420) {
        padding-left: 10px;
        margin-left: 0;
      }
    }

    @media screen and (max-width: $media-tablet-max) {
      &:nth-of-type(2) {
        &:before {
          display: none;
        }
      }
    }

    &__title {
      .si-input {
        font-size: 14px;
        @media screen and (max-width: $media-tablet-max) {
          font-size: 16px;
        }
      }
    }

    + .form-control {
      padding-left: 12px;
      @media screen and (max-width: $media-tablet-max) {
        margin-left: 0;
      }

      &:before {
        content: "";
        position: absolute;
        border-left: 1px solid rgba($white, 0.3);
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 20px;
      }
    }

    &:nth-last-child(1) {
      &:before {
        border-left: none;
      }
    }
  }

  .label-text {
    @include border-radius(5px);
    @include flexbox();
    color: $white;
    font-weight: 400;
    font-size: 14px;
    background-color: #222222;
    padding: 5px 10px;
    align-items: center;
    margin-left: 10px;
    text-transform: capitalize;
  }

  .form-control-group {
    @include flexbox();
    align-items: center;
  }
}

.form-control__title {
  .si-input[disabled] {
    background-color: transparent;

    padding-right: 0;
  }
}

.si-week {
  @include flexbox();
  align-items: center;
  text-align: center;
  width: 38px;

  &.si-input[disabled] {
    padding: 0;
  }
}

.si-sports {
  &.si-input[disabled] {
    background: transparent !important;
    -webkit-appearance: none;
    opacity: 1;

    .select {
      background: transparent !important;
    }
  }
}

.si-label-append {
  color: $secondary-color-1;
  font-size: 12px;
  font-family: $semibold_font-family;
  font-weight: $semibold_font-weight;
}

@media screen and (max-width: $media-tablet-max) {
  .si-heading {
    .form-control-group {
      justify-content: center;
    }
  }

  .si-rightPart {
    .si-headwrp {
      &.si-calheader {
        height: 92px;
        align-items: flex-start;
        .icon-chev-left {
          font-size: 13px;
        }

        &.si-margin {
          min-height: 80px;
        }

        .si-heading {
          width: 100%;
          .form-control {
            &:nth-of-type(1) {
              width: 100% !important;
              .si-input {
                @media screen and (max-width: $media-mobile-small-max-420) {
                  font-size: 16px;
                }
              }
            }

            &:nth-of-type(3) {
              bottom: -40px;
              @media screen and (max-width: $media-mobile-small-max-380) {
                left: 0;
              }
            }

            &:nth-of-type(4) {
              right: 67px;
              top: 59px;
              left: initial;

              .icon-edit {
                position: absolute;
                right: -38px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-mobile-small-max-420) {
  .si-rightPart {
    .si-headwrp {
      .si-heading {
        .form-control {
          &:nth-of-type(1) {
            .si-input {
              font-size: 16px;
            }
          }

          &:nth-of-type(2) {
            .si-label-append {
              font-size: 12px;
            }
          }

          &:nth-of-type(3) {
            left: -10px;

            .si-sports {
              font-size: 12px;
            }
          }

          &:nth-of-type(4) {
            right: 44px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $media-tablet-max) {
  .si-label-wrap {
    display: none;
  }

  .si-tab-pane {
    &__editspecs {
      .si-custom-col {
        & + .si-custom-col {
          &::before {
            border-left: none;
          }
        }
      }

      .si-custom-row {
        display: block;
      }

      .si-custom-col {
        & + .si-custom-col {
          padding-left: 0;
          margin-left: 0;
        }
      }

      .form-control {
        flex-basis: 48%;
        margin-bottom: 10px;

        @media screen and (max-width: $media-tablet-max) {
          flex-basis: 26%;
        }

        @media screen and (max-width: $media-mobile-max-640) {
          flex-basis: 30%;
        }

        @media screen and (max-width: $media-mobile-max-620) {
          flex-basis: 28%;
        }

        @media screen and (max-width: $media-tablet-max) {
          flex-basis: 44%;
          margin-right: 18px !important;
        }

        & + .form-control {
          margin-top: 0;
        }
      }

      .si-form {
        @include flexbox();
        justify-content: space-between;
      }
    }
  }
}

.si-tabs {
  .si-main-content {
    padding-left: -0px !important;
    padding-right: -0px !important;

    &.si-athletebtn {
      position: initial !important;
      @media screen and (max-width: $media-tablet-max) {
        .content-wrapper {
          padding: 0 5px;
        }
      }
    }
  }

  .si-subHeader {
    padding-left: -0px !important;
    padding-right: -0px !important;
  }
}

// new added

@media screen and (max-width: $media-tablet-max) {
  .si-headcover {
    .si-heading {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .form-control__title {
    width: inherit;
    margin-bottom: 7px !important;
  }
}

.si-athleteprofile-tab {
  .si-subHeader {
    margin-top: 0px;
  }
}
.MuiBox-root {
  height: calc(100vh - 132px);
  @media screen and (min-width: 993px) and (max-width:1199px) {
    height: calc(100dvh - 132px);
  }

  @media screen and (max-width: $media-tablet-max) {
    height: calc(100dvh - 180px);
  }

  .MuiPaper-root {
    height: calc(100% - 58px);
    // added
    @media screen and (max-width: $media-tablet-max) {
      height: calc(100% - 60px);
    }
    .MuiTableContainer-root {
      height: 96%;
      @media screen and (max-width: $media-tablet-max) {
        height: 92%;
      }
    }
  }

  @media screen and (max-width: $media-tablet-max) {
    .si-pagination {
      bottom: 28px;
    }
  }
}

.report-main-table {
  .MuiBox-root {
    height: calc(100vh - 260px);
    @media screen and (min-width: 993px) and (max-width:1199px) {
      height: calc(100dvh - 260px);
    }
    @media screen and (max-width: $media-tablet-max) {
      height: calc(100vh - 315px);
    }
    .si-pagination {
      bottom: 10px;
    }
  }
}

.si-dashboard {
  .MuiBox-root {
    height: unset;
  }
}
.css-13cymwt-control {
  min-height: 30px !important;
}
.css-4oenxb {
  font-size: 12px !important;
}
.MuiTableCell-sizeSmall {
  font-size: 12px !important;
}
.si-tabs {
  .si-main-content {
    margin-top: 0px;
  }
}
.MuiTableHead-root {
  .MuiTableCell-head {
    white-space: nowrap;
  }
}
.si-tab-pane__generalinfo {
  height: calc(100vh - 220px);
  overflow-y: scroll;
  @media screen and (min-width: 993px) and (max-width:1199px) {
    height: calc(100dvh - 220px);
  }
  @media screen and (max-width: $media-tablet-max) {
    height: calc(100dvh - 255px);
  }
}

// --tab--
@media screen and (max-width: $media-tablet-max) {
  .si-tab-content {
    .si-athleteprofile-tab .si-tab-content .si-assesment {
      height: calc(100vh - 322px) !important;
    }
  }
  .si-tabs {
    .si-Performanceheader {
      height: 90px;
    }
  }
}

.si-popup-body {
  .MuiTableContainer-root {
    height: 93% !important;
  }
}

.myprofile-maincontent {
  @media screen and (min-width: $media-tablet-max) {
    .MuiBox-root {
      height: calc(100dvh - 248px) !important;
    }
  }
}

@media screen and (max-width: $media-tablet-max) {
  .atheletes-tab--main-content {
    .MuiBox-root .MuiPaper-root .MuiTableContainer-root {
      height: 86%;
    }
  }
}

@media screen and (max-width: $media-tablet-max) {
  .si-Performanceheader + .si-subHeader + .report-main-table .MuiBox-root {
    height: calc(100vh - 385px);
  }

  .si-headwrp.si-calheader + .si-subHeader + .si-main-content .MuiBox-root {
    height: calc(100vh - 360px);
  }
  .si-Performanceheader + .si-athleteprofile + .si-main-content {
    .myprofile-maincontent {
      .MuiBox-root {
        height: calc(100dvh - 300px) !important;
        .si-custom-table-container {
          height: calc(100dvh - 400px) !important;
        }
      }
    }
  }
}
.si-tab-list + .si-main-content{
  .MuiBox-root {
    height: calc(100dvh - 165px);
    @media screen and (max-width:$media-tablet-max){
      height: calc(100dvh - 260px);
    }
}
}
