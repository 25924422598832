@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap" rel="stylesheet');
//Font Family
$light_font-family: 'Poppins', sans-serif;
$regular_font-family: 'Poppins', sans-serif;
$medium_font-family: 'Poppins', sans-serif;
$semibold_font-family: 'Poppins', sans-serif;
$bold_font-family: 'Poppins', sans-serif;



//Font Weight
$light_font-weight: 300;
$regular_font-weight: 400;
$medium_font-weight: 500;
$semibold_font-weight: 600;
$bold_font-weight: 700;