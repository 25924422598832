@font-face {
  font-family: 'AMS';
  src: url('../fonts/AMS.eot?qhqhm2');
  src: url('../fonts/AMS.eot?qhqhm2#iefix') format('embedded-opentype'),
    url('../fonts/AMS.ttf?qhqhm2') format('truetype'),
    url('../fonts/AMS.woff?qhqhm2') format('woff'),
    url('../fonts/AMS.svg?qhqhm2#AMS') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AMS' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-academic-player:before {
  content: "\e966";
  color: #e10000;
}
.icon-doctor-bag:before {
  content: "\e964";
}
.icon-download:before {
  content: "\e954";
}
.icon-progress:before {
  content: "\e962";
  color: #fff;
}

.icon-send:before {
  content: "\e956";
}

.icon-msg-send:before {
  content: "\e960";
}
.icon-12:before {
  content: "\e961";
  font-size: 20px;
}
.icon-11:before {
  content: "\e963";
  font-size: 18px;
}
.icon-10:before {
  content: "\e965";
  font-size: 19px;
}
.icon-8:before {
  content: "\e95f";
}

.icon-delete-2:before {
  content: "\e95e";
}
.icon-9:before {
  content: "\e958";
}
.icon-6:before {
  content: "\e959";
}
.icon-5:before {
  content: "\e95a";
}
.icon-1:before {
  content: "\e95b";
}
.icon-2:before {
  content: "\e95c";
}
.icon-7:before {
  content: "\e95d";
}
.icon-4:before {
  content: "\e90e";
}


.icon-updated-plus-icon:before {
  content: "\e957";
  color: #ff5046;
}

.icon-upload:before {
  content: "\e955";
}

.icon-test:before {
  content: "\e953";
  color: #e10000;
}
.icon-logout:before {
  content: "\e928";
}

.icon-treck1:before {
  content: "\e925";
}

.icon-ok:before {
  content: "\e926";
}

.icon-Difficult1:before {
  content: "\e927";
}

.icon-smile-1:before {
  content: "\e924";
}

.icon-notes2:before {
  content: "\e922";
}

.icon-eye:before {
  content: "\e921";
}

.icon-Eye-Close:before {
  content: "\e920";
  color: #c8c8c8;
}

.icon-Username:before {
  content: "\e91e";
}

.icon-Password:before {
  content: "\e91f";
}

.icon-calender:before {
  content: "\e91c";
  color: #282828;
}

.icon-treck:before {
  content: "\e91d";
}

.icon-notes1:before {
  content: "\e918";

}

.icon-Okay:before {
  content: "\e919";
}

.icon-smile:before {
  content: "\e91a";
}

.icon-Difficult:before {
  content: "\e91b";
}

.icon-view:before {
  content: "\e917";
  color: #787878;
}

.icon-check:before {
  content: "\e916";

}

.icon-tags:before {
  content: "\e915";

}

.icon-search:before {
  content: "\e90b";

}

.icon-carret:before {
  content: "\e900";

}

.icon-chev-down:before {
  content: "\e901";

}

.icon-chev-up:before {
  content: "\e902";

}

.icon-chev-left:before {
  content: "\e903";

}

.icon-chev-right:before {
  content: "\e904";

}

.icon-info:before {
  content: "\e905";

}

.icon-close:before {
  content: "\e906";

}

.icon-bell:before {
  content: "\e907";

}

.icon-options:before {
  content: "\e908";

}

.icon-arrow-left:before {
  content: "\e909";

}

.icon-arrow-right:before {
  content: "\e90a";

}

.icon-training:before {
  content: "\e90c";

}

.icon-user:before {
  content: "\e90d";

  font-weight: bold;
}


.icon-add:before {
  content: "\e957";
  font-size: 12px!important;
  
}

.icon-page:before {
  content: "\e90f";

}

.icon-notes:before {
  content: "\e910";

}

.icon-delete:before {
  content: "\e911";

}

.icon-edit:before {
  content: "\e912";

}

.icon-media .path1:before {
  content: "\e913";
  color: rgb(255, 255, 255);
}

.icon-media .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.icon-cloud-upload:before {
  content: "\e956";
  color: #0075e1;
}