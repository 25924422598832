.spin {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.6);
    backdrop-filter: blur(2px);
    z-index: 999;
    @include flexbox();
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;

    &:before {
        content: "";
        background-image: url("../images/loader.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 200px;
        height: 200px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
