.si-icon {
    &.si-icon-search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 34px;
    }
}

.si-searchBox {
    color: $white;

    @include placeholder {
        color: white;
    }

    input::-webkit-input-placeholder {
        /* Edge */
        color: $white;
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $white;
    }

    input::placeholder {
        color: $white;
    }
}
@media screen and (max-width: $media-tablet-max) {
    .completed-search {
        position: unset !important;
        .si-input {
            width: 350px !important;
        }
        .icon-search {
            left: unset !important;
            top: 0 !important;
        }
        .icon-close {
            left: 10px !important;
        }
        input {
            font-size: 14px !important;
            padding-left: 35px;
        }
        .icon-close {
            font-size: 12px;
        }
    }
    .si-athleteprofile-tab {
        .animateIn {
            width: 344px !important;
        }
        .completed-search {
            .si-input {
                width: 342px !important;
            }
            .icon-close {
                left: 0px !important;
            }
        }
    }
}

@media screen and (max-width: $media-tablet-max) {
    .completed-search {
        width: 100% !important;
        .si-input {
            width: 100% !important;
        }
    }
    .form-control-wrap:empty {
        display: none !important;
    }
}
