@import "../mixins";
@import "../variables";
@import "../variables-en";

.si-calender {
  border: 1px solid $neutral-color-5;
  position: relative;
  @include border-radius(10px);
  overflow: hidden;
  @media screen and (max-width: $media-desktop-max-1024) {
    width: 100%;
  }

  @media screen and (max-width: $media-tablet-max) {
    background: transparent;
    border: 0;
    overflow: initial;
  }

  @media screen and (max-width: $media-tablet-large-max) {
    float: none;
    margin: 0px auto 0;
    width: 100%;
    @include border-radius(20px 20px 0 0px);
    height: calc(100dvh - 335px);
  }

  @media screen and (max-width: 854px) and (orientation: landscape) {
    height: auto;
  }

  @media screen and (max-width: $media-mobile-max) and (orientation: landscape) {
    height: auto;
  }
  &.si-version1 {
    .si-calenderBottom {
      height: calc(100vh - 276px);
      @media screen and (max-width: $media-tablet-max) {
        height: calc(100vh - 382px);
        margin-top: 33px;
      }
    }
  }
  &.si-version2 {
    .si-Right {
      display: none;
    }
    .si-weekcalender {
      .si-tblheader {
        .si-column {
          padding-top: 12px;
          padding-bottom: 7px;
          @media screen and (max-width: $media-tablet-max) {
            padding-top: 0;
          }
          .si-day {
            @media screen and (max-width: $media-tablet-max) {
              margin-bottom: 0;
            }
            & span {
              @media screen and (max-width: $media-tablet-max) {
                padding-top: 4px;
              }
            }
          }
        }
      }
    }
  }

  .si-calenderBottom {
    @media screen and (max-width: $media-tablet-max) {
      height: calc(100vh - 410px);
    }
  }
  .si-weekcalender {
    .si-tblheader {
      .si-column {
        @media screen and (max-width: $media-tablet-max) {
          padding-top: 0;
          padding-bottom: 0;
        }

        .si-day {
          @media screen and (max-width: $media-tablet-max) {
            margin-bottom: 0;
          }

          & span {
            @media screen and (max-width: $media-tablet-max) {
              padding-top: 4px;
            }
          }
          &.si-no {
            padding-left: 3px;
          }
        }
      }
    }

    .si-progressbar {
      width: 78%;
      padding-top: 0px;
      padding-bottom: 7px;
      @media screen and (max-width: $media-tablet-max) {
        display: none;
      }
    }

    .si-percent {
      width: 50%;
      flex: 0 0 50%;
      position: relative !important;
      right: 0 !important;

      @media screen and (max-width: $media-tablet-max) {
        top: 4px;
      }
    }

    .si-percent {
      width: 50%;
      flex: 0 0 50%;
      position: relative !important;
      right: 0 !important;
      @media screen and (min-width: 992px) {
        margin-left: -3px;
      }
    }

    .si-Addnotes {
      width: 50%;
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      cursor: pointer;
      z-index: 9;
      @media screen and (min-width: 992px) {
        margin-right: -3px;
      }
      .si-addtxt {
        padding-left: 8px;
      }

      .si-addtxt,
      .si-percent {
        font-size: 12px;
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
      }

      .si-notesicon {
        width: 19px;
        position: relative;
        cursor: pointer;
        &:after {
          content: "\e922";
          font-family: "AMS" !important;
          position: absolute;
          font-size: 15px;
          color: $secondary-color-1;
          right: 0;

          @media screen and (max-width: $media-tablet-max) {
            font-size: 24px;
          }
        }
      }
    }

    .si-tblheader {
      .rangeSlider,
      .rangeslider {
        .rangeSlider__horizontal,
        .rangeslider-horizontal {
          @media screen and (max-width: $media-desktop-max-1024) {
            width: 90px !important;
          }
        }
      }
    }

    .si-calheader {
      .si-column {
        &.active {
          @media screen and (max-width: $media-tablet-max) {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .si-card__container {
    .si-Left {
      @media screen and (max-width: $media-tablet-max) {
        width: 97%;
        flex: 0 0 97%;
      }
    }
  }

  .si-day {
    display: flex;
    @media screen and (max-width: $media-tablet-max) {
      font-size: 11px !important;
      font-weight: 500 !important;
    }

    .si-no {
      padding-left: 3px;
      @media screen and (max-width: $media-tablet-max) {
        padding-left: 0;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
  }

  .si-daywrp {
    @media screen and (max-width: $media-tablet-max) {
      display: flex;
      flex-flow: column;
      flex-direction: column-reverse;
    }
  }

  .si-Daytxt,
  .si-year {
    @media screen and (max-width: $media-tablet-max) {
      display: none !important;
    }
  }

  .si-month {
    @media screen and (max-width: $media-tablet-max) {
      font-size: 11px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
    }
  }

  .si-dateno {
    @media screen and (max-width: $media-tablet-max) {
      font-size: 14px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      padding-bottom: 7px;
    }
  }

  .si-card__box {
    &.active {
      .rangeSlider__handle {
        display: flex;
      }
      .rangeslider__handle {
        display: flex;
        translate: -5.5px;
        @media screen and (max-width: $media-tablet-max) {
          translate: -7.5px;
        }
      }
    }
  }

  .si-mobileview {
    @media screen and (max-width: $media-tablet-max) {
      .si-percent {
        font-size: 14px !important;
      }

      .rangeSlider,
      .rangeslider {
        &.rangeSlider__horizontal,
        &.rangeslider-horizontal {
          width: 88% !important;

          @media screen and (max-width: $media-tablet-max) {
            width: 84% !important;
          }

          @media screen and (max-width: $media-mobile-small-max) {
            width: 83% !important;
          }

          @media screen and (max-width: $media-mobile-small-max-380) {
            width: 75% !important;
          }
        }
      }
    }
  }

  .si-column {
    &.activeday {
      background: #ffcbc7;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      .si-day {
        color: $primary-color-1 !important;
      }
      &::before {
        content: "";
        display: none;
      }
      &.si-column {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100vw;
          width: 100%;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
          @media screen and (max-width: $media-tablet-max) {
            display: none;
          }
        }
      }
    }
  }

  .si-card__container {
    .si-Left {
      line-height: 18px;
      width: 100%;
      flex: 1;
    }
    .si-Right {
      position: absolute;
      right: 0;
      width: 28px;
    }
  }

  .si-progressbar {
    left: 0;
    right: 0;
    padding-bottom: 11px;
    width: 100%;
    @media screen and (max-width: $media-tablet-max) {
      position: relative;
    }

    .si-progressInfo {
      display: flex;
      padding-bottom: 4px;
      width: 100%;
      justify-content: space-between;
      color: $secondary-color-1;
      z-index: 1;
      font-size: 10px;
      font-weight: 500;

      @media screen and (max-width: $media-desktop-max-1024) {
        font-size: 8px;
      }

      @media screen and (max-width: $media-tablet-max) {
        padding-bottom: 10px;
        font-weight: 400;
      }

      .si-Label {
        font-family: $medium_font-family;
        font-size: 12px;
        @media screen and (max-width: $media-desktop-max-1024) {
          font-size: 8px;
        }
        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }
      }

      .si-percent {
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        right: 6px;
        font-size: 12px;

        @media screen and (max-width: $media-tablet-max) {
          position: unset;
          right: 16px;
        }
      }
    }

    &.si-red {
      .rangeSlider__fill,
      .rangeslider__fill {
        background: #ff501e;
        border: 0px solid transparent;

        &:before {
          border: 2px solid #ff501e;
          background: #ff501e;
        }
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        background: #ff501e;
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        outline: 3px solid $neutral-color-8;
        left: 3.5px;
      }
    }

    &.si-green {
      .rangeSlider__fill,
      .rangeslider__fill {
        background: $active-color-3;

        &:before {
          border: 2px solid $active-color-3;
        }
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        background: $active-color-3;
      }

      .rangeSlider__handle,
      .rangeslider__handle {
        outline: 3px solid $neutral-color-8;
      }
    }
  }

  .progress {
    border-radius: 50%;

    position: relative;

    &:before {
      --s: 1;
      content: "";
      width: 28px;
      height: 28px;
      left: 50%;
      top: 50%;
      z-index: 1;
      position: absolute;

      border-radius: inherit;
      transform: translate(-50%, -50%) scale(var(--s));
      transition: transform 0.32s ease;
    }
  }

  .rangeSlider,
  .rangeslider {
    background: $neutral-color-4 !important;
    position: absolute;
    background: none;
    border-radius: 6px;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    &.rangeSlider__horizontal,
    &.rangeslider-horizontal {
      width: 92%;
      height: 5px;
      @media screen and (max-width: $media-tablet-max) {
        width: 100%;
      }
    }

    .rangeSlider__fill,
    .rangeslider__fill {
      border-radius: 7px;
      position: absolute;

      &:before {
        content: "";
        left: 0px;
        top: -2px;
        bottom: -2px;
        right: -2px;

        border-radius: 6px;
        position: absolute;
      }
    }

    .rangeSlider__fill__horizontal,
    .rangeslider__fill {
      height: 21%;
      top: 2px;
      left: 0;

      @media screen and (max-width: $media-tablet-max) {
        height: 23%;
      }
    }

    .rangeSlider__handle,
    .rangeslider__handle {
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      display: inline-block;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 50%;
      display: none;
      @media screen and (max-width: $media-tablet-max) {
        width: 15px;
        height: 15px;
      }
    }

    .rangeSlider__handle__horizontal,
    .rangeslider__handle {
      top: -2.5px;
      @media screen and (max-width: $media-tablet-max) {
        top: -5.5px;
      }
    }
  }

  .progress {
    &.complete {
      & + input {
        & + .rangeSlider,
        & + .rangeslider {
          opacity: 0;
          transition: opacity 0.4s ease;
        }
      }
    }
  }

  .icon-chev-right {
    font-size: 10px;
    right: 6px;
    position: relative;

    @media screen and (max-width: $media-tablet-max) {
      font-size: 18px;
    }

    &:before {
      color: $client-primary-color;
    }
  }
  .si-tblheader {
    .si-column {
      &.activeday {
        .si-indication {
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background: #e10000;
            bottom: -1px;
            z-index: 999;
            left: 0;
            right: 0;
            margin: auto;

            @media screen and (max-width: $media-tablet-max) {
              display: none;
            }
          }
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #e10000;
            position: absolute;
            bottom: -6px;
            z-index: 9;
            left: 0;
            right: 0;
            margin: auto;
            @media screen and (max-width: $media-tablet-max) {
              display: none;
            }
          }
        }
      }
    }
  }

  .audio-progress .bar {
    height: 100%;
    background-color: #e95f74;
    border-radius: 70px;
  }

  .si-card__container {
    min-height: auto;
    @media screen and (max-width: $media-tablet-max) {
      padding-right: 15px;
    }
    &:hover {
      @media screen and (min-width: 769px) {
        background-color: $neutral-color-7;
      }
    }
  }

  &.si-logperformance {
    .si-weekcalender {
      .si-tblheader {
        .si-column {
          padding-bottom: 3px;
        }
      }
    }
    .si-Right {
      display: none;
    }
  }
  &.si-caltemplate {
    .si-calenderBottom {
      height: calc(100vh - 250px);
      @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 250px);
      }
      .si-card__list {
        &.activeday {
          height: calc(100vh - 273px);
        }
      }
      @media screen and (max-width: $media-tablet-max) {
        height: calc(100vh - 391px);
      }
    }
  }
  &.si-calprogram {
    .si-calenderBottom {
      height: calc(100vh - 280px);
      @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 280px);
      }

      @media screen and (max-width: $media-tablet-max) {
        height: calc(100vh - 440px);
      }
    }
    .si-weekcalender {
      .si-tblheader {
        .si-column {
          padding-bottom: 12px;
        }
      }
    }
  }

  &.si-coachview {
    .si-weekcalender {
      .si-tblheader {
        .si-column {
          padding-bottom: 6px;
        }
      }
    }
    .si-Right {
      display: none;
    }

    .si-calenderBottom {
      height: calc(100vh - 323px);
      @media screen and (max-width: $media-tablet-max) {
        height: calc(100vh - 535px);
      }
    }
  }
  .si-version3 {
    .si-calenderBottom {
      height: calc(100vh - 327px);
    }
  }
  .si-calheader {
    .si-top {
      @extend %flexbox;
      margin-bottom: 25px;

      .si-left {
        @extend %flexbox;
        flex: 1;

        @media screen and (max-width: $media-tablet-large-max) {
          flex-flow: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        .si-assign,
        .si-unassign {
          @extend %flexbox;
          font-family: $bold_font-family;
          font-weight: $bold_font-weight;
          font-size: 14px;
          align-items: center;

          @media screen and (max-width: $media-tablet-large-max) {
            font-size: 12px;
          }

          & span {
            padding-left: 4px;
          }
        }

        .si-assign {
          color: $client-primary-color;
        }

        .si-unassign {
          color: $client-secondary-color;
          padding-left: 27px;

          @media screen and (max-width: $media-tablet-large-max) {
            padding-left: 0;
            margin-top: 10px;
          }
        }
      }

      .si-right {
        width: 444px;

        @media screen and (max-width: 991px) {
          flex: 0 0 45%;
          width: 45%;
        }

        .si-searchBox {
          max-width: 444px;

          input {
            @include border-radius(30px);
            border: 1px solid $white;
            padding: 10px;

            @media screen and (max-width: $media-tablet-large-max) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .si-weekcalender {
    .si-tblheader {
      position: relative;
      @extend %flexbox;

      @media screen and (max-width: $media-tablet-large-max) {
        @include box-shadow(0, 0, 18px, 0, rgba($black, 0.14));
      }

      @media screen and (max-width: $media-tablet-max) {
        padding: 0;
        box-shadow: none;
      }
      .si-progressInfo {
        .si-percent {
          @media screen and (max-width: $media-desktop-max-1024) {
            font-size: 11px;
          }
        }
      }
      .si-column {
        @extend %flexbox;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        color: #5c5c5c;
        position: relative;
        padding-top: 6px;
        padding-bottom: 10px;
        @media screen and (max-width: $media-tablet-max) {
          padding-bottom: 2px !important;
        }
        & + .si-column {
          &::before {
            content: "";
            position: absolute;
            border-left: 1px solid $neutral-color-5;
            top: 75px;
            left: -1px;
            height: 100vw;
          }
        }

        @media screen and (max-width: $media-tablet-max) {
          & + .si-column {
            &::before {
              content: "";

              display: none;
            }
          }
        }

        @media screen and (max-width: $media-tablet-max) {
          width: 92.72px;
          height: 45px;
          background: $neutral-color-5;
          border-radius: 5px;
          padding-top: 6px;

          &.active {
            background: $primary-color-1;
            border-radius: 5px;
            .si-dateno {
              font-weight: 700 !important;
            }

            .si-day {
              color: $white !important;
              .si-no {
                font-weight: 700 !important;
              }
            }
          }

          &:not(:last-child) {
            margin-right: 9px;
          }
        }

        .si-day {
          font-family: $medium_font-family;
          font-weight: $regular_font-weight;
          font-size: 12px;
          line-height: 1;
          margin-bottom: 10px;
          color: $secondary-color-1;
          &.si-no {
            margin-left: 3px;
          }
          @media screen and (max-width: $media-desktop-max-1024) {
            font-size: 12px;
          }
          @media screen and (max-width: $media-tablet-max) {
            color: $secondary-color-1;
            font-family: $bold_font-family;
            display: flex;
            flex-flow: column;
            & span {
              display: block;
              text-align: center;
              padding-top: 7px;
            }
          }

          .si-Daytxt {
            padding-right: 3px;
          }
          .si-year {
            padding-left: 3px;
          }
          &.si-shortName {
            display: none;
          }

          @media screen and (max-width: $media-tablet-large-max) {
            margin-bottom: 5px;
          }
        }

        .si-date {
          font-family: $regular_font-family;
          font-weight: $regular_font-weight;
          font-size: 35px;
          line-height: 1;

          @media screen and (max-width: $media-tablet-large-max) {
            font-size: 30px;
          }
        }
      }

      .si-exerise {
        @include border-radius(5px);
        width: 134px;
        height: 30px;
        background-color: $white;
        @include flexbox();
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $secondary-color-1;
        border: 1px solid $neutral-color-5;
        z-index: 9;
        .icon-add {
          font-size: 14px;
          margin-right: 5px;
          display: flex;
        }

        @media screen and (min-width: 992px) and (max-width: 1100px) {
          width: 100px;
          .icon-add {
            display: none;
          }
        }
        @media screen and (min-width: 1100px) and (max-width: 1200px) {
          width: 110px;
          .icon-add {
            display: none;
          }
        }

        @media screen and (max-width: $media-tablet-max) {
          display: none;
        }
        &[disabled] {
          pointer-events: none;
          color: $neutral-color-4;
          border-color: $neutral-color-4;
          cursor: not-allowed;
        }
        .si-addtxt {
          font-size: 12px;
          font-family: $medium_font-family;
          font-weight: $medium_font-weight;
          text-align: center;
          text-transform: uppercase;
        }

        .si-addicon {
          width: 20px;
          height: 20px;
          border: 1px solid $dark-yellow;
          border-radius: 50%;
          position: relative;
          margin-right: 5px;

          @media screen and (max-width: $media-desktop-max-1024) {
            width: 16px;
            height: 16px;
          }

          &:after {
            content: "\e90d";
            font-family: "AMS" !important;
            font-size: 10px;
            color: $dark-yellow;
            position: absolute;
            transform: rotate(134deg);
            left: 13px;
            right: 6px;

            @media screen and (max-width: $media-desktop-max-1024) {
              font-size: 8px;
              left: 9.4px;
            }
          }
        }
        &:focus {
          border: 1px solid $secondary-color-1;
          box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
        }
        &:hover {
          @media screen and (min-width: 769px) {
            border: 1px solid $secondary-color-1;
          }
        }
      }
    }

    .si-column {
      width: 14.28%;
      @extend %flexbox;
      align-items: center;
      justify-content: center;
    }
  }

  &Top {
    @include border-radius(10px 10px 0px 0px);
    overflow: hidden;
    padding-bottom: 2px;
    @media screen and (max-width: $media-tablet-max) {
      @include border-radius(0);
    }
    .si-calheader {
      background: #f5eefa;
      height: 65px;
      position: relative;
      @media screen and (max-width: $media-tablet-max) {
        background: transparent;
        box-shadow: none;
        padding-top: 0;
      }
    }
  }

  &Bottom {
    height: calc(100vh - 250px);
    background-color: $white;
    overflow: hidden;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
      z-index: 999;
      @media screen and (max-width: $media-tablet-max) {
        width: 0px;
      }
      @supports (-webkit-touch-callout: none) {
        width: 0px;
        display: none;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(#242424, 0.2);
      border-radius: 10px;
      z-index: 999;
      @supports (-webkit-touch-callout: none) {
        background: transparent;
      }
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(#242424, 0.2);
      z-index: 999;
    }

    @media screen and (max-width: $media-tablet-max) {
      padding-top: 0;
      margin-top: 31px;
      height: calc(100vh - 405px);
      background: transparent;
    }

    .scroll-pane {
      width: 100% !important;
      height: 100% !important;
    }

    .si-tableBox {
      @extend %flexbox;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.si-drp {
  width: 95.33px;
  height: 31px;
  background: $dropdown-color;
  border-radius: 35px;

  @media screen and (max-width: $media-tablet-max) {
    width: 100%;
  }
}

.si-mobileview {
  display: none;

  .si-progressbar {
    @media screen and (max-width: $media-tablet-max) {
      display: flex !important;
      width: 100% !important;
      align-items: center;
      padding-bottom: 40px !important;
      padding-top: 0px !important;

      .si-addtxt {
        display: none;
      }

      .si-percent {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: $media-tablet-max) {
    @include flexbox();
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 57px;
    z-index: 0;
    &:after {
      content: "";
      opacity: 0.6;
      border: 0.1px solid $neutral-color-4;
      width: 100%;
      height: 1;
      padding: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    .si-exerise {
      display: flex !important;
      width: 164px !important;
      height: 31px !important;
      background: $exercise-btn;
      border-radius: 70px;

      z-index: 1;

      .si-addtxt {
        font-size: 14px !important;
      }

      .si-addicon {
        width: 20px !important;
        height: 20px !important;

        &:after {
          font-size: 10px !important;
          left: 13px !important;
        }
      }
    }
  }
}

.si-publish {
  background: $publish-btn;
  border-radius: 55px;
  width: 150px;
  height: 40px;
  text-transform: uppercase;
  text-align: center;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: $white;
  position: absolute;
  bottom: 24px;
  right: 0;
  cursor: pointer;

  @media screen and (max-width: $media-tablet-max) {
    bottom: 74px;
    width: 136px;
    height: 25px;
    bottom: 80px;
    line-height: 25px;
    font-size: 13px;
  }

  @media screen and (max-width: $media-tablet-max) {
    width: 100%;
    height: 40px;
    line-height: 40px;
    bottom: 73px;
    font-size: 14px;
  }

  &.active {
    background: $solid-red;
  }
}

@media screen and (max-width: $media-tablet-max) {
  .si-calender {
    .si-weekcalender {
      .si-tblheader {
        .si-column {
          width: 99.72px;

          &:nth-of-type(7) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
.si-calheader {
  .rangeslider.rangeslider-horizontal {
    width: 82% !important;
  }
}

.disable-calender {
  background: rgba(200, 200, 200, 0.3) !important;
  display: inline-flex;
  padding: 2px 11px;
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  pointer-events: none;
  .icon-calender {
    margin-right: 10px;
  }
}
.clickable-card {
  &:hover {
    background-color: #f9f9f9 !important;
    cursor: pointer;
  }
}
@media screen and (max-width:992px){
  .rangeslider__handle {
    &::after{
      content: "";
      height: 46px;
      width: 50px;
      position: absolute;
      top: -100%;
      background-color: transparent;
    }
  }
}