.si-pagination {
    display: inline-block;
    position: relative;
    left: -8px;
    z-index: 1;
    bottom: -2px;
    @media screen and (max-width: $media-tablet-max) {
        width: 100%;
        justify-content: flex-start;
        left: 0;
        bottom: 10px;
    }
}

.si-page-count {
    color: $white;
    font-size: 14px;
}

.si-page-navigation {
    @include flexbox();
    margin-left: 15px;
    .icon-chev-left,
    .icon-chev-right {
        border: 1px solid $dark-yellow;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: 0.2s;

        &:before {
            color: $dark-yellow;
        }

        &.active,
        &:hover {
            background-color: $dark-yellow;

            &:before {
                color: $black;
            }
        }
    }

    button + button {
        margin-left: 15px;
    }

    .si-icon__button {
        &[disabled] {
            cursor: not-allowed;
            filter: grayscale(1);

            &:hover {
                background-color: transparent;
                &:before {
                    color: $dark-yellow;
                }
            }
        }
    }
}

.css-8nphli {
    padding-left: 2px;
}
.MuiTablePagination-root {
    .css-8nphli {
        min-height: 45px !important;
        padding-left: 8px !important;
        display: flex;
        align-items: center;
    }
    .MuiTablePagination-displayedRows {
        padding-top: 4px;
    }
}
