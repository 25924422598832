.si-button {
    @include border-radius(5px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: $bold_font-weight;
    color: $white;
    padding: 9px 34px;
    border: none;
    transition: 0.2s;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    span {
        margin-right: 8px;
        display: inline-flex;
    }

    &--red {
        background-color: $publish-btn;
        border: 1px solid #5d2626;

        &:hover,
        &.active {
            background-color: $solid-red;
        }
    }

    &--solid-red {
        background-color: $solid-red;
        border: 1px solid $solid-red;
    }

    &--transparent {
        background-color: transparent;
        border: 1px solid transparent;
    }

    &[disabled] {
        background-color: $neutral-color-5;
        border-color: $neutral-color-4;
        color: $neutral-color-4;
        cursor: not-allowed;

        &:hover {
            background-color: $neutral-color-5;
        }
    }

    &--green {
        background-color: $active-color-1;
        border: 1px solid $active-color-1;
        min-width: 140px;
        font-family: "Poppins";
        &:hover {
            @media screen and (min-width: $media-tablet-max) {
                background-color: darken($active-color-1, 8%);
            }
        }
        &.active {
            background-color: darken($active-color-1, 8%);
        }
    }

}

.si-popup-footer {
    .si-action-btn {
        font-weight: 700;
    }
}
.si-action-btn {
    @include border-radius(5px);
    color: $secondary-color-1;
    border: 1px solid $secondary-color-1;
    background-color: transparent;
    padding: 9px 16px;
    font-size: 14px;
    line-height: 1.2;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.2s;
    font-weight: 500;
    &.active,
    &:hover {
        background-color: $active-color-1;
        color: $white;
        border: 1px solid $active-color-1;
    }

    & + .si-action-btn {
        margin-left: 10px;
    }

    &[disabled] {
        color: $neutral-color-4;
        border-color: $neutral-color-4;

        &:hover {
            color: $neutral-color-4;
            border-color: $neutral-color-4;
            cursor: not-allowed;
        }
    }
}

.si-icon {
    &__button {
        @include flexbox();
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.si-wrap {
    position: relative;

    .si-action-icons {
        @include border-radius(5px);
        background-color: $action-div-bg;
        position: absolute;
        width: 90px;
        top: 2px;
        right: 55px;
        z-index: 1;
        display: none;

        @media screen and (max-width: $media-tablet-max) {
            top: initial;
        }
        @media screen and (min-width:570px) and (max-width:767px) {
            right: 65px;
        }
        @media screen and (min-width:768px) and (max-width:992px) {
            right: 85px;
        }
        @media screen and (min-width:992px)  {
            right: 55px;
        }
        // @media screen and (min-width: 1300px) {
        //     right: 40px;
        // }

        .si-icon-button {
            @include border-radius(5px);
            border: none;
            background-color: transparent;
            padding: 6px 10px;
            display: block;
            color: $white;
            font-family: $regular_font-family;
            width: 100%;
            font-size: 11px;
            text-transform: capitalize;
            text-align: left;
            transition: 0.2s;
            cursor: pointer;

            @media screen and (max-width: $media-tablet-max) {
                padding: 4px 10px;
            }

            &:hover {
                background-color: darken($color: $action-div-bg, $amount: 10);
            }

            span {
                padding: 0;
                margin-right: 5px;
            }
        }
    }

    .si-icon__button {
        &.active {
            & ~ .si-action-icons {
                display: block;
            }
        }
    }
}

.si-button-group {
    @include flexbox();
    @include border-radius(5px);
    background-color: $neutral-color-6;
    padding: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    button {
        display: inline-flex;
        justify-content: center;
        padding: 0 20px;

        & + button {
            border-left: 1px solid $secondary-color-1;
        }
    }
}

.btn-group-wrap {
    position: relative;

    &.active {
        .si-button-group {
            display: none;
        }
    }
}

.si-xs-fixed-button {
    display: none;
    position: fixed;
    bottom: 17px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 97%;
    @media screen and (max-width: 767px) {
        width: 95%;
    }
    @media screen and (min-width:768px) and (max-width:$media-tablet-max) {
        width: 97%;
    }
    
}

.si-viewBtn {
    height: 30px;
    border: 1px solid #f1f1f1;
    @include border-radius(5px);

    background: transparent;
    color: #787878;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;
    &:focus {
        border: 1px solid $secondary-color-1;
        box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
    }
    &:hover {
        @media screen and (min-width: 993px) {
            border: 1px solid $secondary-color-1;
        }
    }

    span {
        font-family: $medium_font-family;
        font-weight: 500;
        text-transform: uppercase;
        color: $secondary-color-1;
    }

    @media screen and (max-width: $media-tablet-max) {
        width: 100%;
        font-size: 0;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &::before {
        content: "\e921";
        color: $black;
        font-family: "AMS" !important;
        font-size: 12px;
        @media screen and (max-width: $media-tablet-max) {
            display: flex;
            justify-content: center;
            position: unset;
            left: 0;
            right: 0;
            align-items: center;
            font-size: 14px;
        }
    }

    @media screen and (max-width: $media-tablet-max) {
        span {
            display: none;
        }
    }
}

@media screen and (max-width: $media-tablet-max) {
    .si-button-group button {
        padding: 0 14px;
        .icon-check,
        .icon-close {
            font-size: 9px;
        }
    }

    .si-xs-fixed-button {
        display: block;
        bottom: 8px;
    }
}

// ----------new-------
.si-clearbtn {
    color: #000000;
    border: 1px solid $secondary-color-1;
    margin-right: 20px;
    min-width: 140px;
    background: $white;
}
.si-savebtn {
    min-width: 140px;
}

// ==hide btn
@media screen and (max-width: $media-tablet-max) {
    .si-subHeader {
        .si-icon--add {
            display: none;
        }
    }
}

.add-new-report {
    max-width: 173px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #ff5046;
    border-radius: 5px;
    color: #ff5046;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    white-space: nowrap;
}
.add-files {
    @extend .add-new-report;
    text-transform: uppercase;
    border-radius: 55px;
    padding-bottom: 6px;
}
.si-add-btn {
    @extend .add-new-report;
    display: flex !important;
    max-width: unset !important;
}
.export-btn {
    font-size: 12px;
    font-weight: 500;
    border: 1px solid $neutral-color-5;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(40, 40, 40);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: $white;
    &:focus,
    &:active,
    &:focus-within {
        border: 1px solid #282828;
        box-shadow: 0px 0px 2px 1px rgba(131, 173, 255, 0.5);
    }
    &:hover {
        border: 1px solid #282828;
    }

    .export-wrapper {
        display: flex;
        align-items: center;
        padding: 6px 8px;
        border-radius: 5px;
        span {
            &:first-child {
                display: block;
                img {
                    height: 16px;
                    width: 20px;
                    padding-right: 6px;
                    display: block;
                }
            }
        }
    }
    @media screen and (max-width: $media-tablet-max) {
        position: relative;
        left: 5px;
    }
}

.button-disabled {
    color: #c8c8c8 !important;
    background: rgba(200, 200, 200, 0.3) !important;
    border-color: #c8c8c8 !important;
    pointer-events: none;
    cursor: not-allowed;
}

.loading-wrapper {
    background: rgb(243, 241, 241);
    padding: 6px 8px;
    border-radius: 5px;
    height: 28px;
}
.loading-wrapper {
    display: none;
}

.loading {
    display: flex;
    align-items: center;
    gap: 4px;
    @supports (-webkit-touch-callout: none) {
        gap: unset;
    }
}

.colored-circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    @supports (-webkit-touch-callout: none) {
        margin-right: 4px;
    }
}

.colored-circle:first-child {
    animation: dot 0s infinite alternate;
    animation-delay: 0s;
    background-color: #e50914;
}

.colored-circle:nth-child(2) {
    animation: dot 1s infinite alternate;
    animation-delay: 1s;
    background-color: #221f1f;
}

.colored-circle:last-child {
    animation: dot 1s infinite alternate;
    animation-delay: 1.3s;
    margin-right: 8px;
    background-color: #c8c8c8;
}

.btn-text {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: #282828;
    text-transform: capitalize;
    font-family: "poppins";
}


.si-loading {
    .export-wrapper {
        display: none;
    }
    .loading-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 75.35px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.dropdown-heading {
    button {
        .dropdown-search-clear-icon {
            display: none;
        }
        &::before {
            content: "\e906";
            font-family: "AMS";
            font-size: 10px;
        }
    }
}
.si-dropdown-disable {
    border: 1px solid #c8c8c8;
    background-color: #eaeaea !important;
    pointer-events: none !important;
    height: 32px;
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    color: #c8c8c8;
    align-items: center;
    min-width: 130px;
    .icon-chev-right {
        font-size: 14px;
        color: #c8c8c8;
        padding-top: 2px;
    }
}
.si-clearbtn {
    color: #000000;
    border: 1px solid $secondary-color-1;
    margin-right: 20px;
    min-width: 140px;
    font-size: 14px;
    background: $white;
    font-family: $bold_font-family;
    font-weight: $bold_font-weight;
    height: 40px;
}
.clear-selected-button {
    color: #000000 !important;
}
.css-1p9edny:hover {
    background-color: unset !important;
}
button,
.si-button {
    font-family: "Poppins";
}
