@import "../mixins";
@import "../variables";
@import "../variables-en";

.si-dashboard {
  overflow-y: auto;
  height: 100%;
  position: relative;
  z-index: 0;
  @media screen and (max-width: $media-tablet-max) {
    height: calc(100% - 70px);
    height: -webkit-calc(100% - 70px);
  }
  &::-webkit-scrollbar {
    width: 3.68px;
    z-index: 999;
    height: 3px;
    @media screen and (max-width: $media-tablet-max) {
      width: 0px;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    z-index: 999;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(#242424, 0.2);
    border-radius: 10px;
    z-index: 999;
  }

  .si-version1,
  .si-version2 {
    .si-top {
      @include flexbox();
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: $media-tablet-max) {
        flex-flow: column;
      }

      .si-left,
      .si-right {
        width: 49%;
        flex: 0 0 49%;
        position: relative;

        @media screen and (max-width: $media-tablet-max) {
          width: 100%;
          flex: 0 0 100%;
          top: 0;

          display: flex;
          flex-flow: column;
          right: 0;
          left: 0;
        }
      }

      .si-right {
        .si-header {
          justify-content: flex-start;
        }

        .si-custom-table-container {
          max-height: initial;
          min-height: inherit;
          overflow: hidden;
          height: initial;
          @media screen and (max-width: $media-tablet-max) {
            overflow-x: auto;
          }
        }

        .si-custom-table {
          @media screen and (max-width: $media-tablet-max) {
            width: 100%;
          }
        }
      }

      .si-header {
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        width: 100%;
        margin-bottom: 6px;

        @media screen and (max-width: $media-tablet-max) {
          background: transparent;
        }
      }

      .si-title {
        color: $secondary-color-1;
        font-size: 20px;
      }

      .si-add {
        width: 90px;
        height: 30px;
        background: $white;
        border: 1px solid $primary-color-5;
        @include border-radius(5px);
        color: $primary-color-5;
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
      }

      .si-wrp {
        @include flexbox();
        background: $white;
        box-shadow: 0px 0px 2px rgba($black, 0.25);
        @include border-radius(5px);
        width: 100%;

        height: 60px;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:last-of-type {
          margin-bottom: 23px;
        }
      }

      .si-name {
        color: $secondary-color-1;
        font-size: 12px;
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
      }

      .si-datewrp {
        font-size: 12px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        color: $neutral-color-1;
      }

      .si-duration {
        color: $primary-color-5;
        font-size: 12px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        @include flexbox();
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        & span {
          display: block;
        }
      }
    }

    .si-view {
      color: $secondary-color-1;
      font-size: 10px;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      width: auto;
      float: right;
      cursor: pointer;
      position: relative;
      right: 0;
      bottom: 18px;
      text-align: right;
    }

    .si-subtitle {
      font-size: 16px;
      color: $neutral-color-1;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      padding-left: 11px;
      padding-top: 3px;
    }

    .si-bottom {
      margin-top: 1px;

      @media screen and (max-width: $media-tablet-max) {
        margin-top: 4px;
      }
      .si-title {
        font-weight: 500;
        font-size: 20px;
      }
      .si-left,
      .si-right {
        @include flexbox();
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;

        align-items: center;

        @media screen and (max-width: $media-tablet-max) {
          flex-flow: column;
        }

        .si-count1 {
          color: $primary-color-1;

          font-size: 40px;
          @media screen and (max-width: $media-tablet-max) {
            font-size: 64px;
          }
        }

        .si-txt {
          color: $secondary-color-1;
          font-size: 14px;
          padding-left: 15px;
          line-height: 18px;
          @media screen and (max-width: $media-tablet-max) {
            font-size: 18px;
            padding-left: 0;
          }

          & span {
            display: block;

            @media screen and (max-width: $media-tablet-max) {
              display: inline-block;
            }
          }
        }
      }

      .si-right {
        position: relative;
        .si-desk {
          display: flex;
          align-items: center;

          @media screen and (max-width: $media-tablet-max) {
            display: none;
          }
        }

        .si-mobile {
          display: none;

          @media screen and (max-width: $media-tablet-max) {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: -111px;

            right: -90px;
            left: initial;

            & img {
              height: 180px;
            }

            .si-txt {
              margin-top: -29px;
              @media screen and (max-width: $media-mobile-max-520) {
                margin-top: -10px;
              }
              @media screen and (max-width: $media-mobile-med) {
                width: 124px;
                margin-left: 40px;
              }
            }
          }
          @media screen and (max-width: $media-mobile-small-max-380) {
            right: -79px;
          }
        }
      }

      .si-header {
        justify-content: flex-start;

        @media screen and (max-width: $media-tablet-max) {
          background-color: transparent;
        }
      }

      .si-subheaderwrp {
        @include flexbox();
        position: relative;
        width: 50%;
        margin-top: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: $media-tablet-max) {
          width: 100%;
        }

        .si-left,
        .si-right {
          width: 50%;
        }

        .si-right {
          padding-left: 25px;
        }

        .si-left {
          border-right: 1px solid rgba($black, 0.3);
          @media screen and (max-width: $media-tablet-max) {
            border-right: 0;
            align-items: baseline;
          }
        }
      }

      .si-custom-table-container {
        max-height: initial;
        min-height: inherit;
        overflow: hidden;
        height: auto;
        overflow-x: scroll;
      }
    }
  }
}

.si-version2 {
  .si-image {
    margin-left: 10px;
  }

  .si-subtitle {
    color: $black;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    font-size: 14px;
    padding-left: 0px;
  }

  .si-label {
    color: $secondary-color-1;
  }

  .si-header {
    width: auto !important;
    display: block;
    justify-content: flex-start !important;
  }

  .si-left {
    justify-content: flex-start;
    display: flex;
    flex-flow: column;
  }
}

.si-table {
  width: 100%;

  .si-custom-table {
    width: 100%;

    .si-custom-table-container {
      max-height: calc(100vh - 418px);
      min-height: calc(100vh - 418px);
    }
  }
}

.usermanagement {
  .si-row {
    margin: 0 -15px;
  }

  @media only screen and (min-width: $media-tablet-max) {
    .si-row {
      @include flexbox();
      flex-wrap: wrap;
    }
  }

  .si-col {
    flex-basis: 33.33%;

    padding: 0 15px;
  }

  .si-calender {
    @include border-radius(5px);
    background-color: $white;
    box-shadow: 0 0 1px rgba($black, 0.3);
    padding: 5px;
    border: none;

    @media screen and (max-width: $media-tablet-max) {
      height: initial;
    }

    & + .si-calender {
      margin-top: 15px;
    }

    .rangeSlider.rangeSlider__horizontal {
      width: 100%;
    }

    .si-progressbar {
      position: relative;
    }

    .si-exercise {
      font-size: 14px;
      font-weight: 500;
    }

    .si-Controlled {
      font-size: 12px;
    }
  }

  .si-progress-svg {
    img {
      width: 100%;
    }
  }
}

// ----new-------
.si-title {
  font-weight: 500;
  line-height: 30px;
}
.si-subtitle {
  font-size: 14px;
  line-height: 21px;
}
.si-exerise {
  font-size: 14px;
  font-weight: 500;
}

// ==========
.si-dashboard {
  height: calc(100vh - 70px);
  overflow: scroll;
  @media screen and (min-width: 993px) and (max-width:1199px) {
    height: calc(100dvh - 70px);
  }
}

// ==========new-dashboard========
.si-dashboard-new {
  background: #eee;
  height: calc(100vh - 60px);
  padding-top: 22px;
  overflow-x: scroll;
  margin-top: 0px !important;
  padding-bottom: 15px;
  &::-webkit-scrollbar {
    width: 3.68px;
    z-index: 999;
    @media screen and (max-width: $media-tablet-max) {
      width: 0px;
    }
  }
  @media screen and (min-width: 993px) and (max-width:1199px) {
    height: calc(100dvh - 60px);
  }
  @media screen and (max-width: $media-tablet-max) {
    height: calc(100dvh - 110px);
    margin-top: 0px !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .si-quotes-container {
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px 20px;
    margin-bottom: 23px;

    .si-quotes {
      color: $primary-color-1;
      font-size: 16px;
      font-weight: 400;
      position: relative;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
        font-weight: 500;
      }
      .dbl-comma:first-child {
        position: absolute;
        left: -8px;
      }
    }
    .si-writer {
      color: $neutral-color-4;
      white-space: nowrap;
      display: flex;
      justify-content: end;
      font-size: 16px;
      @media screen and (max-width: $media-tablet-max) {
        padding-top: 20px;
        font-size: 14px;
      }
    }
  }
  .si-upcoming-heading {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #282828;
    margin-bottom: 8px;
    @media screen and (max-width: $media-tablet-max) {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .si-upcoming-wrapper {
    display: flex;
    align-items: center;
    padding-right: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 22px;
    color: #ffffff;
    background-color: #090c6a;
    .si-date-wrap {
      white-space: nowrap;
      .si-upcoming-date {
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        text-align: right;
        background-color: #e10000;
        padding: 5px 18px;
        border-top-left-radius: 5px;
        .specific-date {
          font-size: 20px;
          margin-right: 5px;
        }
      }
      .si-upcoming-daysleft {
        background-color: #ff5046;
        font-size: 12px;
        padding: 5px 18px;
        border-bottom-left-radius: 5px;
      }
    }
    .si-status-wrap {
      padding-left: 30px;
      .si-upcoming-status {
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  .si-dailygoal-heading {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #2b2a48;
    margin-bottom: 9px;
    @media screen and (max-width: $media-tablet-max) {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .si-card-wrapper {
    display: flex;
    gap: 13px;
    flex-wrap: wrap;

    .si-card {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 49.3%;
      padding: 10px 10px 25px 10px;
      border-radius: 5px;
      @media screen and (min-width: $media-desktop-max-1024) and (max-width: $media-desktop-small-max) {
        width: 49%;
      }
      @media screen and (max-width: $media-tablet-max) {
        width: 100%;
      }
      &-title {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 100%;
        color: $neutral-color-1;
        margin-bottom: 10px;
      }
      &-subtitle {
        font-size: 14px;
        line-height: 21px;
        color: #090c6a;
        margin-bottom: 15px;
        font-weight: 600;
      }
      &-subtitle-emptyCard {
        color: #282828 !important;
        font-weight: 400 !important;
      }
      &-maincontent {
        display: flex;
        align-items: center;

        .si-card-percentage {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          span {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            color: #282828;
            display: block;
          }
        }
        .si-per-left {
          text-align: end;
          margin-right: 15px;
          @media screen and (max-width: $media-tablet-max) {
            margin-right: 10px;
          }
        }
        .si-per-right {
          margin-left: 15px;
          margin-left: 10px;
        }
      }
      .si-like-img {
        margin-bottom: 10px;
        margin-top: 5px;
      }
      .si-cardscore {
        color: $active-color-2;
        font-size: 22px;
        margin-bottom: 10px;
        .si-obtainscore {
          font-size: 26px;
          font-weight: 500;
        }
        .si-totalscore {
        }
      }
      .si-task-status {
        font-size: 20px;
        color: $secondary-color-1;
        margin-bottom: 16px;
        text-transform: capitalize;
        @media screen and (max-width: 1199px) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .si-task-feedback {
        font-size: 23px;
        font-weight: 700;
        text-transform: uppercase;
        color: #ebebf5;
        background-color: #353196;
        padding: 1px 45px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        @media screen and (max-width: 1199px) {
          font-size: 14px;
          padding: 1px 10px;
        }
      }
      .msg-for-nodata {
        font-size: 14px;
        font-weight: 400;
        color: $secondary-color-1;
        max-width: 434px;
        padding: 15px;
        text-align: center;
      }
    }
  }
}

// --------coach-dashboard-----
.si-coach-dashboard {
  .si-coach-nodata-container,
  .si-upcoming-heading {
    display: none;
  }
  .si-upcoming-outer-wrapper {
    display: none;
    gap: 13px;
    margin-bottom: 8px;
    @media screen and (max-width: $media-laptop-max-1280) {
      overflow-x: scroll;
    }
    @media screen and (max-width: $media-tablet-max) {
      margin-bottom: 22px;
    }
    .si-upcoming-wrapper {
      min-width: 354px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border: 1px solid #b4b4b4;
      color: #000000;
      margin-bottom: 15px;
      .si-date-wrap {
        border-right: 1px solid #b4b4b4;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .si-upcoming-date {
          background: #ffffff;
          border-top-right-radius: 5px;
          text-transform: capitalize;
        }
      }
      .si-status-wrap {
        padding-left: 15px;
      }
    }
  }
  .si-upcoming-date {
    text-align: center !important;
    line-height: 17px !important;
  }
  .si-upcoming-daysleft {
    background-color: #ffcbc7 !important;
    border-radius: 4px;
  }
  .si-chart-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
    &__title {
      font-size: 18px;
      font-weight: 400;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .custom-select__control {
      width: 220px;
    }
  }
  .si-chart-wrapper {
    background: linear-gradient(rgb(9, 12, 106) 0%, rgba(9, 12, 106, 0.52) 100%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px;
    border-radius: 5px;
    img {
      width: 100.3%;
    }
    margin-bottom: 23px;
    @media screen and (max-width: $media-tablet-max) {
      margin-bottom: 25px;
    }
    canvas {
      margin: 10px !important;
    }
  }
  .rpe-date {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 8px;
    @media screen and (max-width: $media-tablet-max) {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .performance-level-list {
    display: flex;
    gap: 24px;
    @media screen and (max-width: $media-laptop-max-1280) {
      overflow-x: scroll;
    }
    @media screen and (max-width: $media-tablet-max) {
      gap: 7px;
    }
    &__item {
      padding-bottom: 12px;
      position: relative;
      min-width: 160px;
      @media screen and (max-width: $media-tablet-max) {
        min-width: 110px;
      }
      .performance-level {
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9px;
        padding: 3px 20px;
        border-radius: 5px;
        cursor: pointer;
        background-color: $white;
        @media screen and (max-width: $media-tablet-max) {
          gap: 5px;
          padding: 3px 1px;
          font-size: 11px;
        }
        &__num {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          background-color: #efefef;
          color: #fa7537;
          border: none !important;
          @media screen and (max-width: $media-tablet-max) {
            height: 24px;
            width: 24px;
          }
        }
      }
     
      .max-effort {
        border: 1px solid #f43426;
        color: #f43426;
      }
      .very-hard {
        border: 1px solid #fa7537;
        color: #fa7537;
      }
      .vigorous {
        border: 1px solid #e0a43a;
        color: #e0a43a;
      }
      .moderate {
        border: 1px solid #52b504;
        color: #52b504;
      }
      .light {
        border: 1px solid #00c6c4;
        color: #00c6c4;
      }
      .very-light {
        border: 1px solid #388df9;
        color: #388df9;
      }
    }
  }
  .no-player-active {
    .players-list {
      display: none;
    }
    .no-player {
      background: white;
      display: flex;
      justify-content: center;
      padding: 40px;
    }
  }

  .players-list {
    padding: 17px 15px 15px 15px;
    background-color: #ffffff;
    display: flex;
    gap: 15px;
    @media screen and (max-width: $media-laptop-max-1280) {
      overflow-x: scroll;
    }
    .player-card {
      background-image: url("../images/card-bg-img.svg");
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 20px 20px 20px;
      &__img {
        width: 60px;
        margin-bottom: 8px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      &__name {
        font-size: 9px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
  .no-player {
    display: none;
  }
  .si-quotes-container {
    display: flex;
  }
  .upcoming-competition-active {
    .si-upcoming-heading,
    .si-upcoming-outer-wrapper,
    .si-upcoming-heading {
      display: flex;
    }
    .si-quotes-container {
      display: none;
    }
  }
}
.si-coach-dashboard-nodata-active {
  .si-coach-data-container {
    display: none;
  }
  .si-coach-nodata-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 35px 20px;
    background: $white;
    border-radius: 5px;
    .nodata-img {
      width: 309px;
      height: 298px;
      margin-bottom: 24px;
      @media screen and (max-width: $media-tablet-max) {
        width: 215px;
        height: 207px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nodata-para {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 12px;
      text-align: center;
    }
    .si-btn-wrp {
      width: 300px;
      button {
        width: 100%;
      }
    }
  }
}

.performance-level-list .active {
  border-bottom: 3px solid #f43426;
  .performance-level {
    background-color: #f43426;
    border: 1px solid #f43426;
    &__num {
      color: #f43426 !important;
    }

    &__text {
      color: white;
    }
  }
  .level-indicator::before {
    content: "";
    width: 10px;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #e10000;
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.si-upcoming-wrapper-active {
  background: linear-gradient(180deg, #090c6a 46.19%, #7273a6 100%) !important;
  color: #ffffff !important;
  .si-date-wrap {
    border-right: none !important;
    .si-upcoming-date {
      background: #e10000 !important;
    }
    .si-upcoming-daysleft {
      background: #ff5046 !important;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
}
// .si-upcoming-outer-wrapper

// -----dashboard-progress-bar-----

.circle {
  position: relative;
}

.circle .text {
  position: absolute;
  width: 100%;
  top: 75px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  line-height: 22px;
  @media screen and (max-width: $media-tablet-max) {
    top: 56px;
  }
}
.obtain {
  color: #00b137;
  font-size: 50px;
  @media screen and (max-width: $media-tablet-max) {
    font-size: 40px;
  }
}
.slash {
  color: #6e6e6e;
  font-size: 30px;
  @media screen and (max-width: $media-tablet-max) {
    font-size: 20px;
  }
}
.total {
  color: #6e6e6e;
  font-size: 30px;
  @media screen and (max-width: $media-tablet-max) {
    font-size: 20px;
  }
}
.progress-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 5px;
  @media screen and (max-width: $media-tablet-max) {
    font-size: 14px;
  }
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #00b137 !important;
}
.CircularProgressbar-wrp {
  height: 230px;
  width: 230px;
  margin: auto;
  @media screen and (min-width: $media-desktop-max-1024) and (max-width: $media-desktop-small-max) {
    width: 210px;
  }
  @media screen and (max-width: $media-tablet-max) {
    height: 162px;
    width: 173px;
  }
}
