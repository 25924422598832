// information

.information-main {
    display: flex;
    gap: 20px;
    padding-bottom: 15px;
    height: calc(100dvh - 115px);
    @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 115px);
      }
    @media screen and (max-width: 992px) {
        height: calc(100dvh - 174px);
    }
    @media screen and (max-width: $media-tablet-max) {
        flex-direction: column;
        margin: 6px -15px;
        background: white;
        .details-active + .atheletes-details-content {
            height: calc(100dvh - 300px);
            overflow: scroll;
        }
    }
    .injury-athelet-profile {
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        .injury-athelet-profile-img {
            height: 35px;
            width: 35px;
            background-color: $neutral-color-5;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
                width: 18px;
            }
        }
        &-name {
            font-size: 18px;
            font-weight: 600;
            color: #282828;
            text-transform: capitalize;
        }
    }
    .si-subheader {
        font-weight: 700;
        font-size: 14px;
        color: $secondary-color-1;
        background: #e7e0ec;
        border-radius: 5px;
        padding: 7px 15px;
        position: sticky;
        z-index: 1;
        top: 0;
        .icon-chev-right {
            display: none;
        }
        @media screen and (max-width: $media-tablet-max) {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .si-subheader__text {
                color: $neutral-color-2;
            }
            span {
                display: inline-block;
            }
            .icon-chev-right {
                display: block;
                transform: rotate(0deg);
                transition: transform 0.3s ease-in-out;
            }
        }
    }
    &__left {
        width: 38%;
        position: relative;
        background: $white;
        box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        @media screen and (max-width: $media-tablet-max) {
            width: unset;
            box-shadow: unset;
        }
        .atheletes-details {
            background: $white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            flex-direction: column;
        }
        .Anthropometric-Information {
            .si-subheader {
                background: #f1f1f1;
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                padding: 8px 10px 7px 10px;
                margin-left: 15px;
                margin-right: 15px;
                align-items: baseline;
                color: $neutral-color-2;
                text-transform: capitalize;
                .icon-edit {
                    color: $neutral-color-2;
                    cursor: pointer;
                }
            }
            &__content {
                padding: 13px 15px 20px 15px;
                margin-bottom: 20px;
                @media screen and (max-width: $media-tablet-min) {
                    padding-bottom: 0;
                }
                .content-row {
                    margin-bottom: 8px;
                    line-height: 18px;
                    .si-title {
                        font-size: 12px;
                        font-weight: 500px;
                        color: $secondary-color-1;
                        text-transform: capitalize;
                        line-height: unset;
                        margin-right: 3px;
                        @media screen and (max-width: $media-tablet-max) {
                            font-size: 14px;
                        }
                    }
                    .text {
                        font-size: 12px;
                        font-weight: 400;
                        color: $neutral-color-2;
                        @media screen and (max-width: $media-tablet-max) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    &__right {
        width: calc(100% - 38%);
        position: relative;
        background: $white;
        border-radius: 5px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: $media-tablet-max) {
            width: unset;
        }
        .table-body__wrapper {
            padding: 15px;
            thead {
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            .report-table-header {
                background-color: $neutral-color-6;
                font-size: 12px;
                font-weight: 600;
                color: $secondary-color-1;
                border-radius: 5px;
                @media screen and (max-width: $media-tablet-min) {
                    font-size: 14px;
                }
                th {
                    padding: 8px 10px 7px 10px;
                    text-align: center;
                    padding-left: 15px;
                    &:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        text-align: start;
                        padding-left: 10px;
                    }
                    &:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        text-align: center;
                        padding-right: 0;
                    }
                }
            }
            .report-table-body {
                font-size: 12px;
                font-weight: 400;
                color: $secondary-color-1;
                max-height: calc(100vh - 215px);
                overflow-y: scroll;
                display: block;
                @media screen and (min-width: 993px) and (max-width:1199px) {
                    height: calc(100dvh - 215px);
                  }
                @media screen and (max-width: $media-tablet-min) {
                    max-height: calc(100dvh - 345px);
                    font-size: 14px;
                }
                &::-webkit-scrollbar {
                    width: 3px;
                    z-index: 999;
                    @media screen and (max-width: $media-tablet-max) {
                        width: 1px;
                    }
                }

                &::-webkit-scrollbar-track {
                    z-index: 999;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgba(#242424, 0.2);
                    border-radius: 10px;
                    z-index: 999;
                }
                tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }

                td {
                    padding: 10px;
                    // padding-left: 0px;
                    text-align: center;
                    padding-left: 15px;
                    font-weight: 500;
                    &:last-child {
                        text-align: center;
                        padding-right: 0;
                    }
                    &:first-child {
                        text-align: start;
                        padding-left: 10px;
                        font-weight: 400;
                    }
                }
                .si-viewBtn {
                    @media screen and (max-width: $media-tablet-max) {
                        border: none;
                    }
                    &::before {
                        @media screen and (max-width: $media-tablet-max) {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.atheletes-details-content {
    @media screen and (max-width: $media-tablet-max) {
        height: 0;
        overflow: hidden;
    }
}
.report-details-content {
    @media screen and (max-width: $media-tablet-max) {
        height: 0;
        overflow: hidden;
    }
}

.details-active,
.report-active {
    .si-subheader__text {
        color: $secondary-color-2 !important;
    }
    .icon-chev-right {
        transform: rotate(90deg) !important;
        transition: transform 0.3s ease-in-out;
    }
}
.details-active + .Anthropometric-Information-content {
    @media screen and (max-width: $media-tablet-min) {
        height: calc(100vh - 225px);
        overflow: scroll;
    }
    &::-webkit-scrollbar {
        width: 3px;
        z-index: 999;

        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
    }

    &::-webkit-scrollbar-track {
        z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }
}
.report-active + .report-details-content {
    height: unset !important;

    @media screen and (max-width: $media-tablet-max) {
        .reportlog-content {
            height: calc(100vh - 343px);
            overflow: scroll;
        }
    }
}

// =====groupNutrition-plan-main========
.groupNutrition-plan-main {
    height: calc(100vh - 115px);
    overflow-y: scroll;
    padding-left: 15px;
    margin-left: -15px;
    padding-right: 15px;
    margin-right: -15px;
    @media screen and (min-width: 993px) and (max-width:1199px) {
        height: calc(100dvh - 115px);
      }
    @media screen and (max-width: $media-tablet-max) {
        height: calc(100dvh - 170px);
    }
    &::-webkit-scrollbar {
        width: 3px;
        z-index: 999;

        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
    }

    &::-webkit-scrollbar-track {
        z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(#242424, 0.2);
        z-index: 999;
    }
    .groupNutrition-plan-wrp {
        margin-bottom: 15px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }
    .groupNutrition-heading-wrp {
        display: flex;
        margin-bottom: 10px;
        .groupNutrition-heading--text {
            font-size: 16px;
            font-weight: 600;
            color: $secondary-color-1;
        }
        .group-num {
            font-size: 16px;
            font-weight: 600;
            color: $neutral-color-1;
        }
    }
    .groupNutrition-content {
        border-radius: 5px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
        .icon-calender:before {
            color: $neutral-color-2;
        }

        &--title {
            font-size: 14px;
            font-weight: 700;
            color: $secondary-color-2;
            background: #e7e0ec;
            border-radius: 5px;
            padding: 8px 15px 7px 15px;
            .additional-text {
                font-size: 12px;
                font-weight: 400;
                color: $neutral-color-2;
                margin-left: 5px;
            }
        }
    }
    .groupNutrition-innercontent {
        padding: 10px 15px 15px 15px;
        .si-label {
            font-size: 12px !important;
            @media screen and (max-width: $media-tablet-max) {
                font-size: 12px !important;
            }
        }
        .date-name-wrp {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            .writer-name {
                font-size: 12px;
                font-weight: 500;
                color: $neutral-color-2;
            }
        }
        .groupNutrition-textarea {
            font-size: 12px;
            font-weight: 400;
            color: $neutral-color-2;
            padding: 10px;
        }
    }
    .date-form-control {
        position: relative;
        width: 130px;
    }
    .nodata-active {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .nodata-text {
            font-size: 14px;
            font-weight: 400;
            color: #282828;
        }
    }
}
