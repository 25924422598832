.flex-1 {
    flex: 1;
}

.text-capitalize {
    text-transform: capitalize;
}

.color-primary-1 {
    color: $primary-color-1;
}

.color-active-1 {
    color: $active-color-1;
}

.color-red {
    color: $primary-color-5;
}

@media only screen and (max-width: $media-tablet-max) {
    .d-sm-none {
        display: none !important;
    }
}