@import "../mixins";
@import "../variables";
@import "../variables-en";

.si-rightPart {
  margin-left: 243px;
  position: relative;
  background-color: $neutral-color-7;
  @media screen and (max-width: 993px) {
    margin-left: 0px;
  }

  .si-headwrp {
    @include flexbox();
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 15px;
    padding-right: 10px;
    background-color: $white;

    box-shadow: 0px 4px 4px rgba($black, 0.05);

    @media screen and (max-width: $media-tablet-max) {
      padding-bottom: 0;
      height: 50px;
      padding-top: 0;
      padding-left: 10px;
    }

    .si-week {
      @media screen and (max-width: $media-tablet-max) {
        position: relative;

        margin-left: -22%;

        margin-top: 0;
        font-size: 13px !important;
      }

      &__text {
        color: $white;
        font-size: 14px;
        text-transform: capitalize;

        margin-left: 5px;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $white;
        opacity: 1;
        /* Firefox */
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        font-size: 12px;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $white;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        font-size: 12px;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $white;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        font-size: 12px;
      }
    }

    .icon-back {
      width: 30px;
      height: 30px;
      font-size: 18px;
      justify-content: flex-start;
      color: #0d0d0d;
      @media screen and (max-width: $media-mobile-max-580) {
        height: 30px;
      }
    }
  }

  .si-headcover {
    @include flexbox();
    align-items: center;
    position: relative;
    width: 100%;

    @media screen and (max-width: $media-tablet-max) {
      flex-flow: column;
    }

    .si-Disable,
    .si-Disabled {
      @include flexbox();
      align-items: center;
      width: 100%;

      .si-wrp {
        @include flexbox();
        align-items: center;

        @media screen and (max-width: $media-tablet-max) {
          position: absolute;
          top: 38px;
          left: 0;
          width: 214px;
          align-items: flex-start;
        }
      }
    }

    .si-Disable {
      margin-right: 20px;

      @media screen and (max-width: $media-tablet-max) {
        margin-right: 10px;
      }

      @media screen and (max-width: $media-tablet-max) {
        margin-right: 0;
      }

      @media screen and (max-width: $media-tablet-max) {
        .si-button-group {
          left: -68px !important;
          top: -2px;
        }
        .form-control__title {
          position: relative;
          bottom: 2px;
        }
      }

      .form-control {
        &.si-error {
          input {
            border: 1px solid $primary-color-5;
          }

          .si-error-message {
            display: flex;

            @media screen and (max-width: $media-tablet-max) {
              font-size: 9px;
            }
          }
        }

        .si-error-message {
          display: none;
        }
      }

      .si-wrp {
        padding: 0 10px;

        &.si-weekwrp {
          margin-top: 1px;
        }

        @media screen and (max-width: $media-tablet-max) {
          padding: 0;

          .form-control {
            &.si-weekwrp {
              margin-left: 0px !important;
              margin-top: 3px;
            }

            &.si-sportsdrp {
              width: 196px;
              padding-left: 0;
              margin-top: 3px;
            }
          }
        }
      }
    }

    .si-Disabled {
      align-items: baseline;

      .form-control {
        @media screen and (max-width: $media-tablet-max) {
          @include flexbox();
          justify-content: flex-end;
          position: absolute;
          right: 0;

          .si-button-group {
            left: -7px !important;
          }
        }
      }

      .si-Title {
        color: $secondary-color-1;
        font-size: 22px;
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;
        padding-right: 15px;
        width: auto !important;

        @media screen and (max-width: $media-tablet-max) {
          width: 100%;
          padding-right: 0;
          font-size: 18px;
        }

        @media screen and (max-width: $media-mobile-max-580) {
          font-size: 18px;
          padding-top: 1px;
        }
      }

      .si-weeks,
      .si-sports {
        color: $secondary-color-1;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        font-size: 14px;
        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }

        & span {
          padding-right: 5px;
        }
      }

      .si-weeks {
        padding-right: 20px;
        position: relative;

        &:after {
          content: "";
          border: 0.1px solid $neutral-color-4;
          position: absolute;
          right: 9px;
          height: 100%;
        }
      }

      .si-sports {
        padding-right: 15px;
      }
    }

    .si-heading {
      width: 100%;
      flex: 1;
      position: relative;

      @media screen and (max-width: $media-tablet-max) {
        font-size: 20px;
      }
    }
    .icon-edit {
      @media screen and (max-width: $media-tablet-max) {
        position: absolute;
        top: -9px;
      }
    }
  }

  .si-align-center {
    position: fixed;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);
    @include flexbox();
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $media-tablet-max) {
      left: 50%;
    }
  }

  .si-firstStep {
    .si-text {
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      color: $secondary-color-1;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .si-button {
      width: 300px;
      height: 36px;
      font-size: 14px;
    }
  }

  .si-secondStep {
    width: 29%;
    .si-error .si-error-message {
      position: absolute;
    }
    .custom-select__control {
      @media screen and (max-width: $media-tablet-max) {
        font-size: 14px !important;
      }
    }
    .form-group {
      .si-input {
        padding: 5px 10px !important;
        @media screen and (max-width: $media-tablet-max) {
          font-size: 14px !important;
        }
      }
      .si-label {
        @media screen and (max-width: $media-tablet-max) {
          font-size: 14px !important;
        }
      }
      .si-label-append {
        @media screen and (max-width: $media-tablet-max) {
          font-size: 14px !important;
        }
      }
    }

    .si-form {
      width: 100%;

      .form-control {
        margin-bottom: 20px;
        margin-top: 12px;

        &:last-child {
          margin-top: 73px;
        }

        &.si-error {
          input,
          select {
            border: 1px solid $red;
          }

          .custom-select__control {
            border-color: $red !important;
          }

          .si-error-message {
            @include flexbox();
          }
        }
      }
    }

    .si-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: $media-tablet-max) {
  .si-rightPart {
    .si-firstStep {
      width: 90%;
      top: 43%;

      .si-text {
        font-size: 14px;
      }
    }

    .si-secondStep {
      width: 90%;
      top: 49%;
      height: 100%;

      .si-form {
        height: 62%;
      }

      .form-control {
        &:nth-of-type(4) {
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
.btn-group-wrap {
  .icon-check {
    color: #00b137;
    font-size: 12px;
  }
  .icon-close {
    font-size: 12px;
    color: #e10000;
  }
}
.si-headwrp {
  .si-headcover {
    .si-heading {
      .si-Disable {
        .si-wrp {
          .custom-single-select {
            width: 130px;
          }
        }
      }
    }
  }
}
