// performance-tracker
.si-main-tournament {
    margin-top: -5px;
    .si-button {
        @media screen and (max-width: $media-tablet-max) {
            display:flex;
        }
    }
    .tournament-subheader {
        background: $white;
        box-shadow: $box-shadow-lightblack;
        border-radius: 5px;
        padding: 5px 15px;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 10px;
        color: #282828;
        font-size: 14px;
        overflow-x: scroll;
        white-space: nowrap;
        @media screen and (max-width: $media-tablet-max) {
            margin-left: -15px;
            margin-right: -15px;
            gap: 50px;
        }
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

        &__date,
        &__location {
            display: flex;
            align-items: center;
            .date-icon,
            .location-icon {
                margin-right: 10px;
                display: flex;
                img {
                    width: 20px;
                }
            }
            .location-icon {
                img {
                    width: 15px;
                    padding-bottom: 4px;
                }
            }
            .date-text,
            .location-text {
                font-weight: 600;
                margin-right: 7px;
                @media screen and (max-width: $media-tablet-max) {
                    display: none;
                }
            }
            .date-digit,
            .location-name {
                font-weight: 500;
            }
        }
    }
    .tournament-body {
        display: flex;
        background: $white;
        gap: 20px;
        height: calc(100vh - 120px);
        @media screen and (min-width: 993px) and (max-width:1199px) {
            height: calc(100dvh - 120px);
          }
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100dvh - 190px);
        }
        &__left {
            background: $white;
            box-shadow: $box-shadow-lightblack;
            border-radius: 5px;
            width: 355px;
            position: relative;
            height: calc(100dvh - 190px);
            @media screen and (min-width: 993px) and (max-width:1199px) {
                height: calc(100dvh - 190px);
              }
            @media screen and (max-width: $media-tablet-max) {
                height: calc(100dvh - 260px);
                width: 100%;
                box-shadow: unset;
            }
            &-header {
                display: flex;
                gap: 10px;
                padding: 10px;
                .si-button {
                    text-transform: uppercase;
                    padding: 4px 23px;
                    font-size: 14px;
                    @media screen and (max-width: $media-tablet-max) {
                        height: 30px;
                    }
                }

                .si-select {
                    border: none;
                    font-size: 16px;
                    font-weight: 600;
                    color: $secondary-color-1;
                    text-transform: capitalize;
                    width: 175px;
                }
                .si-selected {
                    @extend .si-select;
                    background: none;
                    display: flex;
                    align-items: center;
                }
            }
            &-body {
                .left-tournament-name {
                    min-height: 40px;
                    border-radius: 5px;
                    font-size: 14px;
                    color: $secondary-color-1;
                    font-weight: 600;
                    background: $white;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    display: flex;
                    align-items: center;
                    padding: 2px 10px 2px 15px;
                    cursor: pointer;
                }
                .active-tournament {
                    border-left: 5px solid $primary-color-1;
                    background: $primary-color-6;
                    padding: 2px 10px 2px 10px;
                    @media screen and (max-width: $media-tablet-max) {
                        background-color: unset;
                        border-left: unset;
                    }
                }
            }
        }
        .empty-contain-wrapper {
            font-size: 14px;
            font-weight: 400;
            color: $secondary-color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                display: inline-block;
                max-width: 274px;
                text-align: center;
                opacity: 0.5;
            }
            @media screen and (max-width: $media-tablet-max) {
                position: absolute;
                background: $white;
                width: 94%;
                padding-right: 10px;
                height: 90%;
            }
        }
        .tournament-empty-container {
            flex-direction: column;
        }
        .empty-contain-wrapper-left {
            @extend .empty-contain-wrapper;
            span {
                display: block !important;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }
        &__right {
            width: calc(100vw - 613px);
            position: relative;
            .tournament-empty-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            @media screen and (min-width: 993px) and (max-width: $media-desktop-max-1024) {
                width: calc(100vw - 640px);
            }
            @media screen and (min-width: $media-tablet-max) {
                padding-right: 15px;
            }
            .si-action-icons {
                top: 4px;
                right: 25px;
                @media screen and (max-width: $media-tablet-max) {
                    top: 20px;
                }
            }
            @media screen and (max-width: $media-tablet-max) {
                position: fixed;
                background: $white;
                width: 90%;
                padding-right: 10px;
                top: 140%;
                transition: top 0.4s linear;
            }
            &-header {
                display: flex;
                justify-content: space-between;
                padding: 13px 0px 8px 0;
                align-items: center;
                .add-action-btn-wrp {
                    height: 15px;
                    .add-new-report {
                        display: none;
                    }
                }
                @media screen and (max-width: $media-tablet-max) {
                    align-items: baseline;
                    .si-custom-table__cell {
                        position: absolute;
                        top: -1px;
                        right: 10px;
                        .si-icon__button {
                            border: none;
                            position: absolute;
                            top: 14px;
                            right: 0;
                        }
                    }
                }
                .header-inner-wrp {
                    .icon-close {
                        display: none;
                        font-size: 17px;
                        padding-right: 4px;
                        cursor: pointer;
                    }
                    @media screen and (max-width: $media-tablet-max) {
                        display: flex;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .right-heading {
                    font-size: 16px;
                    font-weight: 700;
                    color: $secondary-color-2;
                }
                .si-button {
                    text-transform: uppercase;
                    padding: 4px 15px;
                    font-size: 14px;
                    @media screen and (max-width: $media-tablet-max) {
                        max-width: 100%;
                        height: 34px;
                        margin-top: 10px;
                    }
                }
                .si-wrap .si-action-icons {
                    z-index: 12;
                }
            }
            &-subheader {
                display: flex;
                margin: 10px 0;
                justify-content: space-between;
                margin-top: 5px;
                @media screen and (max-width: $media-tablet-max) {
                    height: 33px;
                }
              
                .si-subHeader {
                    padding-left: 0px;
                    @media screen and (max-width: 1199px) {
                        .si-searchbox {
                            width: unset;
                        }
                    }
                }
                .si-add {
                    text-transform: uppercase;
                    font-size: 14px;
                }
                @media screen and (max-width: $media-tablet-max) {
                    .si-add {
                        height: 34px;
                        display: flex;
                        align-items: center;
                        max-width: unset;
                       
                    }
                }
                @media screen and (max-width: $media-tablet-max) {
                    .si-subHeader {
                        width: 60px;
                        .si-searchbox {
                            width: 35px;
                            height: 36px;
                        }
                        .form-control.si-searchbox input.animateIn {
                            width: calc(100vw - 34px);
                        }
                    }
                }
            }
            .table-body__inner-wrapper {
                height: calc(100vh - 230px);
                // height: 61vh;
                position: relative;
                margin-top: 5px;
                @media screen and (min-width: 993px) and (max-width:1199px) {
                    height: calc(100dvh - 230px);
                  }
                @media screen and (max-width: $media-tablet-max) {
                    height: calc(100vh - 365px);
                }
                .custom-select__menu {
                    z-index: 3 !important;
                }
            }
            &-body {
                border-radius: 10px;
                border: 1px solid #c8c8c8;
                height: 100%;
                border-top: 0px;
                overflow: scroll;
                &::-webkit-scrollbar {
                    width: 0px;
                }
                .tournament-table {
                    border-spacing: 0;
                    border-collapse: separate;
                    &-header {
                        background: $patch-color;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        color: $secondary-color-1;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: capitalize;
                        position: sticky;
                        top: 0;
                        z-index: 4;
                        td {
                            padding: 8px 10px 7px 10px;
                            text-align: center;
                            white-space: nowrap;
                            &:first-child {
                                border-top-left-radius: 5px;
                                justify-content: center;
                                padding-right: 0;
                            }
                            &:nth-child(2) {
                                text-align: left;
                                padding-left: 0;
                            }
                            &:last-child {
                                border-top-right-radius: 5px;
                            }
                        }
                    }
                    &-body {
                        border-top: 0px;
                        border-radius: 5px;
                        td {
                            padding: 10px;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 400;
                            min-height: 45px;
                            color: $secondary-color-1;
                            &:first-child {
                                text-align: left;
                                text-transform: capitalize;
                                white-space: nowrap;
                                display: flex;
                                align-items: center;
                                // padding-left: 27px;
                                position: relative;
                                padding-right: 0;
                                padding-top: 15px;
                                width: 25px;
                                @media screen and (max-width: 768px) {
                                    width: 30px;
                                }
                                @media screen and (min-width:768px) and (max-width: $media-tablet-max) {
                                    width: 22px;
                                }
                                @media screen and (min-width: $media-tablet-max) and (max-width:1200px) {
                                    width: 35px;
                                }
                            }
                            &:nth-child(2) {
                                text-align: left;
                                padding-left: 0 !important;
                            }
                            .tbl-input {
                                width: 60px;
                                height: 30px;
                                border: 1px solid #c8c8c8;
                                border-radius: 5px;
                                padding: 6px;
                                text-align: center;
                                &:focus {
                                    border-color: $secondary-color-1;
                                }
                            }
                            .tbl-viewdata {
                                display: none;
                            }
                            .img-type-wrp {
                                display: none;
                                margin: auto;

                                width: 33px;
                                justify-content: flex-start;
                                padding-left: 7px;
                                .medal-type {
                                    margin-left: 7px;
                                }
                            }
                            .si-select {
                                width: 135px;
                            }
                            .custom-single-select {
                                width: 142px;
                                margin: auto;
                                text-align: left;
                                @media screen and (min-width:768px) and (max-width: $media-tablet-max) {
                                    width: 150px;
                                }
                            }
                        }
                        .icon-close {
                            padding: 5px;
                            border-radius: 50%;
                            background-color: $white;
                            cursor: pointer;
                            color: $neutral-color-3;
                            &:hover {
                                color: $secondary-color-1;
                                background: $neutral-color-5;
                                transition: all 0.2s ease-in-out;
                            }
                        }
                    }
                }
                .view-tournament-table {
                    @extend .tournament-table;
                    input,
                    select {
                        display: none;
                    }
                    tbody {
                        td {
                            padding: 0 10px !important;
                        }
                    }
                    .tbl-viewdata {
                        min-height: 45px;
                        min-width: 80px;
                        display: inline-flex !important;
                        justify-content: center;
                        align-items: center;
                    }
                    .view-total-score {
                        background-color: $neutral-color-6;
                    }
                    .img-type-wrp {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 20px;
                        }
                    }
                }
            }
            &-footer {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                position: relative;
                @media screen and (max-width: $media-tablet-max) {
                    height: 40px;
                }
                .footer-left {
                    display: flex;
                    align-items: center;
                    &__delete-icon {
                        cursor: pointer;
                        padding-top: 4px;
                        img {
                            width: 15px;
                            margin-right: 4px;
                        }
                    }

                    &__text {
                        display: flex;
                        font-size: 12px;
                        font-weight: 500;
                        color: $neutral-color-2;
                        margin-left: 8px;
                        max-width: 300px;
                        align-items: center;
                        @media screen and (min-width: 993px) and (max-width: $media-desktop-small-max) {
                            display: none;
                        }
                        .icon-academic-player {
                            margin-right: 5px;
                        }
                    }
                    @media screen and (max-width: $media-tablet-max) {
                        &__text {
                            display: none;
                        }
                    }
                }
                @media screen and (min-width: $media-tablet-max) {
                    .popup-btn-wrp {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .tournament-table-active {
            @media screen and (max-width: $media-tablet-max) {
                top: 120px;
                left: 0;
                padding: 0 17px;
                width: 100%;
                .si-subHeader .form-control .si-icon.icon-search {
                    top: 0px !important;
                }
            }
        }
    }
}
.nodata-tbody {
    display: none;
}
.nodata-tbody-active {
    .nodata-tbody {
        display: block !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    .data-tbody {
        display: none;
    }
}
.add-action-btn-wrp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .add-new-report {
        margin-right: 5px;
    }
    .si-icon__button {
        height: 30px;
        width: 30px;
        border-radius: 5px;
        padding: 0 14px;
    }
    .si-custom-table__cell {
        padding: unset;
    }
}

.tournament-main-content {
    .MuiBox-root .MuiPaper-root {
        height: calc(100% - 55px);
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100% - 70px);
        }
    }
}
.close-btn {
    display: none;
}
@media screen and (max-width: $media-tablet-max) {
    .close-btn {
        display: block;
        width: 96vw;
        height: 40px;
        color: #282828;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 5px;
        background: $white;
        border: 1px solid #282828;
    }
    .popup-btn-wrp {
        display: contents;
        .si-button--green {
            margin-right: 20px;
            @media screen and (max-width: $media-tablet-max) {
                margin-right: 0px;
            }
        }
    }
}

@media screen and (max-width: $media-tablet-max) {
    .si-main-tournament + .si-custom-popup {
        width: 100%;
    }
}
.view-data-mode {
    .tournament-body__right-subheader {
        .si-add {
            display: none;
        }
    }
}

.tournament-body__right-subheader + .table-body__wrapper {
    .table-body__inner-wrapper {
        height: calc(100vh - 284px);
        @media screen and (min-width: 993px) and (max-width:1199px) {
            height: calc(100dvh - 284px);
          }
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100dvh - 284px);
            .icon-search {
                top: 0 !important;
            }
        }
    }
}
