// @use "sass:map";
// @use "../variables";

.si-header {
  @extend %flexbox !optional;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 11;

  @media screen and (max-width: $media-tablet-max) {
    height: 60px;
    background: $secondary-color-1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

  .si-left {
    @extend %flexbox !optional;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 10px;

    @media screen and (max-width: $media-tablet-max) {
      left: 52px;
      top: 16px;
    }
    .si-Logo {
      width: 205px;
      height: 34.91px;

      &.si-mobilelogo {
        display: none;
      }

      @media screen and (max-width: $media-desktop-max-1024) {
        width: 180px;
      }

      @media screen and (max-width: $media-tablet-max) {
        &.si-desklogo {
          display: none;
        }

        &.si-mobilelogo {
          display: flex;
        }
      }

      & img {
        width: 100%;
        height: auto;
      }

      &.si-mobilelogo {
        width: 25.14px;
        height: 25.56px;
        margin-left: -8px;
      }
    }
  }

  .si-actionwrp {
    @extend %flexbox !optional;
    position: absolute;

    right: 75px;
    width: 89px;
    top: 21px;

    @media screen and (max-width: $media-tablet-max) {
      right: 0;
    }

    .si-edit {
      position: relative;
      cursor: pointer;
      z-index: 999;
      width: 17px;

      @media screen and (max-width: $media-tablet-max) {
        position: absolute;
        bottom: -60px;
        right: 47px;
      }

      &:after {
        content: map-get($si-icons, edit);
        font-family: "AMS" !important;
        font-size: 14px;
        color: $white;
        position: absolute;
        right: 0;
        cursor: pointer;
        top: 0;
      }

      &.active {
        &:after {
          display: none;
        }

        .si-editAction {
          @include flexbox();
        }
      }

      .si-editAction {
        display: none;
      }

      .si-tick {
        &:after {
          content: map-get($si-icons, tick);
          font-family: "AMS" !important;
          font-size: 14px;
          color: $white;
          position: absolute;
          right: 29px;
          cursor: pointer;
          top: 0;
        }
      }

      .si-cancel {
        &:after {
          content: map-get($si-icons, cancel);
          font-family: "AMS" !important;
          font-size: 14px;
          color: $white;
          position: absolute;
          right: 0;
          cursor: pointer;
          top: 0;
        }
      }
    }

    .si-notification {
      position: relative;
      cursor: pointer;
      z-index: 999;
      width: 16px;
      display: none;

      @media screen and (max-width: $media-tablet-min) {
        top: -6px;
      }

      &.active {
        position: relative;

        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: $notification-alert;
          border-radius: 50%;
          right: 0;
          position: absolute;
          z-index: 99;
        }
      }

      &:after {
        content: map-get($si-icons, notification);
        font-family: "AMS" !important;
        font-size: 16px;
        color: $white;
        position: absolute;
        right: -26px;
        cursor: pointer;
        top: 0;
      }
    }
  }
}

@media screen and (max-width: $media-desktop-max-1024) {
  .si-header {
    .si-actionwrp {
      width: 47px;
    }
  }
}
.si-header2 {
  height: 60px;
  background: #282828;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width:992px){
  .height-65{
    height: 65px!important;
  }
}
