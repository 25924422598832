@import "../mixins";
@import "../variables";

table {
    width: 100%;
    border-collapse: collapse;
}

.si-custom-table {
    color: $white;
    font-size: 12px;
    border-radius: 10px;
    position: relative;
    .si-count {
        background-color: $neutral-color-4;
        padding: 5px 10px;
        @media screen and (max-width: $media-tablet-max) {
            background-color: unset;
            color: #282828;
        }
    }

    &__check {
        .si-custom-table__cell {
            &.checkbox {
                width: 5%;
                flex: unset;
                @include flexbox();
                align-items: center;
            }

            &:nth-child(2) {
                width: 45%;
                flex: 1;
                text-align: left;
                justify-content: flex-start;
            }
        }
    }

    &__head {
        background-color: #292929;
        position: absolute;
        width: 100%;
        z-index: 3;
        padding-right: 3px;
        color: $dark-yellow;
        text-transform: capitalize;
    }

    &__body {
        height: calc(100vh - 190px);
        height: -webkit-calc(100vh - 190px);
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 38px;
        border-radius: 10px;

        @media screen and (max-width: $media-tablet-max) {
            height: calc(100vh - 261px);
            height: -webkit-calc(100vh - 261px);
        }

        &::-webkit-scrollbar {
            width: 3.68px;
            z-index: 999;
            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: $dark-yellow;
            border-radius: 10px;
            z-index: 999;
        }

        .si-custom-table__row {
            background-color: #181818;

            &.active,
            &:hover {
                background-color: $dark-yellow;
                color: $black;

                .icon-options {
                    &:before {
                        color: $black;
                    }
                }

                .si-count {
                    background-color: $primary-color-5;
                }
            }
        }
    }

    &__row {
        @include flexbox();
        transition: 0.2s;

        span {
            padding: 12px;
        }

        & + .si-custom-table__row {
            border-top: 1px solid rgba($white, 0.1);

            &:hover,
            &.active {
                border-top: 1px solid rgba($black, 0.5);
            }
        }

        &:last-child {
            .si-wrap .si-action-icons {
                top: auto;
                bottom: 0;
                right: 20px;
            }
        }
    }

    &__cell {
        @include flexbox();
        text-align: center;
        width: 10%;
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        cursor: pointer;

        &:nth-child(1) {
            width: 55%;
            flex: 1;
            text-align: left;
            justify-content: flex-start;
        }
    }
}

.si-custom-table-container {
    height: calc(100vh - 190px);
    height: -webkit-calc(100vh - 190px);
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    background: $white;
    &::-webkit-scrollbar {
        width: 2px;
        z-index: 999;
        height: 3px;
        @media screen and (max-width: $media-tablet-max) {
            width: 0px;
        }
    }

    /* Track */
    &::-webkit-scrollbar-track {
        z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2);
        border-radius: 10px;
        z-index: 999;
    }

    @media screen and (max-width: $media-tablet-max) {
        height: calc(100vh - 351px);
        height: -webkit-calc(100vh - 351px);
    }

    .si-icon__button {
        height: auto;
        background: $white;
        color: #0d0d0d;
        border-radius: 4px;

        .si-icon__button {
            height: auto;
        }

        &::-webkit-scrollbar {
            width: 3.68px;
            height: 3px;
            z-index: 999;
            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(#242424, 0.2);

            border-radius: 10px;
            z-index: 999;
        }
    }

    @media only screen and (max-width: 1200px) {
        .si-custom-table {
            &__cell {
                width: 15%;
                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    &.si-custom-table--temp {
        border-radius: 0;

        table,
        td,
        th {
            border: 1px solid;
        }

        th {
            text-transform: capitalize;
        }

        .si-custom-table .MuiTableCell-head {
            padding: 8px;
        }
    }
}
.si-custom-table__head {
    .si-custom-table__row {
        .si-custom-table__cell {
            font-weight: 500;
        }
    }
}
.MuiTableCell-stickyHeader {
    font-size: 14px !important;
    padding: 6px 10px !important;
}
