.si-tabs {
    padding-left: 15px;
    padding-right: 15px;
    .si-subHeader{
        margin-top: 15px;
    }
    .si-tab {
      
        &-list {
            @include flexbox();
            border-bottom: 1px solid $neutral-color-2;
            margin-top: 7px;
            @media screen and (max-width:1200px){
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    height: 0;
                    z-index: 999;
                }
        
            }
        }

        &-item {
            color: $neutral-color-2;
            padding: 5px 15px;
            transition: 0.2s;
            font-size: 12px;
            font-weight: 700;
            text-transform: capitalize;
            border-bottom: 4px solid transparent;
            line-height: 18px;
            cursor: pointer;
            @media screen and (max-width:$media-desktop-small-max) {
                font-size:13px;
                white-space: nowrap;
            }
            &.active {
                color: $primary-color-1;
                border-bottom: 4px solid $primary-color-1;
            }
        }
    }
}