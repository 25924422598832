// @use "sass:map";
// @use "../mixins";
// @use "../variables";
// @use "../variables-en";

.si-mobilemenu {
	.si-menuicon {
		&:after {
			content: "";
			background-image: url("../images/menu.png");
			background-repeat: no-repeat;
			background-size: cover;
			width: 18px;
			height: 18px;
			position: absolute;
			left: 12px;
			top: 26px;
			display: none;
			cursor: pointer;
			z-index: 999;
			@media screen and (max-width: $media-tablet-max) {
				left: 12px;
			}
		}
	}

	.si-close {
		display: none;

		&:before {
			content: "\e906";
			font-family: "AMS" !important;
			font-size: 20px;
			color: $white;
			left: 20px;
			top: 20px;
			cursor: pointer;
			z-index: 999;
			position: fixed;

			@media screen and (max-width: $media-tablet-max) {
				left: 13px;
			}
		}

		@media screen and (max-width: $media-tablet-max) {
			display: flex;
		}
	}

	@media screen and (max-width: 993px) {
		.si-menuicon {
			&:after {
				display: block;
				top: 20px;
				position: fixed;
			}
		}

		.si-right {
			z-index: 9999999999;
			position: fixed;
		}

		&.activeIn {
			.si-right {
				@include transform(translate(0%, 0%));
				@include transition(all, 625ms, ease-in-out);
			}

			.si-leftPanel {
				width: 360px;

				@include transform(translate(0%, 0%));
				@include transition(all, 625ms, ease-in-out);
				position: fixed;
				left: 0;
			}
		}

		&.activeOut {
			.si-leftPanel {
				@include transform(translate(-100%, 0%));
				@include transition(all, 625ms, ease-in-out);
			}

			.si-right {
				@include transform(translate(-100%, 0%));
				@include transition(all, 625ms, ease-in-out);
			}
		}
	}
}

.si-right {
	position: absolute;
	right: 0;
	top: 1px;
	z-index: 9;

	@media screen and (max-width: 993px) {
		left: -90px;
		right: inherit;
		width: 100%;
		top: 51px;

		@include flexbox();
		@include transform(translate(0%, 0%));
	}
}

.si-profileBox {
	@media screen and (max-width: 993px) {
		position: relative;
	}

	&.active {
		.si-profileInfo {
			display: block;
			@include transform(scale(0.9));
			@include transition(all, 0.2s, ease-in-out);
		}
	}

	.icon-carret {
		&:before {
			font-size: 10px;
			padding-left: 3px;
		}
	}
}

.si-profile {
	width: 40px;
	height: 40px;
	cursor: pointer;
	border: 2px solid #8080802b;
	@include border-radius(50%);
	position: absolute;
	right: 15px;
	top: 9px;

	@media screen and (max-width: 993px) {
		right: 40px;
		width: 60px;
		height: 60px;
	}

	& img {
		width: 100%;
		height: auto;
	}
}

.icon-carret {
	cursor: pointer;
	font-size: 10px;
	width: 16px;
	height: 16px;
	background: $black;
	@include border-radius(22px);

	position: absolute;
	right: 12px;
	top: 41px;

	&:before {
		color: $dark-yellow !important;
	}

	@media screen and (max-width: 993px) {
		display: none;
	}

	&:after {
		content: map-get($si-icons, admin-drp);
		font-family: "AMS" !important;
		font-size: 7px;
		position: absolute;
		color: $dark-yellow;
		right: 3px;
		top: 5px;
	}
}

.si-profileInfo {
	width: 200px;
	min-height: 90px;
	background: $white;
	box-shadow: 0px 0px 4px rgba($black, 0.25);

	margin-top: 54px;
	z-index: 99;
	@include border-radius(10px);
	padding: 10px;
	display: none;

	@include transform(scale(0.9));
	@include transition(all, 0.2s, ease-in-out);

	@media screen and (max-width: $media-tablet-max) {
		min-height: auto;
	}
	&:after {
		filter: drop-shadow(0px 0px 10px #b2b2b2);
		border-color: transparent transparent white transparent;
	}
	.si-profileName {
		font-family: $semibold_font-family;
		font-weight: $semibold_font-weight;
		color: $black;
		font-size: 16px;
		text-transform: capitalize;

		@media screen and (max-width: 993px) {
			z-index: 999;
			position: absolute;
			top: -90%;
			transform: translateY(-50%);
			width: 100%;
			left: 94%;
			visibility: visible;
			font-family: $medium_font-family;
			font-weight: $medium_font-weight;
			font-size: 16px;
			color: $white;

			.si-firstName {
				display: block;
			}

			.si-arrow {
				padding-left: 10px;

				position: absolute;

				top: -21px;
				right: -21px;

				&:after {
					content: map-get($si-icons, arrow);
					font-family: "AMS" !important;
					position: absolute;
					font-size: 15px;
					color: $dark-yellow;
					top: 24px;
				}

				&:before {
					content: map-get($si-icons, arrow);
					color: rgba($dark-yellow, 0.5);
					right: 3px;
					font-family: "AMS" !important;
					position: absolute;
					font-size: 10px;
					top: 26px;
					@include animation("slideInLeft .7s linear infinite");
				}
			}

			.si-lastName {
				position: relative;
				&::after {
					@media screen and (max-width: $media-tablet-max) {
						content: "\e901";
						font-family: "AMS" !important;
						position: absolute;
						font-size: 0px;
						color: #ffffff;
						right: -24px;
						bottom: 6px;
						font-weight: normal;
						z-index: 999999;
						transform: rotate(-90deg);
					}
				}
			}
		}
	}
}

.si-otherDetails {
	font-family: $regular_font-family;
	font-weight: $regular_font-weight;
	font-size: 10px;
	color: $secondary-color-5;
	padding-top: 3px;
	padding-bottom: 8px;
}

.si-logout {
	border-top: 1px solid $neutral-color-3;
	cursor: pointer;
	position: relative;
	padding-top: 10px;
	color: $primary-color-1;

	&:after {
		content: "\e928";
		font-family: "AMS" !important;
		position: absolute;
		font-size: 17px;
		left: 1px;
		top: 9px;
	}

	button {
		border: 0;
		background-color: transparent;
		color: $primary-color-1;
		font-family: $regular_font-family;
		font-weight: $regular_font-weight;
		font-size: 10px;
		padding-left: 27px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
}

.si-leftPanel {
	position: absolute;
	background-color: $secondary-color-1;
	width: 243px;
	height: calc(100vh - 0px);
	@include transform(translate(0%, 0%));
	@media screen and (max-width: $media-tablet-max) {
		left: 0px;
		top: 57px;
		height: calc(100dvh - 60px);
		z-index: 9999999;
		@include transform(translate(-100%, 0%));
	}

	.si-menuWrp {
		position: absolute;
		left: 0px;
		right: 0;
		text-align: left;
		top: 75px;
		height: calc(100vh - 138px);
		overflow: hidden;

		@media screen and (max-width: 993px) {
			top: 95px;
			height: calc(100dvh - 155px);
			overflow-y: scroll;
		}
	}

	.si-menu {
		padding-left: 31px;

		.icon-training,
		.icon-user,
		.icon-1,
		.icon-2,
		.icon-7,
		.icon-4,
		.icon-5,
		.icon-6,
		.icon-9 {
			font-size: 20px;
			position: absolute;
		}
		.icon-10 {
			padding-top: 2.5px;
		}

		// patch work
		&.icon-chev-down {
			&:before {
				transition: 0.2s;
				position: absolute;
				right: 0;
				font-size: 12px;
			}
		}

		padding-left: 0;

		&:not(:last-child) {
			margin-bottom: 9px;
		}

		&.activeIn {
			@media screen and (max-width: $media-tablet-max) {
				border-bottom: 1px solid rgba($white, 0.8);
			}

			.si-menu-title {
				position: relative;
				height: 30px;
				background: $primary-color-1 !important;
				box-shadow: 0px 4px 4px rgba($black, 0.25);
				color: $white;
				padding-left: 44px;
				font-size: 12px;
				display: flex;
				align-items: center;
				@media screen and (max-width: $media-tablet-max) {
					font-size: 16px;
					height: 50px;
				}

				&:after {
					display: none;
				}
				.si-image {
					&.si-black {
						@include flexbox();
					}
				}

				.si-drparrow {
					&:after {
						transform: rotate(360deg);
						@media screen and (max-width: $media-tablet-max) {
							top: 9px !important;
						}
					}
				}
			}

			.si-submenu {
				display: block;

				@media screen and (max-width: 993px) {
					@include flexbox;
					justify-content: flex-start;
					margin-left: 23px;
				}

				ul {
					visibility: visible;
					opacity: 1;
					@include animation("slideInDown .5s linear ");
				}
			}
		}

		&.activeOut {
			&.icon-chev-down {
				&:before {
					transform: rotate(-90deg);
				}
			}

			.si-submenu {
				ul {
					@include animation("slideOutUp .5s linear ");
				}
			}
		}

		.si-menu-title {
			font-family: $bold_font-family;
			font-weight: $bold_font-weight;
			color: $neutral-color-3;
			font-size: 12px;
			z-index: 99;
			padding-left: 44px;
			cursor: pointer;
			position: relative;
			height: 30px;
			position: relative;
			@include flexbox();
			align-items: center;
			&:hover {
				background-color: $secondary-color-2;
			}
			@media screen and (max-width: $media-tablet-max) {
				font-size: 16px;
			}

			@media screen and (max-width: 993px) {
				font-family: $semibold_font-family;
				font-weight: $semibold_font-weight;
				color: rgba($white, 0.6);
				padding-left: 10px !important;
			}

			.si-drparrow {
				position: absolute;
				right: 0;
				top: 2px;
				line-height: 28px;

				&:after {
					content: "\e901";
					font-family: "AMS" !important;
					position: absolute;
					font-size: 12px;
					left: -35px;
					font-weight: normal;
					transform: rotate(-90deg);
				}
			}

			.si-image {
				width: 23px;
				height: 23px;
				position: absolute;
				left: 10px;
				top: 1px;
				display: flex;
				justify-content: center;
				align-items: center;

				&.si-black {
					display: none;
				}

				& img {
					width: 23px;
					height: 23px;
					-webkit-filter: grayscale(100%);
					/* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
				}
			}

			&.si-Training {
				&:after {
					content: "";
					background-image: url("../images/S&C-Training.svg");
					background-repeat: no-repeat;
					background-size: cover;
					width: 19px;
					height: 19px;
					position: absolute;
					left: -26px;
					display: none;
				}
			}

			&.si-Athletes {
				&:after {
					content: map-get($si-icons, Athletes);
					font-family: "AMS" !important;
					position: absolute;
					font-size: 18px;
					color: $white;
					left: -26px;
					display: none;
				}
			}
		}

		.si-submenu {
			overflow: hidden;
			padding: 10px 0px 0px 56px;
			display: none;

			@media screen and (max-width: $media-desktop-max-1024) {
				padding: 12px 0px 0px 17px;
			}

			@media screen and (max-width: 993px) {
				padding: 0px 0px 10px 10px;

				ul {
					visibility: hidden;
					opacity: 0;
				}
			}

			li {
				color: $neutral-color-3;
				font-family: $regular_font-family;
				font-weight: $regular_font-weight;
				font-size: 12px;
				cursor: pointer;
				text-transform: capitalize;
				padding: 5px 0;

				@media screen and (max-width: $media-desktop-max-1024) {
					font-size: 12px;
				}

				@media screen and (max-width: 993px) {
					font-size: 16px;
					height: 50px;
					line-height: 38px;

					&:not(:last-child) {
						border-bottom: 1px solid rgba($white, 0.8);
						width: 299px;
					}
				}

				&.active {
					color: $white;
					font-family: $bold_font-family;
					font-weight: $bold_font-weight;
				}
			}
		}
	}

	/* width */
	::-webkit-scrollbar {
		width: 8px;
		z-index: 999;
	}

	/* Track */
	::-webkit-scrollbar-track {
		z-index: 999;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgba(#242424, 0.2);
		border-radius: 10px;
		z-index: 999;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgba(#242424, 0.2);
		z-index: 999;
	}
}

@media screen and (max-width: $media-tablet-max) {
	.si-profileInfo {
		@include flexbox();
		background: transparent;
		box-shadow: none;
		width: 199px;

		.si-otherDetails {
			display: none;
		}

		.si-logout {
			position: absolute;

			right: -287px;

			left: initial;
			top: -46px;

			&:after {
				color: $white;
				font-size: 23px;
				left: -36px;
				top: 12px;
			}
			button {
				display: none;
			}
		}
	}

	.si-leftPanel {
		.si-menu {
			.si-menu-title {
				.si-image {
					display: none;

					&.si-black {
						display: none !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 360px) {
	.si-mobilemenu.activeIn .si-leftPanel {
		width: 320px;
		top: 59px;
	}
}
@media screen and (max-width: 360px) {
	.si-profileInfo .si-logout:after {
		color: #ffffff;
		font-size: 23px;
		left: -82px;
		top: 12px;
	}
}
