// first-page

.internal-note-section {
    margin-top: -5px;
    .add-new-report {
        text-transform: uppercase;
    }
    .add-note--title {
        display: flex;
        align-items: baseline;
        margin-bottom: 15px;
        @media screen and (max-width: $media-tablet-max) {
            flex-direction: column;
            align-items: start;
        }
        .title-text {
            font-size: 16px;
            font-weight: 600;
            color: $secondary-color-1;
            margin-right: 5px;
        }
        .title-note {
            font-size: 12px;
            font-weight: 400;
            color: $neutral-color-1;
        }
    }
}
.add-note-section {
    .new-note {
        height: calc(100vh - 250px);
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: $media-tablet-max) {
            padding-right: 15px;
        }
        .add-new-report {
            font-size: 14px;
            max-width: 204px;
            @media screen and (max-width: $media-tablet-max) {
                display: flex;
            }
        }
        .icon-add {
            font-size: 16px;
        }
    }
}

// second-page

.internal-note-section {
    .note-heading {
        background-color: #e7e0ec;
        font-size: 14px;
        font-weight: 700;
        padding: 6px 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .note-heading--text {
            margin-right: 15px;
            text-transform: capitalize;
        }
        .icon-edit {
            cursor: pointer;
            color: $neutral-color-2;
            font-size: 15px;
            &:hover {
                color: #454343;
            }
        }
    }
    .note-subheading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .date-form-control {
            position: relative;
        }
    }
    // .note-edit-mode{
    textarea {
        height: 130px !important;
    }
    textarea[readonly] {
        border: none;
        background: none;
    }
    .view-notes {
        font-size: 12px;
        font-weight: 400;
        color: $neutral-color-2;
        max-height: 220px;
        overflow-y: scroll;
        padding: 0 15px;
        &::-webkit-scrollbar {
            width: 2px;
            z-index: 999;
            @media screen and (max-width: $media-tablet-max) {
                width: 0px;
            }
        }

        /* Track */
        &::-webkit-scrollbar-track {
            z-index: 999;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(#242424, 0.2);
            border-radius: 10px;
            z-index: 999;
        }
    }

    .edit-mode-active {
        .icon-edit,
        .view-notes,
        .writer-name {
            display: none;
        }
    }
    .view-mode-active {
        textarea {
            display: none;
        }
        .icon-edit,
        .view-notes,
        .writer-name {
            display: block;
        }
    }
}

.nutrition-footer {
    right: 15px;
    bottom: 15px;
    @media screen and (max-width: $media-tablet-max) {
        position: unset;
    }
}

@media screen and (max-width: $media-tablet-max) {
    .btn-with-table {
        width: 100% !important;
        padding: 0 15px !important;
        .add-new-report {
            display: flex !important;
            max-width: unset !important;
            .icon-add:before{
                font-size: 13px!important;
                margin-bottom: 1px;
            }
        }
    }
    .btn-without-table {
        .add-new-report {
            display: none !important;
        }
    }
}
