@import "../mixins";
 @import "../variables";
 @import "../variables-en";

.si-tabs {

    .si-tab-list {
        &.si-athleteprofile {
            @media screen and (max-width:$media-tablet-max) {
                width: 100%;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    height: 0px;
                    z-index: 999;
                  
                }
                &::-webkit-scrollbar-thumb {
                    background: rgba(#242424, 0.2);
                    border-radius: 10px;
                    z-index: 999;
                  }
            }

            .si-tab-item {
                font-size: 12px;

                @media screen and (max-width:$media-desktop-max-1024) {
                    padding: 5px 14px;
                }

                @media screen and (max-width:$media-tablet-max) {
                    padding: 0;
                    white-space: nowrap;
                    margin-right: 20px;
                    font-size: 14px;

                }

            }
        }
    }

    .si-Performanceheader {
        width: 100%;
        margin-left: 0;

        &:after {
            @media screen and (max-width:$media-tablet-max) {
                width: 97%;
                right: -2px;
            }
            @media screen and (max-width:420px){
                width: 94%;
            }
        }

        .si-Right {
            display: none;
        }

        .si-Left {
            width: 100%;
            flex: 0 0 100%;
        }

        .si-profession {
            @media screen and (max-width:$media-mobile-small-max-380) {
                font-size: 9px;
            }
        }

        .si-group {
            @media screen and (max-width:$media-mobile-small-max-380) {
                width: 48px;
                height: 22px;
                font-size: 9px;
                white-space: nowrap;

                .si-group:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }

    .si-informationsection {
        @extend %flexbox;
        justify-content: space-between;
        width: 100%;
        position: relative;
        border-radius: 5px;
        &::-webkit-scrollbar {
            width: 3px;
            z-index: 999;
            @media screen and (max-width:$media-tablet-max) {
                width: 0px;
              }
        }

        /* Track */
        &::-webkit-scrollbar-track {
            z-index: 999;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(#242424, 0.2);
            border-radius: 10px;
            z-index: 999;
        }

        @media screen and (max-width:$media-tablet-max) {
            flex-flow: column;

            height: calc(100vh - 306px);
        }

        .si-Left,
        .si-Right {
            width: 50%;
            flex: 0 050%;
            background: $white;
            @include border-radius(5px);
            padding: 10px;

            @media screen and (max-width:$media-tablet-max) {
                width: 100%;
                flex: 1;
            }

            .si-view {
                color: $neutral-color-2;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                font-size: 10px;
                float: right;
            }

            .si-top {
                border-bottom: 1px solid $neutral-color-4;
                padding-bottom: 12px;
            }

            .si-bottom {
                padding-top: 12px;

                @media screen and (max-width:$media-tablet-max) {
                    border-bottom: 1px solid $neutral-color-4;
                }
            }

        }

        .si-Right {
            position: relative;
            margin-left: 10px;
            @media screen and (max-width:$media-tablet-max) {
                margin-left: 0px;
            }
           
          
        }

        .si-heading {
            color: $secondary-color-1;
            font-size: 12px;
            font-family: $bold_font-family;
            font-weight: $bold_font-weight;
            padding-bottom: 10px;
            @media screen and (max-width:$media-tablet-max) {
                font-size: 14px;
            }
        }

        .si-infowrp {
            font-size: 12px;
            @extend %flexbox;
            flex-flow: column;
            @media screen and (max-width:$media-tablet-max) {
                font-size: 14px;
            }

            .si-title {
                color: $secondary-color-1;
                font-family: $medium_font-family;
                font-weight: $medium_font-weight;
                width: 50%;
                flex: 0 0 50%;
                display: flex;
                align-items: center;
                line-height: unset;

            }

            .si-info {
                color: $secondary-color-5;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                width: 50%;
                flex: 0 0 50%;
                display: flex;
            }
        }

        .si-section {
            @extend %flexbox;
            flex-flow: row;
            padding-bottom: 8px;
        }
    }

  
    .si-tab-pane{
        &__Training {
            .si-subHeader{
                .form-control-wrap{
                    .custom-multi-select{
                        @media screen and (max-width:$media-mobile-small-max-420) {
                        width: 46%;
                        }
                        @media screen and (max-width:$media-mobile-small-max-380) {
                            width: 38%;
                            }
                    }
                }
                } 
            .si-custom-table-container {
                height: calc(100vh - 310px) !important;
                height: -webkit-calc(100vh - 310px) !important;
                @media screen and (max-width:$media-desktop-small-max) {
                    height: calc(100vh - 346px) !important;
                    height: -webkit-calc(100vh - 346px) !important;
    
                }
    
                @media screen and (max-width:$media-tablet-max) {
                    height: calc(100vh - 397px) !important;
                    height: -webkit-calc(100vh - 397px) !important;
                }
    
                @media screen and (max-width:$media-mobile-small-max-420) {
                    height: calc(100vh - 410px) !important;
                    height: -webkit-calc(100vh - 410px) !important;
                }
            }
        }
    }
}
.si-athleteprofile-tab{
    .si-tab-content{
        height: calc(100dvh - 250px);
        .si-assesment{
            height: calc(100vh - 225px);
            @media screen and (max-width:$media-tablet-max){
                height: calc(100vh - 320px);
            }
        }
    }
}
.comming-soon{
    position: fixed;
    top: 57%;
    left: 57%;
    transform: translate(-50%,-50%);
    height: 144px;
    width: 357px;
    @media screen and (max-width:$media-tablet-max){
        left: 50%;
        height: 120px;
        width: 280px;  
    }
    img{
        height: 100%;
        width: 100%;
    }
}



.si-athleteprofile-tab{
    .si-tab-pane{
        @media screen and (max-width:$media-tablet-max){
            .si-custom-table-container{
                height: calc(100vh - 410px) !important;
            }

        }
    }
    }
