@import "../variables";
@import "../mixins";
@import "../variables-en";

.si-popup {
    &-head {
        margin-bottom: 18px;

        @media screen and (max-width: $media-tablet-max) {
            margin-bottom: 15px;
        }
        @supports (-webkit-touch-callout: none) {
            margin-bottom: 15px;
        }
        .si-subHeader {
            margin-top: 8px;
            @media screen and (max-width: $media-tablet-max) {
                margin-top: 0px;
            }
        }
    }

    &__title {
        @include flexbox();
        align-items: center;
        margin-bottom: 5px;
        margin-top: 5px;

        .form-control {
            flex: 1;
            margin-right: 30px;
        }

        .si-input {
            font-size: 14px;
            height: 28px;
            @media screen and (max-width: $media-tablet-max) {
                height: 34px;
            }
            @supports (-webkit-touch-callout: none) {
                height: 30px;
                color: #000000;
                opacity: 1;
            }

            &[disabled] {
                padding: 5px 0;
                background: transparent;
                border: none;
            }

            ::placeholder {
                font-size: 14px;
                font-family: $regular_font-family;
                font-weight: 400;
                font-style: normal;
                color: #c8c8c8 !important;
                -webkit-text-fill-color:#c8c8c8!important;
                opacity: 1;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                font-size: 14px;
                font-family: $regular_font-family;
                font-weight: 400;
                font-style: normal;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                font-size: 14px;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                font-style: normal;
            }
        }
    }

    &__description {
        @media screen and (max-width: $media-tablet-max) {
            margin-top: -8px;
        }

        .si-input {
            background: $white;
            border-radius: 5px;
            // border: none;
        }

        textarea {
            .si-input {
                height: 28px;
            }
        }
    }
    input {
        &::placeholder {
            color: #c8c8c8 !important;
            -webkit-text-fill-color:#c8c8c8!important;
            opacity: 1;
        }
    }
}

.si-custom-popup {
    display: none;
    z-index: 1050;
    padding: 10px;
    @include border-radius(5px);

    &.si-popup {
        .si-popup-content {
            background-color: $white;
            width: 68%;
            height: 96%;

            &.si-Athletes {
                .si-popup-body {
                    .si-tab-content {
                        .si-custom-table-container {
                            height: calc(100vh - 294px) !important;
                            height: -webkit-calc(100vh - 294px) !important;
                        }
                    }
                }
            }
        }
    }

    &.active {
        display: block;
    }

    .si-button__close {
        margin-left: auto;
    }

    .si-popup-footer {
        @include flexbox();
        align-items: center;
        justify-content: flex-end;
        color: $white;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        padding: 10px 20px;
        @media screen and (max-width: $media-tablet-max) {
            bottom: 45px;
            padding: 10px 15px;
        }
        @media screen and (max-width:768px){
            @supports (-webkit-touch-callout: none) {
                bottom: 60px;
            }
        }
        .si-pagination {
            @media screen and (max-width: $media-tablet-max) {
                bottom: 0 !important;
            }
        }
    }

    .si-footer-warning {
        @include flexbox();
        color: $secondary-color-1;
        font-size: 12px;
        align-items: center;
        width: 100%;
        flex: 1;

        .icon-info {
            font-size: 24px;
            margin-right: 10px;
            color: $secondary-color-4;
        }
    }
}

@media only screen and (max-width: $media-tablet-max) {
    .si-custom-popup {
        width: 100%;
        top: 44px;
        height: calc(100vh - 48px);
        height: -webkit-calc(100vh - 48px);

        .si-popup-footer {
            display: block;
        }

        .si-footer-warning {
            display: none;
        }

        &.si-popup {
            .si-popup-content {
                width: 100%;
                @media screen and (max-width: $media-tablet-max) {
                    .MuiBox-root .MuiPaper-root .MuiTableContainer-root {
                        height: 84%;
                    }
                }
            }
        }
    }

    
}

// file-upload-popup

.upload-popup {
    background: #ffffff;
    padding: 20px;
    width: 55%;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $media-laptop-max-1280) {
        width: 60%;
    }
    @media screen and (max-width: $media-desktop-max) {
        width: 70%;
    }
    @media screen and (max-width: $media-tablet-max) {
        width: 70%;
    }
    @media screen and (max-width: $media-tablet-max) {
        width: 90%;
        padding: 15px;
    }
    &__header {
        margin-bottom: 20px;
        .header-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3px;
            &__title {
                font-weight: 500;
                font-size: 16px;
                color: #282828;
            }
            &__close {
                margin-top: -13px;
                margin-right: -8px;
            }
        }
        .header-bottom {
            font-size: 12px;
            font-weight: 400;
            color: $neutral-color-2;
        }
    }
    &__body {
        .drag-drop-container {
            display: flex;
            border: 1px dashed #0075e1;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            padding: 25px 15px;
            margin-bottom: 20px;
            @media screen and (max-width: $media-tablet-max) {
                margin-bottom: 10px;
            }
            .drag-drop__icon {
                i {
                    color: #0075e1;
                    font-size: 40px;
                }
            }
            .drag-drop__text-wrp {
                margin-left: 15px;
                .title-text {
                    font-size: 12px;
                    font-weight: 400;
                    color: $secondary-color-1;
                    margin-bottom: 4px;
                    input {
                        width: 64px;
                        margin-left: 3px;
                        margin-right: 3px;
                        @media screen and (max-width: $media-tablet-max) {
                            width: 75px;
                            margin-right: 4px;
                        }
                    }
                    input::file-selector-button {
                        color: dodgerblue;
                        border: none;
                        background: none;
                        text-transform: lowercase;
                        cursor: pointer;
                        padding: 0px !important;
                        margin-right: 0px !important;
                        font-family: "poppins";
                        font-size: 12px;
                        @media screen and (max-width: $media-tablet-max) {
                            font-size: 14px;
                        }
                    }
                
                }
                .info-text {
                    font-size: 10px;
                    color: #888888;
                    font-weight: 400;
                }
                .error-text {
                    font-size: 10px;
                    color: $primary-color-1;
                    font-weight: 400;
                    display: none;
                }
            }
        }
        .uploading-state,
        .uploaded-state {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
        }
        .file-card {
            width: 160px;
            @media screen and (max-width: $media-tablet-max) {
                width: 150px;
            }
            .file-img-wrp {
                height: 90px;
                @media screen and (max-width: $media-tablet-max) {
                    height: 84px;
                }
                background-color: #d9d9d9;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                img {
                    height: 56px;
                    width: 56px;
                }
            }
            .file-name-wrp {
                font-size: 11px;
                font-weight: 400;
                color: $secondary-color-2;
                display: flex;
                justify-content: space-between;
                width: 100%;
                .img-name {
                    width: 86%;
                    overflow: wrap;
                    word-wrap: break-word;
                }
                .del-icon {
                    height: 12px;
                    cursor: pointer;
                    img {
                        height: 100%;
                    }
                }
            }
            .progress-wrp {
                line-height: 3px;
                progress {
                    height: 7px;
                    accent-color: $primary-color-3;
                    width: 100%;
                }
            }
        }
    }
    .error-active {
        .drag-drop-container {
            border: 1px dashed red !important;
        }
        .info-text {
            display: none !important;
        }
        .error-text {
            display: block !important;
        }
    }
}

// ---tournament popup----
.tournament-popup {
    @extend .upload-popup;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .header-title {
            font-size: 20px;
            font-weight: 500;
            color: #282828;
            display: inline-block;
        }
    }
    &__body {
        .si-form {
            .form-control-outer-wrap {
                display: flex;
                gap: 20px;
                @media screen and (max-width: $media-tablet-max) {
                    flex-direction: column;
                    gap: 0;
                }
            }
            .form-control-inner-wrap {
                display: flex;
                gap: 20px;
                .form-control:first-child {
                    .custom-single-select {
                        width: 136px !important;
                    }
                }
                .custom-single-select:last-child {
                    width: 156px !important;
                }
                @media screen and (max-width: $media-tablet-max) {
                    gap: 15px;
                }
            }
            .form-control {
                margin-top: 15px;
                position: relative;
                .si-input {
                    font-weight: 400;
                }
                input[type="date"]::-webkit-calendar-picker-indicator {
                    position: absolute;
                    left: 5%;
                }
                input,
                select {
                    &:focus-within {
                        border: 1px solid #000000 !important;
                    }
                    &:focus-visible {
                        outline: none !important;
                    }

                    &:focus {
                        border: 1px solid #000000 !important;
                    }
                }
            }
            .selectSport-form-control {
                width: 330px;
                @media screen and (max-width: $media-tablet-max) {
                    width: 100%;
                }
            }
            .location-form-control {
                width: 330px;
                @media screen and (max-width: $media-tablet-max) {
                    width: 100%;
                }
            }
            .date-form-control {
                // width: 130px;
                width: 145px;
            }
            .popup-btn-wrp {
                display: flex;
                justify-content: end;
                margin-top: 30px;
            }
            .si-error {
                input,
                select {
                    border: 1px solid red;
                }
                .si-error-message {
                    display: block;
                    position: absolute;
                }
            }
        }
    }
    .panel-content {
        .select-panel {
            .search {
                input {
                    color: #282825;
                    font-size: 12px;
                    &:focus-within {
                        border: none !important;
                    }
                    &:focus {
                        border: none !important;
                    }
                }
            }
        }
    }
    .popup-btn-wrp {
        .si-button {
            min-width: 140px;
        }
    }
}

// ---event-popup

.event-popup {
    @extend .tournament-popup;
    &__header {
        @extend .tournament-popup__header;
    }
    &__body {
        @extend .tournament-popup__body;
    }
}

.si-Athletes {
    .MuiBox-root {
        @media screen and (max-width: $media-tablet-max) {
            height: calc(100dvh - 270px);
        }
    }
    .MuiTableHead-root {
        background-color: #eaeaea;
        .MuiTableHead-root {
            th {
                border: 1px solid #c8c8c8;
                background-color: red;
            }
        }
    }
}

.si-popup__description {
    .si-label {
        @media screen and (max-width: $media-tablet-max) {
            font-size: 12px !important;
        }
    }
}
