//popup styles starts here
.si-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    color: $default;
    visibility: hidden;

    &.active {
        visibility: visible;
        background-color: #282828;
    }

    &-head {
        .si-label-wrap {
            @include flexbox();
            align-items: center;
            position: relative;
            width: 100%;
        }

        .label-head {
            font-size: 18px;
            font-weight: 500;
        }

        .label-info {
            font-size: 10px;
            margin-left: 10px;
            margin-top: 4px;
            color: #999999;
            &.si-datelabel {
                @media screen and (max-width: $media-tablet-max) {
                    position: absolute;
                    bottom: -14px;
                    width: 100%;
                    left: 0;
                    right: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .content {
        &-title h5 {
            font-size: 16px;
            margin-bottom: 15px;
        }

        &-description {
            font-size: 14px;
        }
    }

    .si-action-buttons {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: end;
        @media screen and (max-width: $media-mobile-small-max-456) {
            text-align: center;
            display: flex;
            justify-content: center;
        }
        @media screen and (min-width: 768px) and (max-width: $media-tablet-max) {
            justify-content: center;
        }
    }

    .si-emotionWrp {
        display: flex;
        width: 75%;
        justify-content: center;
        align-items: center;
        margin-left: 47px;
        margin-top: 28px;
        &.si-coachview {
            .si-Difficult,
            .si-Okay,
            .si-smile {
                cursor: initial !important;

                &:hover {
                    transform: none;

                    transition: none;
                    pointer-events: none;
                    //  cursor:not-allowed;
                    border-color: #c8c8c8 !important;
                    cursor: initial !important;
                    &:before {
                        content: "\e927" !important;
                        font-family: "AMS" !important;
                        font-size: 30px;
                        color: $neutral-color-4;
                        background-image: none !important;
                        color: #c8c8c8 !important;
                        cursor: not-allowed;
                    }

                    &.si-Okay {
                        &:before {
                            content: "\e926" !important;
                            font-family: "AMS" !important;
                        }
                    }
                    &.si-smile {
                        &:before {
                            content: "\e924" !important;
                            font-family: "AMS" !important;
                        }
                    }
                    span {
                        color: #c8c8c8 !important;
                    }
                }
                &.active {
                    &:hover {
                        &.si-Difficult {
                            border-color: #ff5740 !important;

                            &:before {
                                content: "" !important;
                                font-family: "AMS" !important;
                                color: #ff5740 !important;
                                background-image: url("../images/fill-Difficult.svg") !important;
                            }
                            & span {
                                color: #ff5740 !important;
                            }
                        }

                        &.si-Okay {
                            border-color: #f9dd4a !important;

                            &:before {
                                content: "" !important;
                                font-family: "AMS" !important;
                                color: #f9dd4a !important;
                                background-image: url("../images/fill-ok.svg") !important;
                            }
                            & span {
                                color: #f9dd4a;
                            }
                        }

                        &.si-smile {
                            border-color: #18e95f !important;

                            &:before {
                                content: "";
                                font-family: "AMS" !important;
                                color: #18e95f !important;
                                background-image: url("../images/fill-happy.svg") !important;
                            }
                            & span {
                                color: #18e95f;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: $media-mobile-small-max-456) {
            width: 100%;
            margin-left: 0;
        }

        .si-Difficult,
        .si-Okay,
        .si-smile {
            width: 60px;
            height: 60px;
            background-color: $white;
            border: 1px solid $neutral-color-4;
            border-radius: 5px;
            margin: auto;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            & span {
                position: absolute;
                bottom: -25px;
                font-family: $regular_font-family;
                font-weight: $regular_font-weight;
                color: $neutral-color-4;
                font-size: 12px;
            }

            &.si-Difficult,
            &.si-Okay,
            &.si-smile {
                &:before {
                    content: "\e927";
                    font-family: "AMS" !important;
                    font-size: 30px;
                    color: $neutral-color-4;
                }
            }

            &.si-Okay {
                &:before {
                    content: "\e926";
                    font-family: "AMS" !important;
                }
            }

            &.si-smile {
                &:before {
                    content: "\e924";
                    font-family: "AMS" !important;
                }
            }

            &:hover {
                transform: scale(1.1);
                transition: all 0.5s ease;
            }

            &.active,
            &:hover {
                &.si-Difficult {
                    border-color: #ff5740;

                    &:before {
                        content: "";
                        font-family: "AMS" !important;
                        color: #ff5740;
                        background-image: url("../images/fill-Difficult.svg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 30px;
                        height: 30px;
                    }
                    & span {
                        color: #ff5740;
                    }
                }

                &.si-Okay {
                    border-color: #f9dd4a;

                    &:before {
                        content: "";
                        font-family: "AMS" !important;
                        color: #f9dd4a;
                        background-image: url("../images/fill-ok.svg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 30px;
                        height: 30px;
                    }
                    & span {
                        color: #f9dd4a;
                    }
                }

                &.si-smile {
                    border-color: #18e95f;

                    &:before {
                        content: "";
                        font-family: "AMS" !important;
                        color: #18e95f;
                        background-image: url("../images/fill-happy.svg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 30px;
                        height: 30px;
                    }
                    & span {
                        color: #18e95f;
                    }
                }
            }
        }
    }

    .si-contentwrp {
        margin-top: 50px;
        color: $white;
        font-size: 14px;
        max-height: 158px;

        .si-title {
            font-family: $regular_font-family;
            font-weight: $regular_font-weight;
            margin-bottom: 10px;
        }

        .si-content {
            font-family: $light_font-family;
            font-weight: $light_font-weight;
            line-height: 21px;
        }
    }

    &-footer {
        margin-top: 19px;
        .si-action-btn {
            width: 150px;
            @media screen and (max-width: $media-mobile-small-max) {
                width: 135px;
            }
            @media screen and (max-width: $media-mobile-small-max-380) {
                width: 113px;
            }
        }
    }

    .si-popup {
        &-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $white;
            padding: 10px 20px 20px 20px;
            @media screen and (max-width: $media-tablet-max) {
                padding: 25px 15px 20px 15px;
                max-width: 90%;
            }
            @include border-radius(5px);
            .si-main-content {
                padding-top: 0px;
            }
            &.si-remove {
                width: 500px;
                height: 310px;
                @media screen and (max-width: $media-mobile-small-max) {
                    width: 345px;
                    height: 271px;
                }
                .si-popup-footer {
                    margin-top: 86px;
                    @media screen and (max-width: $media-mobile-small-max) {
                        margin-top: 47px;
                    }
                }

                .si-form-radio {
                    margin-top: 15px;
                    .form-control {
                        &:not(:last-child) {
                            margin-bottom: 21px;
                        }
                    }
                }
            }
            &.si-emotion {
                width: 440px;
                max-height: 448px;

                @media screen and (max-width: $media-mobile-small-max-456) {
                    width: 343px;
                }

                .si-popup-footer {
                    margin-top: 50px;
                }
                .content-title {
                    h5 {
                        font-size: 20px;
                        color: $secondary-color-1;
                        font-family: $medium_font-family;
                        font-weight: $medium_font-weight;
                        @media screen and (max-width: $media-mobile-small-max-456) {
                            font-size: 18px;
                        }
                    }
                }

                .si-contentwrp {
                    .si-title {
                        color: $secondary-color-1;
                        font-size: 12px;
                        font-family: $regular_font-family;
                        font-weight: $regular_font-weight;
                    }

                    .si-content {
                        .si-ans {
                            height: 57px;
                            width: 100%;
                            resize: none;
                            background: $neutral-color-5;
                            border: 1px solid $neutral-color-4;
                            border-radius: 5px;
                            color: $secondary-color-1;
                            font-size: 14px;
                            font-family: $regular_font-family;
                            font-weight: $regular_font-weight;
                            padding: 6px;
                        }
                    }
                }
            }

            &.si-notes {
                width: 500px;
                max-height: 300px;

                @media screen and (max-width: $media-mobile-small-max-456) {
                    width: 343px;
                }
                .si-popup-footer {
                    margin-top: 32px;
                    .si-action-btn {
                        font-weight: 700;
                    }
                }

                .si-subTitle {
                    font-size: 12px;
                    margin-bottom: 9px;
                }

                .content-title {
                    h5 {
                        margin-bottom: 7px;
                        margin-right: 10px;
                        font-weight: 500;
                    }
                }

                .si-txtArea {
                    width: 100%;
                    height: 120px;
                    background-color: rgba($neutral-color-4, 0.3);
                    @include border-radius(5px);
                    resize: none;
                    font-family: $regular_font-family;
                    font-weight: $regular_font-weight;
                    font-size: 14px;
                    color: $secondary-color-1;
                    border: 0;
                    padding: 10px;
                    &:focus {
                        border: 1px solid #c8c8c8;
                    }

                    @media screen and (max-width: $media-mobile-small-max-456) {
                        width: 100%;
                    }
                }
                .content-description {
                    textarea {
                        height: 88px !important;
                        @media screen and (max-width: $media-tablet-max) {
                            height: 127px !important;
                        }
                    }
                }
                .icon-close {
                    @media screen and (max-width: $media-tablet-max) {
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }
            }

            &.si-emotion,
            &.si-notes {
                .si-popup-footer {
                    .si-action-buttons {
                        .si-action-btn {
                            width: 150px;
                            height: 40px;
                            @media screen and (max-width: $media-mobile-small-max-456) {
                                width: 132px;
                            }
                        }
                    }
                }
            }

            .si-button__close {
                position: absolute;
                right: 16px;
                top: 12px;
                @media screen and (max-width: $media-tablet-max) {
                    right: 10px;
                    top: 6px;
                }
            }
        }
    }
    .si-custom-tabs {
        .si-tab-list {
            overflow-x: unset !important;
        }
    }
}

.si-custom-popup {
    .si-popup__title {
        .form-control {
            width: 90%;
            // width: 500px;
            // flex: 0 0 500px;
            position: relative;
            &.si-error {
                input {
                    border: 1px solid #e10000;
                }
                .si-error-message {
                    display: flex;
                }
            }

            @media screen and (max-width: $media-tablet-max) {
                width: 100%;
                flex: 1;
                margin-right: 0;
                margin-bottom: 7px;
            }
        }
    }

    .si-button__close {
        @media screen and (max-width: $media-tablet-max) {
            position: absolute;
            right: 17px;
            top: 0px;
            .icon-close {
                font-size: 14px;
            }
        }
    }

    .si-sports {
        width: 200px;
        height: 34px;
        position: relative;
        &.si-error {
            select {
                border: 1px solid #e10000;
            }
            .si-error-message {
                display: flex;
                width: 100vh;
            }
            .custom-select__control {
                border-color: #e10000 !important;
            }
        }
        @media screen and (max-width: $media-tablet-max) {
            width: 100%;
        }

        .rmsc {
            .dropdown-heading {
                .dropdown-heading-value {
                    font-size: 12px;
                    @media screen and (max-width: $media-tablet-max) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .si-Athletes {
        .si-popup__title {
            .si-input[disabled] {
                font-family: $medium_font-family;
                font-weight: $medium_font-weight;
                font-size: 20px;
            }

            ::placeholder {
                color: $white;
                opacity: 1;
                /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $white;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $white;
            }
        }
    }
}

@media screen and (max-width: $media-tablet-max) {
    .si-custom-popup {
        .si-tab-list {
            .si-tab-item {
                width: 25%;
                height: 30px !important;
                background-color: $white;

                .si-icon-tab {
                    width: 25%;
                    height: 30px;
                    border: 0;
                    box-shadow: none !important;
                }

                .si-icon-tab {
                    &:before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @include flexbox();
                        justify-content: center;
                        font-size: 18px;
                    }
                }

                &.active {
                    width: 25%;
                    height: 30px;
                    background-color: $primary-color-1 !important;

                    span {
                        &:nth-of-type(2) {
                            color: $primary-color-1 !important;
                        }
                    }

                    .si-icon-tab {
                        box-shadow: none;
                    }
                }

                span {
                    &:nth-of-type(2) {
                        font-size: 11px;
                        position: absolute;
                        top: -20px;
                        color: #636363;
                        left: 0;
                        right: 0;
                        font-family: $medium_font-family;
                        font-weight: $medium_font-weight;
                        text-align: center;
                    }
                }
            }
        }
    }

    .si-popup__title {
        margin-bottom: 4px;
    }
}

textarea {
    height: unset !important;
    &::-webkit-scrollbar {
        width: 3px;
        z-index: 999;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        z-index: 999;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(#242424, 0.2) !important;
        border-radius: 10px;
        z-index: 999;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color-1;
        z-index: 999;
    }
}

// ----new-
.si-popup-body {
    .si-main-content {
        overflow: unset;
    }
    .si-pagination {
        bottom: 20px;
    }
    .si-tab-list {
        margin-bottom: 3px !important;
    }
    @media screen and (max-width: $media-tablet-max) {
        .si-tab-list {
            margin-top: 27px !important;
            margin-bottom: 3px !important;
            .si-tab-item {
                width: 93px !important;
                flex: unset;
                &:first-child {
                    // margin-left: 5px;
                }
            }
        }
    }
    .si-program-head {
        .col-1 {
            display: flex;
            align-items: center;
            span {
                white-space: nowrap;
            }
        }
    }
}

// --------rpe-popup------

.rpe-popup {
    background-color: #ffffff;
    width: 620px;
    border-radius: 5px;
    padding: 14px 20px 20px 20px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $media-tablet-max) {
        width: 375px;
        padding: 8px 15px 15px 15px;
    }
    @media screen and (max-width: 420px) {
        width: 364px;
    }
    .icon-close {
        font-size: 16px;
        @media screen and (max-width: $media-tablet-max) {
            margin-right: -13px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 25px;
        margin-bottom: 20px;

        .heading {
            font-weight: 500;
            font-size: 16px;
            color: #282828;
        }
    }
    .tooltip-wrapper {
        height: 25px;
        @media screen and (min-width: 769px) {
            margin-bottom: 20px;
        }
        @media screen and (max-width: 420px) {
            margin-bottom: 20px;
        }
    }
    &__tooltip {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #282828;
        background: #fcbd4e;
        border-radius: 10px 10px 0px 0px;
        display: inline-block;
        padding: 5px 37px;
        margin-bottom: 20px;
        height: 25px;
    }
    &__wrapper {
        margin-bottom: 10px;
        @media screen and (max-width: $media-tablet-max) {
            margin-bottom: 0px;
        }
        .meter {
            height: 240px;

            @media screen and (max-width: 420px) {
                height: 200px;
            }
        }
    }
    &__desc {
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: $secondary-color-1;
        text-align: center;
        margin-bottom: 27px;
        height: 28px;
        @media screen and (max-width: 420px) {
            height: 42px;
            margin-bottom: 12px;
        }
    }

    .si-button {
        padding: 9px 56px;
        @media screen and (max-width: $media-tablet-max) {
            width: initial;
        }
    }
}

.media-url {
    display: block;
    background-color: $neutral-color-8;
    overflow: scroll;
    a {
        color: #0000ee !important;
    }
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        z-index: 999;
    }
}
.si-popup-body {
    .si-tab-item {
        span {
            &:last-child {
                text-transform: uppercase;
            }
        }
    }
}

.si-info-text {
    display: inline-block;
    margin-left: 13px;
    font-size: 10px;
    font-weight: 400;
    color: #969696;
}

.no-attachement-txtwrp {
    width: 100%;
    .no-attachement-txt {
        text-align: center;
        font-size: 14px;
        margin-bottom: 5px;
    }
}
.si-popup-content {
    .si-subHeader,
    .si-main-content {
        padding-left: unset !important;
        padding-right: unset !important;
    }
}

.zindex-10000 {
    z-index: 10000 !important;
    .si-popup-footer {
        @media screen and (max-width: $media-tablet-max) {
            padding: 10px 16px !important;
        }
    }
}
.zindex-10005 {
    z-index: 10005 !important;
    @media screen and (max-width: $media-tablet-max) {
        .si-popup-footer .si-action-btn {
            width: 154px;
        }
    }
}

.si-Athletes {
    .si-pagination {
        bottom: -5px !important;
        @media screen and (max-width: $media-tablet-max) {
            bottom: 35px !important;
        }
    }
}
.si-error {
    .dropdown-container {
        border: 1px solid #e10000 !important;
    }
}

.marked-recovered-popup {
    width: 655px !important;
    max-height: unset !important;
    @media screen and (max-width: $media-tablet-max) {
        width: 420px !important;
        padding: 10px 15px 20px 15px;
    }
    @media screen and (max-width: 460px) {
        width: 340px !important;
        padding: 10px 15px 20px 15px !important;
    }
    .content-wrapper {
        .content-title {
            margin-bottom: 10px;
        }
    }
    .content-description {
        height: 88px;
        @media screen and (max-width: $media-tablet-max) {
            height: 126px;
        }
        textarea {
            height: 100% !important;
        }
    }
    .si-popup-footer {
        margin-top: 25px !important;
        @media screen and (min-width: $media-tablet-max) {
            margin-top: 25px !important;
        }
        .si-action-btn {
            width: 150px !important;
            &:first-child {
                margin-left: 0px;
            }
        }
    }
}

.input-view-popup {
    .si-popup-head {
        .si-popup__title {
            .form-control {
                input {
                    font-size: 20px;
                    font-weight: 500;
                    @media screen and (max-width: 1199px) {
                        font-size: 16px;
                        color: #282828 !important;
                        -webkit-text-fill-color:#282828!important;
                        opacity: 1;
                    }
                   
                }
            }
        }
    }
    .si-popup-body {
        .si-custom-tabs {
            .si-tab-list {
                margin-top: 20px !important;
                margin-bottom: 12px !important;
            }
        }
    }
}

// =----popup disabled---------
.attachment-disabled {
    cursor: not-allowed;
    .sc-iVCKna {
        pointer-events: none;
    }
    .drag-drop-container {
        border: 1px dashed var(--neutral-color-4, #c8c8c8) !important;
        color: $neutral-color-3 !important;
        background: #f1f1f1;
        .drag-drop__text-wrp {
            .title-text,
            .info-text {
                color: #b4b4b4 !important;
            }
            input::file-selector-button {
                color: #b4b4b4 !important;
                font-family: "Poppins";
            }
        }

        i {
            color: #b4b4b4 !important;
        }
    }
}

.si-athletebtn {
    @media screen and (max-width: 992px) {
        .si-popup .si-action-buttons {
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
}

.si-popup .si-popup-content .remove-exercise {
    min-height: 255px!important;
    .si-popup-footer{
        margin-top: 40px!important;
    }
}