.toggle-radio {
    height: 30px;
    @media screen and (max-width: $media-tablet-max) {
        margin-left: -5px;
        width: 153px;
    }
    .switch {
        text-align: center;
        background-color: $white;
        transition: all 0.3s ease;
        @include flexbox();
        font-size: 12px;
        @include border-radius(5px);
    }

    input[type="radio"] {
        display: none;
    }

    .switch label {
        cursor: pointer;
        color: #282828;
        transition: all 0.2s ease;
        text-transform: capitalize;
        flex: 1;
        padding: 0 10px;
        height: 30px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        @include flexbox();
        @include border-radius(5px);
        @include border-radius(5px);
    }

    #yes:checked ~ .switch label[for="yes"] {
        color: $white;
        background-color: $primary-color-1;
        border-color: $primary-color-1;
        font-weight: 700;
    }
    #yes ~ .switch label[for="yes"] {
        border: 1px solid $neutral-color-4;
    }

    #no:checked ~ .switch label[for="no"] {
        color: $white;
        background-color: $primary-color-1;
        border-color: $primary-color-1;
        font-weight: 700;
    }
    #no ~ .switch label[for="no"] {
        border: 1px solid $neutral-color-4;
    }
}
.switch {
    :first-child {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    :last-child {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}
