.si-content-footer {
    position: fixed;
    padding: 0 10px;
    bottom: 23px;
    right: 0;
    width: calc(100vw - 243px);
    .si-footer-wrapper {
        @include flexbox();
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: $media-tablet-max) {
            padding-right: 5px;
        }
        @media screen and (max-width: $media-tablet-max) {
            flex-flow: column;
            height: 47px;
        }
        .si-button {
            margin-left: auto;
        }

        .si-action-buttons {
            margin-left: auto;
        }
    }
}
@media screen and (max-width: $media-tablet-max) {
    .si-content-footer,
    .si-action-buttons,
    .si-button {
        width: 100%;
        padding-top: 11px;
        padding-bottom: 11px;
        height: 40px;
    }
    .si-content-footer {
        flex-flow: column;
    }
}
